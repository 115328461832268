import { PlaceModel } from 'api/places/models/place.model.ts'
import { TPlacesFilters } from 'api/places/models/places.filters.ts'
import { placesTransport } from 'api/places/places.transport.ts'
import { PlaceSorting } from 'enums/places.enums.ts'
import { makeAutoObservable } from 'mobx'

class PlacesStore {
  filters: TPlacesFilters = {}
  sorting: PlaceSorting = PlaceSorting.POPULAR
  places: Array<PlaceModel> = []
  isLoading: boolean = false
  hasError: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setPlaces = (places: Array<PlaceModel>): void => {
    this.places = places
  }

  setFilters = (updatedFilters: Partial<TPlacesFilters>): void => {
    this.filters = updatedFilters
    this.places.length = 0
  }

  updateFilters = (updatedFilters: Partial<TPlacesFilters>): void => {
    this.filters = {
      ...this.filters,
      ...updatedFilters,
    }
    this.places.length = 0
  }

  setSorting = (sorting: PlaceSorting): void => {
    this.sorting = sorting
  }

  setIsLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading
  }

  setHasError = (hasError: boolean): void => {
    this.hasError = hasError
  }

  setIsFavorite = (placeId: number, isFavorite: boolean): void => {
    this.places = this.places.map((place) => {
      if (place.id === placeId) {
        return { ...place, isFavourite: isFavorite } as PlaceModel
      }
      return place
    })
  }

  getPlaces = (): void => {
    if (this.places.length > 0) return

    this.setIsLoading(true)
    this.setHasError(false)

    placesTransport
      .getPlaces(this.filters, this.sorting)
      .then(this.setPlaces)
      .catch(() => {
        this.setHasError(true)
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  reset = (): void => {
    this.filters = {}
    this.sorting = PlaceSorting.POPULAR
    this.places = []
    this.isLoading = false
    this.hasError = false
  }
}

export const placesStore = new PlacesStore()
