import { css } from '@emotion/css'

export const websiteMissingDialogPage = css`
  padding: 0 1.5rem;
`

export const websiteMissingHeaderImage = css`
  display: block;
  width: 100%;
`
