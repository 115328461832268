import * as styles from './block-rerender-client.style'
import {
  BlocksRenderer,
  type BlocksContent,
} from '@strapi/blocks-react-renderer'

export const BlockRendererClient = ({
  content,
}: {
  readonly content: BlocksContent
}) => {
  if (!content) return null
  return (
    <div className={styles.richTextStyles}>
      <BlocksRenderer content={content} />
    </div>
  )
}
