import { JSX } from 'react'
import { Loader } from 'components/common/loader/loader.tsx'
import style from './centered-loader.module.css'

export const CenteredLoader = (): JSX.Element => {
  return (
    <div className={style.centeredLoaderWrapper}>
      <Loader inverse />
    </div>
  )
}
