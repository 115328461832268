import { AxiosResponse } from 'axios'
import { transport } from './transport.ts'

import { AvatarModel } from 'api/avatars/models/avatar.model.ts'
import { TAvatarModel } from 'api/models/avatar.model.ts'

export class Avatars {
  getAvatars() {
    return transport
      .get(`${import.meta.env.VITE_STRAPI_API}/api/avatars?populate=*`)
      .then(({ data }: AxiosResponse) =>
        data.data.map((item: TAvatarModel) => {
          return AvatarModel.fromServer(item)
        })
      )
  }
}

export const avatarsTransport = new Avatars()
