import { css } from '@emotion/css'

const textCommon = css`
  font-family: 'Copperplate', serif;
  font-weight: 300;
  font-size: 40px;
  letter-spacing: -0.01em;
  color: #000;
`
export const contentWrapper = css`
  max-width: 1080px;
  margin: 0 auto;
  padding-bottom: 200px;
`

export const linksWrapper = css`
  max-width: 900px;
  text-align: center;
  display: flex;
  flex-direction: column;

  a {
    ${textCommon};
    line-height: 287%;
    text-decoration: underline;
    text-align: center;
  }
`

export const ownerInfo = css`
  ${textCommon};
  border-radius: 46px;
  background-color: #9fcbdc;
  padding: 20px 40px;

  a {
    color: #000;
  }
`
