import { css } from '@emotion/css'
import { TSanekButtonSize, TSanekButtonType } from './sanek-button.types.ts'

const TYPE_TO_COLOR_MAP: Record<TSanekButtonType, string> = {
  primary: '#ffde00',
  secondary: '#9fcbdc',
  clear: '#ffffff',
  default: '#cdcdcd;',
}

function getSanekButtonHeight(size: TSanekButtonSize): number {
  switch (size) {
    case 's':
      return 32
    case 'l':
      return 66
    case 'm':
    default:
      return 58
  }
}

export function getSanekButtonStyles(
  type: TSanekButtonType,
  size: TSanekButtonSize
): string {
  return css`
    position: relative;
    width: 100%;
    height: ${getSanekButtonHeight(size)}px;
    flex-shrink: 0;
    border-radius: 40px;
    border: 5px solid #e6e6e6;
    background: ${TYPE_TO_COLOR_MAP[type]};
    box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.25);
    padding: 0;
    margin: 0 auto;

    color: #000;
    text-align: center;
    font-family: Copperplate, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.4px;

    &:active,
    &:focus {
      outline: none !important;
      border: 5px solid #e6e6e6 !important;
    }

    &[disabled],
    &:disabled {
      background-color: #cccccc;
      pointer-events: none;
    }
  `
}

const buttonDecor = css`
  position: absolute;
  bottom: -23px;
  width: 40px;
  height: 34px;
`

export const buttonLeftDecor = css`
  ${buttonDecor};
  left: -17px;
`

export const buttonRightDecor = css`
  ${buttonDecor};
  right: -20px;
  transform: rotateY(180deg);
`
