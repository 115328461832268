import { css } from '@emotion/css'
import { buttonReset } from 'styles/reset.style.ts'

export const timeslotsWrapper = css`
  padding: 10px 15px;
  border-radius: 16px;
  background: #ffde00;
`

export const timeslotsTitle = css`
  color: #000;
  font-family: 'Chalkboard SE', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const editButton = css`
  ${buttonReset};
  background-color: transparent;
  width: 23px;
  height: 23px;
`
