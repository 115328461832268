import { meetingChatTransport } from 'api/meeting-chat/meeting-chat.transport.ts'
import { ChatMessageModel } from 'api/meeting-chat/models/chat-message.model.ts'
import { CreateMessageRequest } from 'api/meeting-chat/models/create-message.request.ts'
import { ImageModel } from 'api/models/image.model.ts'
import { transport } from 'api/transport.ts'
import { AxiosResponse } from 'axios'
import { makeAutoObservable } from 'mobx'
import { getFileFromUrl } from 'utils/file.ts'
import { navigateBack } from 'utils/navigation.ts'
import { extractYandexMapsLink } from 'utils/yandex.ts'

class ChatMessageCreationStore {
  text: string = ''
  link: string = ''
  file: File | null = null
  isLoading: boolean = false
  hasError: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setText = (text: string): void => {
    this.text = text
  }

  setLink = (link: string): void => {
    this.link = extractYandexMapsLink(link)
  }

  setFile = (file: File | null): void => {
    this.file = file
  }

  setIsLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading
  }

  setHasError = (hasError: boolean): void => {
    this.hasError = hasError
  }

  createChatMessage = async (meetingId: number): Promise<void> => {
    this.setIsLoading(true)
    this.setHasError(false)

    try {
      const request = await this.getCreateMessageRequest(meetingId)
      await meetingChatTransport.createChatMessage(request)
      void navigateBack()
    } catch {
      this.setHasError(true)
    } finally {
      this.setIsLoading(false)
    }
  }

  editChatMessage = async (
    meetingId: number,
    chatId: number
  ): Promise<void> => {
    this.setIsLoading(true)
    this.setHasError(false)

    try {
      const request = await this.getCreateMessageRequest(meetingId)
      await meetingChatTransport.editChatMessage(chatId, request)
      void navigateBack()
    } catch {
      this.setHasError(true)
    } finally {
      this.setIsLoading(false)
    }
  }

  init = async (message: ChatMessageModel): Promise<void> => {
    this.text = message.text
    this.link = message.link
    if (message.photo) {
      this.file = await getFileFromUrl({
        url: message.photo.url,
        fileName: message.photo.name,
        mimeType: message.photo.mime,
      })
    }
  }

  reset = (): void => {
    this.text = ''
    this.link = ''
    this.file = null
  }

  private getCreateMessageRequest = async (
    meetingId: number
  ): Promise<CreateMessageRequest> => {
    const photo = await this.uploadAndGetChatImage()
    const message = new ChatMessageModel({
      text: this.text,
      link: this.link,
      photo,
    })

    return new CreateMessageRequest({ meetingId, message })
  }

  private uploadAndGetChatImage = async (): Promise<ImageModel | null> => {
    if (!this.file) {
      return null
    }

    const formData = new FormData()
    formData.append('files', this.file)

    return transport
      .post(`${import.meta.env.VITE_STRAPI_API}/api/upload`, formData)
      .then(({ data }: AxiosResponse) => data[0])
  }
}

export const chatMessageCreationStore = new ChatMessageCreationStore()
