import { cx } from '@emotion/css'
import CrownSubscription from 'assets/crown-subscription.png'
import { SubscriptionState } from 'enums/subscription.enums.ts'
import { observer } from 'mobx-react-lite'
import { JSX } from 'react'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums.ts'
import { userStore } from 'store/user.store.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { Typography } from 'ui/typography'
import * as styles from './subscription-info-block.style.ts'

export const SubscriptionInfoBlock = observer((): JSX.Element => {
  const { user } = userStore

  if (!user) {
    return <></>
  }

  const { subscription } = user
  const isExpired =
    !subscription || subscription.state === SubscriptionState.EXPIRED

  const renderContent = (): JSX.Element => {
    if (isExpired) {
      return (
        <Typography size="10" align="center">
          Купить подписку
        </Typography>
      )
    } else if (subscription.state === SubscriptionState.EXPIRE_SOON) {
      return (
        <>
          <Typography size="10" align="center">
            &nbsp;
          </Typography>
          <Typography size="48" lineHeight="0.7" weight="700">
            {user.subscription?.remainingDays}
          </Typography>
          <Typography size="10" align="center">
            дней
          </Typography>
        </>
      )
    } else {
      return (
        <>
          <Typography size="8" align="center">
            Подписка
          </Typography>
          <CellEmpty height="3" />
          <img src={CrownSubscription} alt="Корона" style={{ width: '24px' }} />
          <CellEmpty height="2" />
          <Typography size="10" align="center">
            куплена
          </Typography>
        </>
      )
    }
  }

  const handleSubscriptionClick = (): void => {
    void router.navigate(SanekRoute.SUBSCRIPTIONS)
  }

  return (
    <div
      className={cx(styles.mainContainer, {
        [styles.expiredBg]: isExpired,
      })}
      onClick={handleSubscriptionClick}
    >
      {renderContent()}
    </div>
  )
})
