import { css } from '@emotion/css'

export const container = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 235px;
  height: 35px;
  border-radius: 24px;
  background: #ffde00;
  border: 2px solid #ffde00;
  box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.25);
  background: #fff;
  gap: 10px;
`

export const content = css`
  font-family: 'Copperplate', sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-align: right;
  color: #000;
`

export const icon = css`
  height: 23px;
  width: 23px;
  object-fit: contain;
`
