import { Fragment, JSX } from 'react'
import * as styles from './audio-progress.style.ts'

type TAudioProgressProps = {
  time: number
}
export const AudioProgress = ({ time }: TAudioProgressProps): JSX.Element => {
  return (
    <Fragment>
      <div className={styles.audioProgressBar}>
        {time > 4000 && (
          <div
            className={styles.audioProgressBar}
            style={{
              width: `${time * 0.002}%`,
              maxWidth: '100%',
              backgroundColor: '#FFDE00',
            }}
          >
            {time > 10000 && (
              <div className={styles.progressValue}>
                {time / 10000}&nbsp;сек
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.audioProgressBarTimeTipWrapper}>
        <p>0 сек</p>
        <p>5 сек</p>
      </div>
    </Fragment>
  )
}
