import { css } from '@emotion/css'
import * as common from 'styles/common.style.ts'

export const surveyCommonPage = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const surveyCommonPageContent = css`
  padding: 1rem 1.5rem 4rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`

export const genderOptionsWrapper = css`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
`

export const footerContainer = css`
  position: relative;
`

export const sanekImage = css`
  display: block;
  width: 233px;
  height: 233px;
`

export const speechBubble = css`
  position: absolute;
  top: 20px;
  left: 148px;
`

export const speechBubbleContent = css`
  box-sizing: border-box;
  width: 170px;
  font-family:
    Chalkboard SE,
    serif;
  font-size: 20px;
  font-weight: 400;
`

export const chevronsImage = css`
  position: absolute;
  right: 68px;
  bottom: 128px;
`

export const policy = css`
  ${common.policy};
  text-align: center;
`
