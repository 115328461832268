import { css } from '@emotion/css'

export const portalContent = css`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
`

export const balloonContent = css`
  width: 328px;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 20px 12px;
  background: #fff;
  border-radius: 14px;
  box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%) translateY(70px);
`

export const balloonCloseButton = css`
  width: auto;
  height: auto;
  z-index: 1000;
  position: absolute;
  top: -3px;
  right: -10px;
  background: #fff;
  border: none;
  cursor: pointer;
`
