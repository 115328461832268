import { css } from '@emotion/css'
import { TMeetingRateStep } from 'types/meeting-rate.types.ts'

export function getMeetingRateSliderStyle(
  data: Array<TMeetingRateStep>
): string {
  return css`
    border: 5px solid #ffde00;
    border-radius: 8px;
    padding: 0 30px !important;
    margin: 60px 0 25px;

    .adm-slider-track-container {
      padding: 2px 0;
    }

    .adm-slider-fill,
    .adm-slider-track {
      background-color: transparent;
    }

    .adm-slider-tick {
      background-color: #d9d9d9;
      display: flex;
      justify-content: center;
    }

    .adm-slider-tick::before {
      content: '';
      position: absolute;
      bottom: 13px;
      width: 52px;
      height: 52px;
    }

    .adm-slider-tick:last-child::before {
      width: 60px;
      height: 60px;
    }

    .adm-slider-tick::after {
      position: absolute;
      bottom: -25px;
      font-size: 12px;
      font-weight: 300;
    }

    ${data.map(
      (step: TMeetingRateStep, index: number) => `
      .adm-slider-tick:nth-child(${index + 1})::after {
        content: '${step.text}';
      }
      
      .adm-slider-tick:nth-child(${index + 1})::before {
        background: url('${step.icon}') center/contain no-repeat;
      }
    `
    )}

    .adm-slider-thumb {
      height: 14px;
      width: 14px;
      border: 4px solid black;
      background-color: #ffde00;
    }

    .adm-slider-thumb-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `
}
