import { deserialize, JsonName, JsonStruct } from 'tserialize'
import { PaymentConfirmationModel } from './payment-confirmation.model.ts'
import { TPaymentStatus } from 'enums/payment.enums.ts'

export class PaymentDataModel {
  @JsonName()
  id!: string

  @JsonName()
  status!: TPaymentStatus

  @JsonStruct(PaymentConfirmationModel)
  confirmation!: PaymentConfirmationModel

  static fromServer(data: object): PaymentDataModel {
    return deserialize(data, PaymentDataModel)
  }
}
