import Check from 'assets/decorations/meeting-details/check.png'
import { SanekFriendInfo } from 'components/sanek-friend-info'
import { observer } from 'mobx-react-lite'
import { JSX } from 'react'
import { meetingDetailsStore } from 'store/meeting-details.store.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { FlexContainer } from 'ui/flex-container'
import { Typography } from 'ui/typography'
import * as styles from './found-content.style.ts'
import { SeparatorGrayThin } from 'components/common/separator/separator-gray-thin.tsx'

export const FoundContent = observer((): JSX.Element => {
  const { meeting } = meetingDetailsStore

  return (
    <>
      <Typography family="Chalkboard" size="24" weight="700" align="center">
        Встреча готова
      </Typography>

      <FlexContainer justify="center">
        <img src={Check} alt="Готово" className={styles.checkImage} />
      </FlexContainer>

      <Typography family="Chalkboard" align="center">
        За 3 часа до начала встречи подтверди в приложении, что ты придешь
      </Typography>

      <SeparatorGrayThin className={styles.separatorImage} />

      {meeting?.counterpart && (
        <>
          <Typography family="Chalkboard" size="26" weight="700" align="center">
            Друг Санька
          </Typography>
          <CellEmpty height="15" />

          <SanekFriendInfo friend={meeting.counterpart} />
          <CellEmpty height="20" />
        </>
      )}
    </>
  )
})
