import { UserMeetingModel } from 'api/user-meetings/models/user-meeting.model.ts'
import { GetUserMeetingsResponse } from 'api/user-meetings/responses/get-user-meetings.response.ts'
import { userMeetingsTransport } from 'api/user-meetings/user-meetings.transport.ts'
import { makeAutoObservable } from 'mobx'
import { TMeetingsFilters } from 'types/meetings-filters'
import { InvitationSortType } from 'enums/invitations-sort-types.enum.ts'

class UserInvitationsStore {
  invitations: Array<UserMeetingModel> = []
  total: number = 0
  isLoading: boolean = false
  hasError: boolean = false
  filters: TMeetingsFilters = {
    today: false,
    tomorrow: false,
    dayAfterTomorrow: false,
    nextWeek: false,
    isRecipient: false,
    isOwner: false,
  }

  constructor() {
    makeAutoObservable(this)
  }

  setIsLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading
  }

  setHasError = (hasError: boolean): void => {
    this.hasError = hasError
  }

  setFilters = (newFilters: TMeetingsFilters): void => {
    this.filters = newFilters
  }

  get activeFiltersCount(): number {
    return Object.values(this.filters).filter(Boolean).length
  }

  getInvitations = (
    sort: InvitationSortType = InvitationSortType.NEWEST
  ): void => {
    this.setIsLoading(true)
    this.setHasError(false)

    userMeetingsTransport
      .getUserInvitations(this.filters, sort)
      .then(({ results, pagination }: GetUserMeetingsResponse) => {
        this.setInvitations(results, pagination.total)
      })
      .catch(() => {
        this.setHasError(true)
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  private setInvitations = (
    invitations: Array<UserMeetingModel>,
    total: number
  ): void => {
    this.invitations = invitations
    this.total = total
  }
}

export const userInvitationsStore = new UserInvitationsStore()
