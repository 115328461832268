import { SanekFriendInfo } from 'components/sanek-friend-info'
import { observer } from 'mobx-react-lite'
import { ChatBlock } from 'pages/meeting-details/components/chat-block'
import { CounterpartPresenceStatus } from 'pages/meeting-details/content/meeting-soon-content/counterpart-presence-status.tsx'
import { JSX } from 'react'
import { meetingDetailsStore } from 'store/meeting-details.store.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { FlexContainer } from 'ui/flex-container'
import { Typography } from 'ui/typography'
import { SelfPresenceStatus } from './self-presence-status.tsx'
import Check from 'assets/decorations/meeting-details/check.png'
import { SeparatorGrayThin } from '../../../../components/common/separator/separator-gray-thin.tsx'

export const MeetingSoonContent = observer((): JSX.Element => {
  const { meeting } = meetingDetailsStore

  if (!meeting) {
    return <></>
  }

  return (
    <>
      {meeting.isNotificationsAvailable ? (
        <>
          <Typography family="Chalkboard" size="32">
            Ты где?
          </Typography>
          <CellEmpty height="15" />

          <SelfPresenceStatus notificationStatus={meeting.notificationStatus} />
          <CellEmpty height="25" />

          <Typography family="Chalkboard" size="24">
            Кто где находится
          </Typography>
          <CellEmpty height="10" />

          <ChatBlock meeting={meeting} />
          <CellEmpty height="15" />
        </>
      ) : (
        <>
          <Typography family="Chalkboard" size="24" weight="700" align="center">
            Друг подтвердил участие
          </Typography>

          <FlexContainer justify="center">
            <img src={Check} alt="Готово" width="30%" />
          </FlexContainer>
          <Typography family="Chalkboard" size="16" weight="700" align="center">
            За&nbsp;30&nbsp;мин&nbsp;до&nbsp;встречи ты&nbsp;сможешь списаться
            с&nbsp;другом кто где находится
          </Typography>
          <CellEmpty height="15" />
        </>
      )}

      <SeparatorGrayThin />
      <CellEmpty height="15" />

      {meeting?.counterpart && (
        <>
          <FlexContainer justify="space-between" align="center" wrap="nowrap">
            <Typography family="Chalkboard" size="26" weight="700">
              Друг Санька
            </Typography>

            <CounterpartPresenceStatus
              notificationStatus={meeting.counterpart.notificationStatus}
            />
          </FlexContainer>
          <CellEmpty height="10" />

          <SanekFriendInfo friend={meeting.counterpart} />
          <CellEmpty height="20" />
        </>
      )}
    </>
  )
})
