import { JSX, PropsWithChildren } from 'react'
import styles from './layout-page.module.css'

export function Footer({ children }: PropsWithChildren): JSX.Element {
  return (
    <div className={styles.footer}>
      {children}
    </div>
  )
}
