import { JSX } from 'react'
import { FlowResultPage } from 'ui/flow-result-page'

import { useScrollToTop } from 'hooks/useScrollToTop.ts'

import { PushNotificationsPopup } from 'components/push-notifications-popup'

export const CreateMeetingSuccessPage = (): JSX.Element => {
  useScrollToTop()

  return (
    <>
      <PushNotificationsPopup
        title="Важно"
        description="Чтобы встреча состоялась, Санёк попросит подтвердить, что ты придешь"
        content="Разреши уведомления, чтобы не пропустить"
      />
      <FlowResultPage
        headerText="Встреча создана"
        text="Внимание!"
        description="За 3 часа до начала встречи подтверди в приложении, что ты придешь"
      />
    </>
  )
}
