import { TPlaceFilterForm } from 'types/place-filters.types'
import { pluralize, TPluralizeConfig } from './pluralize.ts'

const STATION_FORMS: TPluralizeConfig = ['станция', 'станции', 'станций']

export function getMetroButtonText(form: TPlaceFilterForm) {
  const metroStationsCount = form.metroStations.length
  if (!metroStationsCount) {
    return 'Выберите метро'
  }

  return metroStationsCount === 1
    ? form.metroStations[0].name
    : `Выбрано ${metroStationsCount} ${pluralize(
        STATION_FORMS,
        metroStationsCount
      )}`
}
