import { cx } from '@emotion/css'
import { DotLoading } from 'antd-mobile'
import ButtonDecor from 'assets/decorations/meeting-details/button-decoration.png'
import { JSX } from 'react'
import * as styles from './sanek-button.style.ts'
import { TSanekButtonProps } from './sanek-button.types.ts'

export function SanekButton({
  children,
  type = 'primary',
  size = 'm',
  disabled,
  isLoading,
  withDecoration,
  className,
  style,
  onClick,
}: TSanekButtonProps): JSX.Element {
  return (
    <button
      className={cx(styles.getSanekButtonStyles(type, size), className)}
      style={style}
      disabled={disabled || isLoading}
      onClick={onClick}
    >
      {isLoading ? <DotLoading /> : children}
      {withDecoration && (
        <>
          <img src={ButtonDecor} alt="" className={styles.buttonLeftDecor} />
          <img src={ButtonDecor} alt="" className={styles.buttonRightDecor} />
        </>
      )}
    </button>
  )
}
