import { JSX, PropsWithChildren } from 'react';
import * as styles from './meeting-category-label.style.ts';

type TMeetingCategoryLabelProps = PropsWithChildren<{
  iconLeft?: string;
}>;

export function MeetingCategoryLabel({ children, iconLeft }: TMeetingCategoryLabelProps): JSX.Element {
  return (
    <div className={styles.container}>
      {iconLeft && (
        <img className={styles.icon} src={iconLeft} alt="category-image" />
      )}
      <div className={styles.content}>{children}</div>
    </div>
  );
}
