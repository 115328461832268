import { JsonName, serialize } from 'tserialize'

export class CreateGlobalFeedbackRequest {
  @JsonName()
  text: string = ''

  constructor(data: Omit<CreateGlobalFeedbackRequest, 'toServer'>) {
    Object.assign(this, data)
  }

  toServer(): object {
    return serialize(this)
  }
}
