import style from './audio-recorder.module.css'

import { useRef, useEffect, useCallback, JSX, useState } from 'react'
import { CircleButton } from 'components/common/circle-button'
import Microphone from 'assets/microphone.svg'
import Play from 'assets/play.svg'
import Trash from 'assets/trash.svg'
import Stop from 'assets/stop.svg'
import OpenLock from 'assets/open-lock.svg'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { AudioProgress } from './audio-progress'
import { observer } from 'mobx-react-lite'
import { audioStore } from 'store/audio.store.ts'
import { useRollbar } from '@rollbar/react'

const mimeType = 'audio/mp3'

export const AudioRecorder = observer(() => {
  const { recordedAudio, permission, time, stream } = audioStore
  const [recordingStatus, setRecordingStatus] = useState('inactive')
  const [audioChunks, setAudioChunks] = useState<Array<Blob>>([])

  const rollbar = useRollbar()

  const mediaRecorder = useRef<MediaRecorder>(null)
  const timerRef = useRef<number>(0)

  const stopRecording = useCallback(() => {
    setRecordingStatus('inactive')

    window.clearInterval(timerRef.current)

    if (!mediaRecorder.current) {
      rollbar.error('MediaRecorder не найден')
      return
    }

    //stops the recording instance
    mediaRecorder.current.stop()
    mediaRecorder.current.onstop = () => {
      //creates a blob file from the audiochunks data
      const audioBlob = new Blob(audioChunks, { type: mimeType })
      //creates a playable URL from the blob file.
      const audioUrl = URL.createObjectURL(audioBlob)
      audioStore.setRecordedAudio(new Audio(audioUrl))
      setAudioChunks([])
    }
  }, [audioChunks])

  useEffect(() => {
    if (time === 50000) {
      stopRecording()
    }
  }, [time, stopRecording])

  const startRecording = async () => {
    if (!stream || !mediaRecorder) {
      rollbar.error(
        `Отсутствуют: stream: ${Boolean(stream)}, mediaRecorder: ${Boolean(
          mediaRecorder
        )}`
      )
      return
    }
    setRecordingStatus('recording')
    // @ts-expect-error
    mediaRecorder.current = new MediaRecorder(stream, { type: mimeType })
    mediaRecorder.current.start()

    const localAudioChunks: Array<Blob> = []
    mediaRecorder.current.ondataavailable = (event: BlobEvent) => {
      if (typeof event.data === 'undefined' || event.data.size === 0) {
        return
      }
      localAudioChunks.push(event.data)
    }
    setAudioChunks(localAudioChunks)
    timerRef.current = window.setInterval(() => {
      audioStore.setTime(audioStore.time + 1000)
    }, 100)
    audioStore.setTime(0)
  }

  const getMicrophonePermission = async () => {
    if ('MediaRecorder' in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        })
        audioStore.setPermission(true)
        audioStore.setStream(streamData)
      } catch (err: unknown) {
        // @ts-expect-error
        rollbar.error(err.message)
      }
    } else {
      alert('The MediaRecorder API is not supported in your browser.')
    }
  }
  const handlePlayAudio = (): void => {
    if (!recordedAudio) {
      rollbar.error('Аудиофайл не найден')
      return
    }

    void recordedAudio.play()
  }

  const handleDeleteAudio = (): void => {
    setRecordingStatus('inactive')
    audioStore.setTime(0)
    window.clearInterval(timerRef.current)

    setAudioChunks([])
    audioStore.setRecordedAudio(null)
  }

  const getMainButton = (): JSX.Element => {
    if (!permission) {
      return <CircleButton onClick={getMicrophonePermission} icon={OpenLock} />
    }

    return recordingStatus === 'recording' ? (
      <CircleButton onClick={stopRecording} icon={Stop} />
    ) : (
      <CircleButton onClick={startRecording} icon={Microphone} />
    )
  }

  return (
    <div className={style.recordingWrapper}>
      <div className={style.recordingMain}>
        {getMainButton()}
        <div className={style.recordingProgress}>
          <AudioProgress time={time} />
          <CellEmpty height="15" />

          <p className={style.recordingTip}>
            Например, «Привет, меня зовут Иван! Буду рад&nbsp;познакомиться»
          </p>
        </div>
      </div>
      <div className={style.recordActions}>
        <CircleButton icon={Play} onClick={handlePlayAudio} />
        <CircleButton
          icon={Trash}
          buttonStyle={{ backgroundColor: '#BDBDBD' }}
          onClick={handleDeleteAudio}
        />
      </div>
    </div>
  )
})
