import { JSX } from 'react'
import * as styles from './moods-block.style.ts'
import { meetingMoodsStore } from 'store/meeting-moods.store.ts'
import { MoodCard } from '../mood-card'
import { MeetingMoodModel } from 'api/meeting/models/meeting-mood.model.ts'

export const MoodsBlock = (): JSX.Element => {
  const { moods, isLoading } = meetingMoodsStore

  if (isLoading) {
    return <div>Loading</div>
  }
  return (
    <div className={styles.container}>
      {moods.map((mood: MeetingMoodModel) => (
        <MoodCard key={mood.id} mood={mood} />
      ))}
    </div>
  )
}
