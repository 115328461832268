import Clocks from 'assets/decorations/meeting-details/clocks.png'
import { ConfirmMeetingRefusalModal } from 'components/confirm-meeting-refusal-modal'
import { SanekFriendInfo } from 'components/sanek-friend-info'
import { observer } from 'mobx-react-lite'
import { JSX } from 'react'
import { meetingConfirmStore } from 'store/meeting-confirm.store.ts'
import { meetingDetailsStore } from 'store/meeting-details.store.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { FlexContainer } from 'ui/flex-container'
import { SanekButton } from 'ui/sanek-button'
import { Typography } from 'ui/typography'
import * as styles from './wait-confirmation-content.style.ts'
import { closeAllDialogs, openDialog } from 'utils/dialog.ts'
import { SeparatorGrayThin } from 'components/common/separator/separator-gray-thin.tsx'

export const WaitConfirmationContent = observer((): JSX.Element => {
  const { meeting } = meetingDetailsStore
  const { isConfirming, isDeclining } = meetingConfirmStore

  if (!meeting) {
    return <></>
  }

  const handleSetConfirmed = (isConfirmed: boolean): void => {
    meetingConfirmStore.setIsMeetingAccepted(meeting.id, isConfirmed)
  }

  const handleRefuse = () => {
    handleSetConfirmed(false)
    closeAllDialogs()
  }

  const handleAccept = () => {
    handleSetConfirmed(true)
    closeAllDialogs()
  }

  const handleOpenModalRefuseConfirmed = (): void => {
    openDialog({
      content: (
        <ConfirmMeetingRefusalModal
          onClose={closeAllDialogs}
          onRefuse={handleRefuse}
          onAccept={handleAccept}
        />
      ),
    })
  }

  return (
    <>
      {meeting.isConfirmed && (
        <>
          <Typography family="Chalkboard" size="24" align="center">
            Ждём подтверждение участия от друга
          </Typography>
          <CellEmpty height="20" />

          <img src={Clocks} alt="Часы" style={{ width: '100%' }} />
          <CellEmpty height="20" />
        </>
      )}

      {!meeting.isConfirmed && (
        <>
          <Typography family="Chalkboard" size="24" align="center">
            Подтверди, что ты придёшь
          </Typography>
          <CellEmpty height="25" />

          <FlexContainer justify="space-between" wrap="nowrap">
            <SanekButton
              type="clear"
              size="l"
              disabled={isConfirming}
              isLoading={isDeclining}
              style={{ width: '40%' }}
              onClick={handleOpenModalRefuseConfirmed}
            >
              <Typography size="24" weight="700" align="center">
                НЕ ИДУ
              </Typography>
            </SanekButton>

            <SanekButton
              type="clear"
              size="l"
              disabled={isDeclining}
              isLoading={isConfirming}
              withDecoration
              style={{ width: '40%' }}
              onClick={handleSetConfirmed.bind(null, true)}
            >
              <Typography size="36" weight="700" align="center">
                ИДУ
              </Typography>
            </SanekButton>
          </FlexContainer>
          <CellEmpty height="46" />
        </>
      )}

      <SeparatorGrayThin className={styles.separatorImage} />
      <CellEmpty height="5" />

      {meeting.counterpart && (
        <>
          <Typography family="Chalkboard" size="26" weight="700" align="center">
            Друг Санька
          </Typography>
          <CellEmpty height="15" />

          <SanekFriendInfo friend={meeting.counterpart} />
          <CellEmpty height="20" />
        </>
      )}
    </>
  )
})
