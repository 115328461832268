import { JSX } from 'react'
import * as styles from './stub-subscription.style.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { pluralize } from 'utils/pluralize.ts'

type TTariff = {
  month: number
  price: number
}

const TARIFFS: Array<TTariff> = [
  {
    month: 1,
    price: 249,
  },
  {
    month: 3,
    price: 199,
  },
  {
    month: 6,
    price: 149,
  },
]
export const StubSubscription = (): JSX.Element => {
  return (
    <>
      <div className={styles.contentWrapper}>
        <h2 className={styles.title}>Что&nbsp;даёт подписка?</h2>
        <p className={styles.text}>
          Возможность создавать и&nbsp;принимать приглашения на&nbsp;встречи
          в&nbsp;приложении
        </p>
        <CellEmpty height="50" />

        <h2 className={styles.title}>Что&nbsp;если нет&nbsp;подписки?</h2>
        <p className={styles.text}>
          Всем новым пользователям мы&nbsp;даём 1&nbsp;месяц бесплатного
          использования приложения
        </p>
        <CellEmpty height="30" />

        <p className={styles.text}>
          чтобы&nbsp;пользоваться приложением после&nbsp;истечения 1&nbsp;месяца
          потребуется подписка
        </p>
        <CellEmpty height="50" />

        <h2 className={styles.title}>Сколько стоит подписка?</h2>
        <CellEmpty height="40" />

        <div className={styles.tariffsWrapper}>
          {TARIFFS.map((tariff: TTariff) => (
            <div className={styles.tariff} key={tariff.price}>
              <p className={styles.text}>{getTitle(tariff)}</p>
              <CellEmpty height="20" />

              <p className={styles.tariffPrice}>{tariff.price}</p>

              <p className={styles.text} style={{ fontWeight: 300 }}>
                РУБ/МЕС
              </p>
            </div>
          ))}
        </div>
        <CellEmpty height="80" />

        <p className={styles.text}>
          после&nbsp;оплаты подписка будет автоматически активирована
        </p>
      </div>
    </>
  )
}

function getTitle(tariff: TTariff): string {
  return `На ${tariff.month} ${pluralize(
    ['месяц', 'месяца', 'месяцев'],
    tariff.month
  )}`
}
