import { JsonName, serialize } from 'tserialize'
import { serializeAddRelation } from 'utils/api.utils.ts'

export class CreateMeetingFeedbackRequest {
  @JsonName()
  text: string = ''

  @JsonName('id', serializeAddRelation)
  meetingId?: number

  constructor(data: Omit<CreateMeetingFeedbackRequest, 'toServer'>) {
    Object.assign(this, data)
  }

  toServer(): object {
    return serialize(this)
  }
}
