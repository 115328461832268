import { JSX } from 'react'
import { FlowResultPage } from 'ui/flow-result-page'

export const SubscriptionBuySuccess = (): JSX.Element => {
  return (
    <FlowResultPage
      headerText="Оплата прошла"
      text="Добро пожаловать в клуб лучших друзей!"
    />
  )
}
