import { css } from '@emotion/css'

export const moodCard = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  border: 2px solid #f2f2f2;
  background: #f6f6f6;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.25);
  padding: 8px;
  cursor: pointer;

  p {
    color: #000;
    text-align: center;
    font-family: Copperplate, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.408px;
  }
`

export const selected = css`
  background: #ffde00;
`
