import { css } from '@emotion/css'

export const placeCategoryCard = css`
  border-radius: 25px;
  border: 2px solid #f2f2f2;
  background: #f6f6f6;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
  box-sizing: border-box;

  padding: 4px 12px 0;
  min-height: 50px;
`

export const selected = css`
  background: #ffde00;
`

export const textMin = css`
  font-family: Copperplate, serif;
  font-weight: 700;
  font-size: 14px;
  color: #000;
`
