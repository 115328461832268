import { JSX } from 'react'
import Stroke from 'assets/separator-gray-thin.png'
import cx from 'classnames'
import * as styles from './separator.style.ts'

type TSeparatorGrayThinProps = {
  className?: string
}
export const SeparatorGrayThin = ({
  className,
}: TSeparatorGrayThinProps): JSX.Element => {
  return <img src={Stroke} alt="" className={cx(styles.img, className)} />
}
