import { JSX } from 'react'

import { FlowResultPage } from 'ui/flow-result-page'

import { useScrollToTop } from 'hooks/useScrollToTop.ts'

export const AcceptInvitationSuccess = (): JSX.Element => {
  useScrollToTop()

  return (
    <FlowResultPage
      headerText="Встреча создана"
      text="Внимание!"
      description="За 3 часа до начала встречи подтверди в приложении, что ты придешь"
    />
  )
}
