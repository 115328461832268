import { css } from '@emotion/css'
import { flexCenterContent } from 'styles/common.style.ts'

export const stepsContainer = css`
  counter-reset: tip;
`

export const step = css`
  display: flex;
  flex-wrap: nowrap;
  font-size: 16px;
  line-height: 1;

  &::before {
    ${flexCenterContent};
    min-width: 32px;
    min-height: 32px;
    border-radius: 16px;
    background-color: #ffde00;
    counter-increment: tip;
    content: counter(tip);
    margin-right: 13px;
  }
  
  &:not(:last-child) {
    margin-bottom: 30px;
  }
`

