import { JsonNameReadonly } from 'tserialize';

export class VoiceModel {
  @JsonNameReadonly()
  id!: number;

  @JsonNameReadonly()
  mime: string = 'audio/mp3';

  @JsonNameReadonly()
  url: string = '';
}
