import { css } from '@emotion/css';

export const buttonReset = css`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
`
