import { ImageModel } from 'api/models/image.model.ts'
import { PlaceModel } from 'api/places/models/place.model.ts'
import { JSX, useCallback, useEffect, useState } from 'react'
import { CellEmpty } from 'ui/cell-empty'
import { FlexContainer } from 'ui/flex-container'
import * as styles from './place-details.style.ts'

type TPlaceDetailsImagesProps = {
  place: PlaceModel
}

export function PlaceDetailsImages({ place }: TPlaceDetailsImagesProps): JSX.Element {
  const [containerNode, setContainerNode] = useState<HTMLDivElement | null>(
    null
  )
  const [imgSize, setImgSize] = useState(135)

  const setRef = useCallback((node: HTMLDivElement) => {
    setContainerNode(node)
  }, [])

  useEffect(() => {
    const handleResize = (): void => {
      setImgSize((containerNode?.offsetWidth || 270) * 0.47)
    }

    handleResize()

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [containerNode])

  if (place.isCreated && place.categories?.[0]) {
    return (
      <FlexContainer direction="column" align="center">
        <img
          src={place.categories[0].image?.url}
          alt={place.categories[0].image.alternativeText || ''}
        />
        <CellEmpty height="7" />
        <p className={styles.placeCategoryLabel}>
          {place.categories[0].text}
        </p>
      </FlexContainer>
    )
  }

  const imagesGallery: Array<ImageModel> = [];
  if (place.image) {
    imagesGallery.push(place.image);
  }
  if (place.additionalImages) {
    imagesGallery.push(...place.additionalImages)
  }

  return (
    <div ref={setRef} className={styles.imagesContainer}>
      {imagesGallery.map(
        (image: ImageModel, index: number) => (
          <img
            key={index}
            src={image.url}
            alt={image.alternativeText || ''}
            className={styles.getImageTileStyle(imgSize)}
          />
        )
      )}
    </div>
  )
}
