import { css } from '@emotion/css'

const textCommon = css`
  color: #000;
  text-align: center;
  font-family: Copperplate, serif;
  font-style: normal;
  line-height: normal;
`
export const segmentWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const segmentDescription = css`
  ${textCommon};
  font-size: 14px;
  font-weight: 300;
  margin-top: 4px;
`
export const segment = css`
  border-radius: 8px;
  border: 2px solid #f2f2f2;
  background: #f6f6f6;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
  width: 100%;

  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    ${textCommon};
    font-size: 18px;
    font-weight: 400;
  }

  input[type='radio'] {
    display: none;
  }
`

export const segmentSelected = css`
  background: #ffde00;
`
