import { deserialize, JsonArray, JsonStruct } from 'tserialize'
import { UserMeetingModel } from '../models/user-meeting.model.ts'
import { PaginationModel } from 'api/models/pagination.model.ts'

export class GetUserMeetingsResponse {
  @JsonArray(UserMeetingModel)
  results: Array<UserMeetingModel> = []

  @JsonStruct(PaginationModel)
  pagination: PaginationModel = new PaginationModel()

  static fromServer(data: object): GetUserMeetingsResponse {
    return deserialize(data, GetUserMeetingsResponse)
  }
}
