import { UserMeetingModel } from 'api/user-meetings/models/user-meeting.model.ts'
import { userMeetingsTransport } from 'api/user-meetings/user-meetings.transport.ts'
import { makeAutoObservable } from 'mobx'
import { TPickerTimeslot } from 'types/time-picker.types.ts'
import { getPickerTimeslots } from 'utils/time-picker.utils.ts'

class MeetingDetailsStore {
  meeting: UserMeetingModel | null = null
  isLoading: boolean = false
  hasError: boolean = false

  selectedTimeslots: Array<TPickerTimeslot> = []

  constructor() {
    makeAutoObservable(this)
  }

  setMeeting = (meeting: UserMeetingModel): void => {
    this.meeting = meeting
  }

  setSelectedTimeslots = (selectedTimeslots: Array<TPickerTimeslot>): void => {
    this.selectedTimeslots = selectedTimeslots
  }

  setIsLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading
  }

  setHasError = (hasError: boolean): void => {
    this.hasError = hasError
  }

  getMeeting = (meetingId: string | number): void => {
    this.setIsLoading(true)
    this.setHasError(false)

    userMeetingsTransport
      .getUserMeeting(meetingId)
      .then((meeting: UserMeetingModel) => {
        this.setMeeting(meeting)
        this.setSelectedTimeslots(getPickerTimeslots(meeting.timeslots))
      })
      .catch(() => {
        this.setHasError(true)
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }
}

export const meetingDetailsStore = new MeetingDetailsStore()
