import { DOMAttributes, JSX } from 'react'
import { CellEmpty } from 'ui/cell-empty'
import style from './intro-lets-talk.module.css'

type TIntroButtonProps = {
  /** Картинка на фон */
  src: string
  /** Текст-подпись */
  children: string
  /** События клика */
  onClick?: DOMAttributes<HTMLButtonElement>['onClick']
}

export function IntroButton({
  src,
  children,
  onClick,
}: TIntroButtonProps): JSX.Element {
  return (
    <button className={style.roundButton} onClick={onClick}>
      <div
        className={style.buttonIcon}
        style={{
          background: `transparent url("${src}") center/cover no-repeat`,
        }}
      />

      <CellEmpty height={5} />
      {children}
    </button>
  )
}
