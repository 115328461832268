import { TRadioOption } from 'ui/radio'

export type TSurveyTipData = {
  step: string
  title: string
  subtitle: string
}

export const SURVEY_TIPS: Array<TSurveyTipData> = [
  { step: '1', title: 'ТОЧНО НЕ', subtitle: 'ПРО МЕНЯ' },
  { step: '2', title: 'РЕДКО', subtitle: 'ПРО МЕНЯ' },
  { step: '3', title: 'ИНОГДА', subtitle: 'ПРО МЕНЯ' },
  { step: '4', title: 'ЧАСТО', subtitle: 'ПРО МЕНЯ' },
  { step: '5', title: 'ВСЕГДА', subtitle: 'ПРО МЕНЯ' },
]

// TODO тут временные хардкод данные для вёрстки
export const QUESTIONS_OPTIONS: Array<TRadioOption<number>> = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
]
