import { css } from '@emotion/css'

export const createPlacePage = css`
  padding: 0 1.5rem 2rem;

  label {
    color: #000;
    font-family: 'Chalkboard SE', serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.408px;
  }
`

export const placeCategoriesWrapper = css`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  width: 100%;
  box-sizing: border-box;
`

export const priceSegments = css`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 10px;
`
export const tip = css`
  margin-left: 4px;
  width: 24px;
  height: 24px;
`

export const formAlert = css`
  text-align: center;
  color: red;
`
