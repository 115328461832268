import { userMeetingsTransport } from 'api/user-meetings/user-meetings.transport.ts'
import { makeAutoObservable } from 'mobx'
import { meetingDetailsStore } from 'store/meeting-details.store.ts'

class MeetingConfirmStore {
  isConfirming: boolean = false
  isDeclining: boolean = false
  hasError: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setIsLoading = (isConfirmed: boolean, isLoading: boolean): void => {
    if (isConfirmed) {
      this.isConfirming = isLoading
    } else {
      this.isDeclining = isLoading
    }
  }

  setHasError = (hasError: boolean): void => {
    this.hasError = hasError
  }

  setIsMeetingAccepted = (meetingId: number, isConfirmed: boolean): void => {
    this.setIsLoading(isConfirmed, true)
    this.setHasError(false)

    userMeetingsTransport.setMeetingConfirmation(meetingId, isConfirmed)
      .then(() => {
        meetingDetailsStore.getMeeting(meetingId)
      })
      .catch(() => {
        this.setHasError(true)
      })
      .finally(() => {
        this.setIsLoading(isConfirmed, false)
      })
  }

  reset = (): void => {
    this.isConfirming = false
    this.isDeclining = false
    this.hasError = false
  }
}

export const meetingConfirmStore = new MeetingConfirmStore()
