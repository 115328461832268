import { JSX, useState } from 'react'
import { ProgressBar } from 'ui/progress-bar'
import style from './survey-customization.module.css'
import stylesCommon from '../survey.module.css'
import { CellEmpty } from 'ui/cell-empty.tsx'
import starImg from 'assets/star.png'
import { SanekTip } from 'components/common/sanek-tip'
import { ChangeAvatarButton } from 'components/change-avatar-button'
import { SanekButton } from 'ui/sanek-button'
import { AudioRecorder } from 'components/audio-recorder'
import { sanekUsersTransport } from 'api/sanek-users.transport.ts'
import { userStore } from 'store/user.store.ts'
import { observer } from 'mobx-react-lite'
import { USER_NOT_FOUND_ERROR } from 'const/common.const.ts'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums.ts'
import { audioStore } from 'store/audio.store.ts'
import { useRollbar } from '@rollbar/react'

export const SurveyCustomizationPage = observer((): JSX.Element => {
  const { user } = userStore
  const { recordedAudio } = audioStore

  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const rollbar = useRollbar()

  const handleContinue = (): void => {
    if (!user) {
      rollbar.error(USER_NOT_FOUND_ERROR)
      return
    }

    if (!recordedAudio) {
      return
    }

    setIsProcessing(true)

    void sanekUsersTransport
      .finishSurvey(user.id, recordedAudio)
      .then(() => {
        void router.navigate(SanekRoute.MAIN)
        audioStore.reset()
      })
      .finally(() => setIsProcessing(false))
  }

  return (
    <div className={style.surveyCustomizationPage}>
      <div className={stylesCommon.surveyHeader} style={{ flex: '0 1 auto' }}>
        <h3 className={stylesCommon.surveyHeaderTitle}>
          Дружба с&nbsp;Саньком
        </h3>
        <CellEmpty height="7" />

        <div className={style.starsBlock}>
          {Array.from(Array(5)).map((_: undefined, index: number) => (
            <img key={index} src={starImg} alt="star" />
          ))}
        </div>
        <ProgressBar percentage={100} />
        <p className={style.progressText}>Теперь вы с Саньком лучшие друзья</p>
      </div>
      <div className={style.surveyCustomizationContent}>
        <div>
          <SanekTip text="Выбрал для тебя аватар, который будут видеть другие пользователи. Если хочешь можешь поменять" />
          <CellEmpty height="15" />

          <div className={style.changeAvatarWrapper}>
            {user && <ChangeAvatarButton user={user} />}
          </div>
          <CellEmpty height="40" />

          <SanekTip text="Чтобы было комфортнее встречаться с новыми людьми, запиши, пожалуйста, голосовое приветствие на 5 секунд" />
          <CellEmpty height="20" />
        </div>
        <AudioRecorder />
        <div>
          <SanekButton
            disabled={!recordedAudio}
            onClick={handleContinue}
            isLoading={isProcessing}
          >
            Далее
          </SanekButton>
        </div>
      </div>
    </div>
  )
})
