import { type BlocksContent } from '@strapi/blocks-react-renderer'
import NewsDivider from 'assets/news-divider.svg'
import { BlockRendererClient } from 'components/block-rerender-client'
import { CenteredLoader } from 'components/common/centered-loader'
import { LayoutPage } from 'components/layout-page'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { storiesStore } from 'store/stories.store'
import { CellEmpty } from 'ui/cell-empty'
import { SVGImg } from 'ui/svg-img'
import { navigateBack } from 'utils/navigation'
import * as styles from './news.style'

type TNewsParams = {
  id: string
}

export const News = observer((): JSX.Element => {
  const { id } = useParams<TNewsParams>()

  const { stories, isLoading } = storiesStore
  console.log(stories)

  if (isLoading) {
    return <CenteredLoader />
  }

  const story = stories.find((story) => story.id === Number(id))

  const content: BlocksContent = story?.attributes.info || []

  return (
    <LayoutPage headerText="Советы" backAction={navigateBack}>
      <div className={styles.mainContainer}>
        <h1 className={styles.title}>{story?.attributes.title}</h1>
        <SVGImg src={NewsDivider} />
        <CellEmpty height="20" />
        <img
          src={`${story?.attributes?.nested_image?.data?.attributes?.url}`}
          alt="лого"
          className={styles.image}
        />
        <CellEmpty height="20" />
        <BlockRendererClient content={content} />
      </div>
    </LayoutPage>
  )
})
