import { css } from '@emotion/css'

export const mainContainer = css`
  display: flex;
  flex-direction: column;
  padding: 0px 25px 40px;
  width: 100%;

  h3 {
    font-family: 'Chalkboard SE', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: #000;
  }
`

export const inputStyle = css`
  resize: none;
  border: 2px solid #f2f2f2;
  border-radius: 11px;
  padding: 9px 14px;
  width: 100%;
  height: 600px;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.25);
  background: #f6f6f6;
  font-family: 'Copperplate', sans-serif;
  font-weight: 300;
  font-size: 16px;
  color: #000;
`

export const formAlert = css`
  text-align: center;
  color: red;
`
