import { css } from '@emotion/css'

const centeredContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const slideContainer = css`
  display: flex;
  position: relative;
  padding-top: 37px;
`

export const card = css`
  ${centeredContainer};
  flex-direction: column;
  width: 100%;
  border-radius: 11px;
  background: #f7f7f7;
  padding: 33px 17px;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 100%;
`

export const image = css`
  width: 252px;
  height: 252px;
  margin-bottom: 14px;
`

export const title = css`
  width: 206px;
  font-family: 'Copperplate', sans-serif;
  font-weight: 700;
  font-size: 26px;
  text-align: center;
  line-height: 24px;
  color: #000;
  margin-bottom: 26px;
`

export const description = css`
  padding: 15px;
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 30px;
  margin-bottom: 26px;

  font-family: 'Chalkboard SE', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.03em;
  color: #000;
`

export const descriptionClosed = css`
  ${description};
  text-align: center;
  font-size: 64px;
  background: #cdcdcd;
`

export const grade = css`
  ${centeredContainer};
  border-radius: 33px;
  width: 270px;
  height: 32px;
  background: #ffde00;
  font-family: 'Copperplate', sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #000;
`

const status = css`
  ${centeredContainer};
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 11px;
  width: 90px;
  height: 28px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #000;
`

export const current = css`
  ${status};
  background: #ffde00;
`

export const done = css`
  ${status};
  background: #f7f7f7;
`

export const closed = css`
  ${status};
  background: #888;
`
