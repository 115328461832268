import { makeAutoObservable } from 'mobx'
import { TStoryModel } from 'api/models/story.model'
import { storiesTransport } from 'api/stories.transport'

class StoriesStore {
  stories: Array<TStoryModel> = []
  isLoading: boolean = false
  hasError: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setStories = (stories: Array<TStoryModel>): void => {
    this.stories = stories
  }

  setIsLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading
  }

  setHasError = (hasError: boolean): void => {
    this.hasError = hasError
  }

  getStories = (): void => {
    this.setIsLoading(true)
    this.setHasError(false)

    void storiesTransport
      .getStories()
      .then((data: Array<TStoryModel>) => {
        this.setStories(data)
      })
      .catch(() => {
        this.setHasError(true)
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }
}

export const storiesStore = new StoriesStore()
