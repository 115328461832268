import { JSX } from 'react'
import styles from './loader.module.css'
import Man from 'assets/man.png'

type TLoaderProps = {
  inverse?: boolean
}
export const Loader = ({ inverse }: TLoaderProps): JSX.Element => {
  return (
    <div className={styles.loaderWrapper}>
      <div className={styles.chase}>
        {Array.from(Array(8)).map((_: undefined, index: number) => (
          <div
            key={index}
            className={`${styles.chaseDot} ${
              inverse ? styles.inverseChaseDot : ''
            }`}
          />
        ))}
      </div>
      <div className={styles.imageWrapper}>
        <img className={styles.image} src={Man} height="151" width="150" />
      </div>
    </div>
  )
}
