import * as styles from './add-point-map-modal.style'
import { CellEmpty } from 'ui/cell-empty'
import { SanekButton } from 'ui/sanek-button'

import ButtonBack from 'assets/btn-back.svg'
import Play from 'assets/play-btn.svg'
import GuideOne from 'assets/guide-map-1.jpeg'
import GuideTwo from 'assets/guide-map-2.jpeg'
import Share from 'assets/share-btn.png'
import { JSX } from 'react'
import { closeAllDialogs } from 'utils/dialog.ts'
import { SeparatorBlue } from 'components/common/separator'

export const AddPointMapModal = (): JSX.Element => {
  return (
    <div className={styles.mainContainer}>
      <div onClick={closeAllDialogs}>
        <img src={ButtonBack} className={styles.buttonBack} />
        <img src={Play} className={styles.play} />
      </div>

      <h2 className={styles.header}>Как добавить точку на карте</h2>

      <p className={styles.info}>
        Найди свое заведение в приложении Яндекс Карты и нажми кнопку поделиться
        <img src={Share} className={styles.share} />
      </p>

      <img
        src={GuideOne}
        alt="назад"
        width="273"
        height="591"
        className={styles.image}
      />

      <SeparatorBlue className={styles.image} />

      <p className={styles.info}>
        Скоприруй ссылку и вставь в соответсвующее поле в приложении САНЁК ЗОВЁТ
      </p>

      <img
        src={GuideTwo}
        alt="назад"
        width="273"
        height="591"
        className={styles.image}
      />

      <div className={styles.buttonBlock}>
        <SanekButton onClick={closeAllDialogs}>ПОНЯТНО</SanekButton>
      </div>
      <CellEmpty height={40} />
    </div>
  )
}
