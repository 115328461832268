import * as styles from './slider-vector.style'
import cx from 'classnames'
import Vector from 'assets/slider-vector.svg'

type SliderVectorProps = {
  prev?: boolean
  disabled?: boolean
  onClick: () => void
}

export const SliderVector = ({
  prev,
  disabled,
  onClick,
}: SliderVectorProps): JSX.Element => {
  return (
    <div
      className={cx(prev ? styles.customPrevButton : styles.button, {
        [styles.disabled]: disabled,
      })}
      onClick={onClick}
    >
      <img src={Vector} alt="Стрелка" />
    </div>
  )
}
