import { JSX } from 'react'
import * as styles from './message-avatar.style.ts'

type TMessageAvatarProps = {
  avatarImg: string
  isOwnMessage: boolean
}

export function MessageAvatar({ avatarImg, isOwnMessage }: TMessageAvatarProps): JSX.Element {
  return (
    <div className={styles.getAvatarStyle(isOwnMessage)}>
      <img
        src={avatarImg}
        alt="Аватар"
        className={styles.avatarImage}
      />
    </div>
  )
}
