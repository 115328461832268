import { JsonName, JsonStruct, serialize } from 'tserialize'
import { FilterModel } from '../models/filter.model.ts'
import { InvitationSortType } from 'enums/invitations-sort-types.enum.ts'

export class GetUserMeetingsRequest {
  @JsonStruct(FilterModel)
  filters: FilterModel = new FilterModel({})

  @JsonName()
  sort?: InvitationSortType

  constructor(data: Omit<GetUserMeetingsRequest, 'toServer'>) {
    Object.assign(this, data)
  }

  toServer(): object {
    return serialize(this)
  }
}
