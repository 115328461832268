import { css } from '@emotion/css'
import { maxLines } from 'styles/common.style.ts'

export const placeItem = css`
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  padding: 0px 14px;
`

export const placeName = css`
  font-family: 'Chalkboard SE', serif;
  font-size: 20px;
  font-weight: 400;
`

export const placeHr = css`
  display: block;
  width: 100%;
`

export const commonText = css`
  font-size: 12px;
  font-weight: 300;

  @media (max-width: 400px) {
    font-size: 10px;
  }
`

export const placePriceSegment = css`
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
  min-width: 35px;
  text-align: right;

  @media (max-width: 400px) {
    margin-left: 0;
    font-size: 12px;
  }
`

export const placeDescription = css`
  ${commonText};
  ${maxLines(3)}
`

export const buttonSaveContainer = css`
  z-index: 10;
`
