import {
  MONTH_MAP,
  STRAPI_DATE_FORMAT,
  TIME_TEMPLATE,
} from 'const/date.const.ts'
import dayjs from 'dayjs'
import { JSX } from 'react'

export function getTime(date: string): number {
  return new Date(date).getTime()
}

export function getFormattedDateLabel(dayjsDate: dayjs.Dayjs): JSX.Element {
  const day = dayjsDate.format('dddd')
  const date = dayjsDate.format('D MMMM')

  return (
    <p>
      {day}
      <br />
      {date}
    </p>
  )
}

export function getFormattedTime(date: dayjs.Dayjs): string {
  return date.format(TIME_TEMPLATE)
}

export function getFormattedStrapiDate(date: dayjs.Dayjs): string {
  return date.format(STRAPI_DATE_FORMAT)
}

export function getFormattedStrapiTime(date: dayjs.Dayjs): string {
  return date.format('HH:mm:ss.SSS')
}

export function getFormatDateMessage(date: dayjs.Dayjs) {
  return date.format('dd, D MMMM')
}

export function getFormatTimeMessage(
  timeFrom: dayjs.Dayjs,
  timeTo: dayjs.Dayjs
) {
  const timeFromFormatted = timeFrom.format(TIME_TEMPLATE)
  const timeToFormatted = timeTo.format(TIME_TEMPLATE)
  return `${timeFromFormatted}-${timeToFormatted}`
}

export function renderPickerLabel(type: string, data: number): string | number {
  switch (type) {
    case 'month':
      return MONTH_MAP[data]
    default:
      return data
  }
}
