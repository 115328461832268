import { SanekUser } from 'api/models/sanek-user.model.ts'
import { transport } from './transport.ts'
import { AxiosResponse } from 'axios'

export class UsersTransport {
  getMe(): Promise<SanekUser> {
    const jwt = localStorage.getItem('jwt')
    return transport
      .get(`${import.meta.env.VITE_STRAPI_API}/api/users/me`, {
        headers: { Authorization: `Bearer ${jwt}` },
      })
      .then(({ data }: AxiosResponse<SanekUser>) => {
        return SanekUser.fromServer(data)
      })
  }
}

export const usersTransport = new UsersTransport()
