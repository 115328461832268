import { css } from '@emotion/css'

export const contentWrapper = css`
  max-width: 1080px;
  margin: 0 auto;
  padding-bottom: 200px;
`

export const title = css`
  font-family: 'Chalkboard SE', serif;
  font-weight: 400;
  font-size: 60px;
  color: #000;
`

export const text = css`
  font-family: 'Copperplate', serif;
  line-height: 44px;
  font-weight: 400;
  font-size: 40px;
  color: #000;
`
export const tariffsWrapper = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const tariff = css`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const tariffPrice = css`
  font-family: 'Copperplate', serif;
  font-weight: 700;
  font-size: 50px;
  text-align: center;
  color: #000;
`
