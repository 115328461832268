import { CellEmpty } from 'ui/cell-empty.tsx'
import * as styles from './selected-place.style.ts'
import { Fragment, JSX } from 'react'
import { ResetButton } from 'ui/reset-button'
import { meetingCreationStore } from 'store/meeting-creation.store.ts'

export const SelectedPlace = (): JSX.Element => {
  const { selectedPlace } = meetingCreationStore

  return (
    <Fragment>
      <ResetButton onClick={handleReset} />
      <CellEmpty height="4" />

      <div className={styles.selectedPlace}>
        <div>
          <p className={styles.title}>Название:</p>
          <p className={styles.name}>{selectedPlace?.name}</p>
        </div>
        <div>
          <p className={styles.title}>Адрес:</p>
          <p className={styles.name}>{selectedPlace?.address}</p>
        </div>
      </div>
    </Fragment>
  )
}

function handleReset(): void {
  meetingCreationStore.setSelectedPlace(null)
  meetingCreationStore.setSelectedCategory(null)
}
