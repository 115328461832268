export enum PriceSegment {
  BUDGET = 'budget',
  MEDIUM = 'medium',
  EXPENSIVE = 'expensive'
}

export enum PlaceSorting {
  POPULAR = 'popular',
  NEW = 'new',
  SANEK_CHOICE = 'sanek_choice'
}
