import { JSX } from 'react';
import * as styles from './copy-alert.style.ts';

export function CopyAlert(): JSX.Element {
  return (
    <div id="sanek-global-copy-alert" className={styles.copyAlert}>
      Скопировано
    </div>
  )
}
