import { JSX } from 'react'
import * as styles from './categories-block.style.ts'
import { meetingCategoriesStore } from 'store/meeting-categories.store.ts'
import { CategoryCard } from '../category-card'
import { MeetingCategoryModel } from 'api/meeting/models/meeting-category.model.ts'

export const CategoriesBlock = (): JSX.Element => {
  const { categories, isLoading } = meetingCategoriesStore

  if (isLoading) {
    return <div>Loading</div>
  }

  return (
    <div className={styles.container}>
      {categories.map((category: MeetingCategoryModel) => (
        <CategoryCard key={category.id} category={category} />
      ))}
    </div>
  )
}
