import styles from './user-avatar.module.css'

import { router } from 'router'

import { SanekRoute } from 'router/router.enums'

import { ImageModel } from 'api/models/image.model.ts'
import { ProgressiveImage } from 'components/progressive-image/progressive-image.tsx'

type Props = {
  image: ImageModel
}

export default function UserAvatar({ image }: Props) {
  const handleNavigatePersonalPage = (): void => {
    void router.navigate(SanekRoute.PERSONAL)
  }

  return (
    <div className={styles.mainContainer} onClick={handleNavigatePersonalPage}>
      <ProgressiveImage image={image} alt="avatar" className={styles.image} />
    </div>
  )
}
