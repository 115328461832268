import { css } from '@emotion/css'

export const button = css`
  cursor: pointer;
  color: #878787;
`

export const customPrevButton = css`
  ${button};
  transform: scaleX(-1);
`

export const disabled = css`
  opacity: 0;
`
