import { css } from '@emotion/css'
import { flexCenterContent } from 'styles/common.style.ts'
import { buttonReset } from 'styles/reset.style.ts'

export const timeslot = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  margin-left: 12px;
  border-bottom: 3px solid white;

  p {
    color: #000;
    font-family: Copperplate, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
`

export const removeButton = css`
  ${flexCenterContent}
  ${buttonReset}
  height: 24px;
  width: 24px;
  cursor: pointer;
  background: transparent;
`
