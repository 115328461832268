import { cx } from '@emotion/css'
import * as styles from './survey-avatars.slyle.ts'

import { JSX } from 'react'

import { ImageModel } from 'api/models/image.model.ts'

import { ProgressiveImage } from 'components/progressive-image/progressive-image.tsx'

type TSurveyTipProps = {
  /** Путь для картинки */
  image: ImageModel
  /** Select карточки */
  isSelected: boolean
  /** Onclick в родителя */
  onClick: () => void
}

export function AvatarCard({
  image,
  isSelected,
  onClick,
}: TSurveyTipProps): JSX.Element {
  return (
    <div className={styles.card} onClick={onClick}>
      <ProgressiveImage image={image} alt="Аватар" className={styles.cardImg} />
      <div
        className={cx(styles.bottomStand, {
          [styles.selected]: isSelected,
        })}
      ></div>
    </div>
  )
}
