import { css } from '@emotion/css'
import { flexCenterContent } from 'styles/common.style.ts'

export const mainContainer = css`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  border: 5px solid #f2f2f2;
  background-color: #ffde00;
  box-shadow: 2px 3px 0 0 rgba(0, 0, 0, 0.25);

  ${flexCenterContent};
  flex-direction: column;
`

export const expiredBg = css`
  background-color: #ff6242;
`
