import { makeAutoObservable } from 'mobx'
import { meetingTransport } from 'api/meeting/meeting.transport.ts'
import { MeetingCategoryModel } from 'api/meeting/models/meeting-category.model.ts'

class MeetingCategoriesStore {
  categories: Array<MeetingCategoryModel> = []
  isLoading: boolean = false
  hasError: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCategories = (categories: Array<MeetingCategoryModel>): void => {
    this.categories = categories
  }

  setIsLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading
  }

  setHasError = (hasError: boolean): void => {
    this.hasError = hasError
  }

  getCategories = (): void => {
    this.setIsLoading(true)
    this.setHasError(false)

    void meetingTransport
      .getMeetingCategories()
      .then((data: Array<MeetingCategoryModel>) => {
        this.setCategories(data)
      })
      .catch(() => {
        this.setHasError(true)
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }
}

export const meetingCategoriesStore = new MeetingCategoriesStore()
