import { CityModel } from 'api/cities/models/city.model.ts'
import { MetroStationModel } from 'api/models/metro-station.model.ts'
import { SanekUser } from 'api/models/sanek-user.model.ts'
import { CityPicker } from 'components/city-picker'
import { LayoutPage } from 'components/layout-page'
import { PriceSegmentRadio } from 'components/price-segment-radio'
import { PRICE_SEGMENT_OPTIONS } from 'const/places.const.ts'
import { PriceSegment } from 'enums/places.enums.ts'
import { ChangeEvent, JSX, useEffect, useRef, useState } from 'react'
import { userStore } from 'store/user.store.ts'
import { TPlaceFilterForm } from 'types/place-filters.types.ts'
import { FormItem } from 'ui/form-item'
import { TRadioOption } from 'ui/radio'
import { ResetButton } from 'ui/reset-button'
import { SanekButton } from 'ui/sanek-button'
import * as styles from './place-filters.style.ts'
import { placesStore } from 'store/places.store.ts'
import { MetroStationMultiSelectDialog } from 'components/metro-station-dialog'
import { getMetroButtonText } from 'utils/string.ts'
import { openDialog } from 'utils/dialog.ts'
import { DialogShowHandler } from 'antd-mobile/es/components/dialog'

function getDefaultPlaceFiltersForm(user: SanekUser | null): TPlaceFilterForm {
  return {
    city: user?.city || null,
    metroStations: [],
    priceSegment: null,
  }
}

type TPlaceFiltersProps = {
  backAction: VoidFunction
  onSubmit: (form: TPlaceFilterForm) => void
}

export function PlaceFilters({
  backAction,
  onSubmit,
}: TPlaceFiltersProps): JSX.Element {
  const { user } = userStore
  const { isLoading } = placesStore
  const [form, setForm] = useState<TPlaceFilterForm>(
    getDefaultPlaceFiltersForm(user)
  )

  const dialogHandlerRef = useRef<DialogShowHandler | null>(null)

  useEffect(() => {
    return () => {
      dialogHandlerRef.current = null
    }
  }, [])

  const handleSubmit = (): void => {
    onSubmit(form)
  }

  const updateForm = (value: Partial<TPlaceFilterForm>): void => {
    setForm((prevState: TPlaceFilterForm) => ({
      ...prevState,
      ...value,
    }))
  }

  const handleCityChange = (city: CityModel): void => {
    updateForm({
      city,
      metroStations: [],
    })
  }

  const handleMetroStationChange = (
    metroStations: MetroStationModel[]
  ): void => {
    updateForm({ metroStations })
  }

  const handlePriceSegmentSelect = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const priceSegment = event.target.value as PriceSegment
    updateForm({ priceSegment })
  }

  const handleResetClick = (): void => {
    setForm(getDefaultPlaceFiltersForm(user))
  }

  const handleMetroSelectionComplete = (
    metroStations: MetroStationModel[]
  ): void => {
    handleMetroStationChange(metroStations)
    dialogHandlerRef.current?.close()
  }

  const handleOpenMetroStationsDialog = (): void => {
    dialogHandlerRef.current = openDialog({
      content: (
        <MetroStationMultiSelectDialog
          cityId={form.city?.id}
          onSelect={handleMetroSelectionComplete}
        />
      ),
    })
  }

  const metroButtonText = getMetroButtonText(form)

  return (
    <LayoutPage
      headerText="Фильтры"
      footerNode={
        <div className={styles.placeFiltersFooter}>
          <SanekButton
            type="secondary"
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            Сохранить
          </SanekButton>
        </div>
      }
      backAction={backAction}
    >
      <div className={styles.placeFiltersPage}>
        <ResetButton onClick={handleResetClick} />

        <FormItem>
          <label htmlFor="city" className={styles.fieldLabel}>
            Город
          </label>
          <CityPicker value={form.city?.name} onChange={handleCityChange} />
        </FormItem>

        <FormItem>
          <label htmlFor="metro" className={styles.fieldLabel}>
            Метро
          </label>
          <div
            className={styles.openMetroModalBtn}
            onClick={handleOpenMetroStationsDialog}
          >
            {metroButtonText}
          </div>
        </FormItem>

        <FormItem hasNext={false}>
          <p className={styles.fieldLabel}>Ценовой сегмент</p>
          <div className={styles.priceSegments}>
            {PRICE_SEGMENT_OPTIONS.map((option: TRadioOption<PriceSegment>) => (
              <PriceSegmentRadio
                key={option.value}
                option={option}
                checked={form.priceSegment === option.value}
                onChange={handlePriceSegmentSelect}
              />
            ))}
          </div>
        </FormItem>
      </div>
    </LayoutPage>
  )
}
