import ChatHeaderLines from 'assets/decorations/chat/chat-header-lines.png'
import { LayoutPage } from 'components/layout-page'
import { SanekEmptyView } from 'components/sanek-empty-view'
import { JSX } from 'react'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { closeAllDialogs } from 'utils/dialog.ts'
import * as styles from './website-missing-dialog.style.ts'

export function WebsiteMissingDialog(): JSX.Element {
  return (
    <LayoutPage
      headerText="Сайт места"
      backAction={closeAllDialogs}
    >
      <img src={ChatHeaderLines} alt="" className={styles.websiteMissingHeaderImage} />
      <div className={styles.websiteMissingDialogPage}>
        <CellEmpty height="40" />
        <SanekEmptyView
          title="Кажется друг не добавил ссылку"
          description={
            `
              Не у всех мест/заведений есть свой сайт. Но также друг мог просто забыть добавить ссылку на сайт.\n
              Попробуй самостоятельно найти описание заведения по его названию и адресу
            `
          }
        />
      </div>
    </LayoutPage>
  )
}
