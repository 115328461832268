import { CityModel } from 'api/cities/models/city.model.ts'
import { observer } from 'mobx-react-lite'
import { JSX, useEffect, useState } from 'react'
import { citiesStore } from 'store/cities.store.ts'
import * as antdStyles from 'styles/antd-mobile.style.ts'
import { Picker } from 'antd-mobile'
import { CenteredInput } from 'ui/centered-input'
import { PickerValue } from 'antd-mobile/es/components/picker-view'
import { noop } from 'antd-mobile/es/utils/noop'

type TCitySelectProps = {
  value?: string
  onChange: (city: CityModel) => void
}

export const CityPicker = observer(
  ({ value, onChange }: TCitySelectProps): JSX.Element => {
    const { cities, isLoading } = citiesStore

    const [isCityPickerOpen, setIsCityPickerOpen] = useState<boolean>(false)

    const columns = cities.map((city: CityModel) => ({
      label: city.name,
      value: city.code,
    }))

    useEffect(() => {
      if (cities.length === 0) {
        citiesStore.getCities()
      }
    }, [cities])

    const handleOpenCityPicker = (): void => {
      setIsCityPickerOpen(true)
    }

    const handleCloseCityPicker = (): void => {
      setIsCityPickerOpen(false)
    }

    const handleConfirm = ([value]: Array<PickerValue>): void => {
      const city = cities.find((city: CityModel) => city.code === value)

      if (!city) {
        console.error('Город не найден')
        return
      }

      onChange(city)
    }

    return (
      <>
        <CenteredInput
          value={value || ''}
          readOnly
          placeholder="Выберите город"
          onClick={handleOpenCityPicker}
          onChange={noop}
          isDropdown
        />

        <Picker
          loading={isLoading}
          visible={isCityPickerOpen}
          columns={[columns]}
          className={antdStyles.picker}
          cancelText="Отмена"
          confirmText="Подтвердить"
          onClose={handleCloseCityPicker}
          onConfirm={handleConfirm}
        />
      </>
    )
  }
)
