import { css } from '@emotion/css'

export const emptyViewTitle = css`
  font-family:
    Chalkboard SE,
    serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
`

export const emptyViewImage = css`
  display: block;
  width: 85%;
`

export const emptyViewSeparator = css`
  ${emptyViewImage};
  height: 11px;
`

export const emptyViewDescription = css`
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  font-family: 'Copperplate', sans-serif;
  white-space: pre-line;
`
