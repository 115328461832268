import { ImageModel } from 'api/models/image.model.ts'
import { SanekUser } from 'api/models/sanek-user.model.ts'
import { userStore } from 'store/user.store.ts'
import { JsonName, JsonNameReadonly, JsonStruct, serialize } from 'tserialize'

export class ChatMessageModel {
  @JsonNameReadonly()
  id!: number

  @JsonName()
  text: string = ''

  @JsonName()
  link: string = ''

  @JsonNameReadonly('seen')
  isSeen: boolean = false

  @JsonName()
  photo: ImageModel | null = null

  @JsonStruct(SanekUser, 'sanek_user')
  sanekUser!: Pick<SanekUser, 'id' | 'avatar'>

  get isOwnMessage(): boolean {
    return this.sanekUser.id === userStore.user?.id
  }

  constructor(data: Omit<ChatMessageModel, 'id' | 'isSeen' | 'sanekUser' | 'isOwnMessage' | 'toServer'>) {
    Object.assign(this, data)
  }

  toServer(): object {
    return serialize(this)
  }
}
