import { css } from '@emotion/css'

export const sendingInvitationsTitle = css`
  font-family: Chalkboard SE, serif;
  font-size: 24px;
  text-align: center;
`

export const sendingInvitationsDescription = css`
  font-size: 14px;
  text-align: center;
`

export const editTimeTitle = css`
  font-family: 'Chalkboard SE', serif;
  font-size: 20px;
  font-weight: 700;
`

export const editTimeDescription = css`
  font-weight: 300;
  font-size: 10px;
  text-align: center;
`
