import { transport } from 'api/transport.ts'
import { CreateMeetingFeedbackRequest } from './models/create-meeting-feedback.request'

export class MeetingFeedbackTransport {
  createMeetingFeedback(
    feedbackText: string,
    meetingId: number
  ): Promise<void> {
    const request = new CreateMeetingFeedbackRequest({
      text: feedbackText,
      meetingId,
    })

    return transport.post(
      `${import.meta.env.VITE_STRAPI_API}/api/add-meeting-feedback`,
      {
        data: request.toServer(),
      }
    )
  }
}

export const meetingFeedbackTransport = new MeetingFeedbackTransport()
