import { AxiosResponse } from 'axios'
import { makeQueryString } from 'utils/query.ts'
import { MetroStationModel } from './models/metro-station.model.ts'
import { transport } from './transport.ts'

export class MetroStationsTransport {
  getMetroStations(cityId?: number) {
    const query = makeQueryString({ cityId })

    return transport
      .get(`${import.meta.env.VITE_STRAPI_API}/api/metro-stations${query}`)
      .then(({ data }: AxiosResponse<Array<object>>) => {
        return data.map((item: object) => MetroStationModel.fromServer(item))
      })
  }
}

export const metroStationsTransport = new MetroStationsTransport()
