export function openYandexMapOrg(yandexOrgId: string): void {
  window.open(yandexOrgId, '_top')
}

export function getOidFromUrl(url: string): string {
  const splittedUrl = url.split('/')
  const splittedUrlLength = splittedUrl.length
  if (splittedUrl[splittedUrlLength - 2] !== 'org') {
    return ''
  }

  return splittedUrl[splittedUrlLength - 1]
}

export function extractYandexMapsLink(text: string) {
  const regex = /https:\/\/yandex\.ru\/(maps|navi)\/[^\s]+/g
  const matched = text.match(regex)
  return matched ? matched[0] : text
}
