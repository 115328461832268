import { TariffModel } from 'api/tariff/models/tariff.model.ts'
import { tariffTransport } from 'api/tariff/tariff.transport.ts'
import { makeAutoObservable } from 'mobx'

class TariffStore {
  tariffs: Array<TariffModel> = []
  isLoading: boolean = false
  hasError: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setTariffs = (tariffs: Array<TariffModel>): void => {
    this.tariffs = tariffs
  }

  setIsLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading
  }

  setHasError = (hasError: boolean): void => {
    this.hasError = hasError
  }

  getTariffs = (): void => {
    this.setIsLoading(true)
    this.setHasError(false)

    tariffTransport.getTariffs()
      .then(this.setTariffs)
      .catch(() => this.setHasError(true))
      .finally(() => this.setIsLoading(false))
  }
}

export const tariffStore = new TariffStore()
