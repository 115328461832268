import { css } from '@emotion/css'
import MeetingLines from 'assets/decorations/meeting-details/meeting-lines.png'

export const meetingDetailsPage = css`
  padding: 0 1.5rem 2rem;
`

export const linesContainer = css`
  background: url(${MeetingLines}) center/contain no-repeat;
  height: 52px;
`

export const imagesContainer = css`
  position: relative;
  top: -15px;
`

export const moodImage = css`
  width: 50px;
  margin-right: 15px;
`

export const categoryImage = css`
  max-height: 50px;
`
