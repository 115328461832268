import { ImageModel } from 'api/models/image.model.ts'
import { deserialize, JsonNameReadonly, JsonStruct } from 'tserialize'

export class UserLevelModel {
  @JsonNameReadonly()
  id!: number

  @JsonNameReadonly('male_title')
  maleTitle: string = ''

  @JsonNameReadonly('female_title')
  femaleTitle: string = ''

  @JsonNameReadonly()
  gradeText: string = ''

  @JsonNameReadonly()
  description: string = ''

  @JsonNameReadonly()
  grade!: number

  @JsonNameReadonly('required_meetings_quantity')
  requiredMeetingsQuantity!: number

  @JsonStruct(ImageModel, 'male_image')
  maleImage!: ImageModel

  @JsonStruct(ImageModel, 'female_image')
  femaleImage!: ImageModel

  static fromServer(data: object): UserLevelModel {
    return deserialize(data, UserLevelModel)
  }
}
