import { CenteredLoader } from 'components/common/centered-loader'
import { LayoutPage } from 'components/layout-page'
import { MeetingDetailsCard } from 'components/meeting-details-card'
import { observer } from 'mobx-react-lite'
import { MeetingDetailsContent } from 'pages/meeting-details/content'
import { JSX, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { meetingDetailsStore } from 'store/meeting-details.store.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { FlexContainer } from 'ui/flex-container'
import { navToMainPage } from 'utils/navigation.ts'
import * as styles from './meeting-details.style.ts'
import { MeetingDetailsFooter } from './meeting-details-footer.tsx'

type TMeetingDetailsParams = {
  meetingId: string
}

export const MeetingDetails = observer((): JSX.Element => {
  const { meetingId } = useParams<TMeetingDetailsParams>()
  const { meeting, isLoading } = meetingDetailsStore

  useEffect(() => {
    if (!meetingId) {
      return
    }

    meetingDetailsStore.getMeeting(meetingId)
  }, [meetingId])

  if (isLoading || !meeting) {
    return <CenteredLoader />
  }

  return (
    <LayoutPage
      headerText={meeting.isCreator ? 'Моя встреча' : 'Приглашение'}
      backAction={navToMainPage}
      footerNode={<MeetingDetailsFooter />}
    >
      <div className={styles.meetingDetailsPage}>
        <CellEmpty height="20" />

        <div className={styles.linesContainer}>
          <div className={styles.imagesContainer}>
            <FlexContainer justify="center" align="center">
              <img
                src={meeting.mood.image?.url}
                alt="Настроение"
                className={styles.moodImage}
              />
              <img
                src={meeting.category.image?.url}
                alt="Категория"
                className={styles.categoryImage}
              />
            </FlexContainer>
          </div>
        </div>

        <MeetingDetailsCard meeting={meeting} />
        <div className={styles.linesContainer} />

        <MeetingDetailsContent meeting={meeting} />
      </div>
    </LayoutPage>
  )
})
