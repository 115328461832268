import * as styles from './user-levels-slider.style'

import { Navigation, Autoplay, Pagination, Mousewheel } from 'swiper/modules'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import SwiperCore from 'swiper'
import { CenteredLoader } from 'components/common/centered-loader'
import { observer } from 'mobx-react-lite'
import { userStore } from 'store/user.store'
import { userLevelsStore } from 'store/user-levels.store'
import { SVGImg } from 'ui/svg-img'
import Divider from 'assets/user-level-divider.svg'
import { MeetingsCounter } from 'components/meetings-counter'
import { JSX, useEffect, useMemo, useRef, useState } from 'react'
import { SliderVector } from './slider-vector/slider-vector'
import { Slide } from './slide/slide'
import { UserGender } from 'enums/user.enum'
import { archiveMeetingsStore } from 'store/archive-meetings.store.ts'

SwiperCore.use([Pagination, Autoplay, Mousewheel, Navigation])

export const UserLevelsSlider = observer((): JSX.Element => {
  const { user, isLoading: isUserLoading } = userStore
  const { levels, isLoading: isLevelsLoading } = userLevelsStore
  const { isLoading: isMeetingsLoading, meetings } = archiveMeetingsStore
  const swiperRef = useRef<SwiperRef>(null)

  const [isAtBeginning, setIsAtBeginning] = useState(true)
  const [isAtEnd, setIsAtEnd] = useState(false)

  useEffect(() => {
    const swiper = swiperRef.current?.swiper
    if (swiper) {
      const updateNavigationState = () => {
        setIsAtBeginning(swiper.isBeginning)
        setIsAtEnd(swiper.isEnd)
      }

      updateNavigationState()

      swiper.on('slideChange', updateNavigationState)

      return () => {
        swiper.off('slideChange', updateNavigationState)
      }
    }
  }, [swiperRef.current])

  const navigateToPrevious = () => {
    if (!swiperRef.current) {
      return
    }
    swiperRef.current.swiper.slidePrev()
  }

  const navigateToNext = () => {
    if (!swiperRef.current) {
      return
    }
    swiperRef.current.swiper.slideNext()
  }

  const finishedMeetingsCount = useMemo(
    () => meetings.filter(({ isFinished }) => isFinished).length,
    [meetings]
  )

  const userLevelGrade = user?.level?.grade

  const getCurrentMeetingsCount = (requiredMeetingsQuantity: number) => {
    return Math.max(0, requiredMeetingsQuantity - finishedMeetingsCount)
  }

  const currentUserLevelIndex = useMemo(() => {
    const index = levels.findIndex((level) => level.grade === userLevelGrade)
    return index !== -1 ? index : 0
  }, [levels, userLevelGrade])

  const nextLevelRequiredMeetings = useMemo(() => {
    if (!userLevelGrade) {
      return 0
    }
    const nextLevel = levels.find((level) => level.grade === userLevelGrade + 1)
    return nextLevel
      ? getCurrentMeetingsCount(nextLevel.requiredMeetingsQuantity)
      : 0
  }, [levels, userLevelGrade, getCurrentMeetingsCount])

  const isHighestLevel = useMemo(() => {
    if (!userLevelGrade) {
      return
    }
    return levels.length && userLevelGrade >= levels[levels.length - 1].grade
  }, [levels, userLevelGrade])

  if (isUserLoading || isLevelsLoading || isMeetingsLoading) {
    return <CenteredLoader />
  }

  if (!userLevelGrade) {
    return <></>
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.header}>
        <span className={styles.titleHeader}>
          {isHighestLevel ? 'Самый крутой уровень' : 'До нового уровня'}
        </span>
        <SVGImg src={Divider} className={styles.divider} />
        {!isHighestLevel && (
          <MeetingsCounter count={nextLevelRequiredMeetings} />
        )}
      </div>

      <div className={styles.sliderContainer}>
        <SliderVector
          prev
          onClick={navigateToPrevious}
          disabled={isAtBeginning}
        />
        <Swiper
          ref={swiperRef}
          loop={false}
          initialSlide={currentUserLevelIndex}
          modules={[Navigation, Autoplay, Pagination, Mousewheel]}
          slidesPerView={1}
          spaceBetween={30}
          speed={400}
          mousewheel={false}
          scrollbar={{ draggable: true }}
          pagination={false}
        >
          {levels.map((level, index) => (
            <SwiperSlide key={index}>
              <Slide
                level={level}
                isMale={user?.gender === UserGender.MALE}
                isCurrent={userLevelGrade === level.grade}
                isDone={userLevelGrade > level.grade}
                isClosed={userLevelGrade < level.grade}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <SliderVector onClick={navigateToNext} disabled={isAtEnd} />
      </div>
    </div>
  )
})
