import { SanekUser } from 'api/models/sanek-user.model.ts'
import { router } from 'router'
import { SURVEY_STEP_TO_ROUTE_MAP } from 'router/router.const.ts'
import { SanekRoute } from 'router/router.enums.ts'

export function getSurveyRouteByUser(user: SanekUser | null): SanekRoute {
  if (user?.hasPassedSurvey) {
    return SanekRoute.MAIN
  }

  if (!user?.surveyStep) {
    return SanekRoute.SURVEY_QUESTIONS
  }

  return SURVEY_STEP_TO_ROUTE_MAP[user.surveyStep]
}

export function navigateBack(): void {
  void router.navigate(-1)
}

export function navToMainPage(): void {
  void router.navigate(SanekRoute.MAIN)
}

export function navToPlaces(): void {
  void router.navigate(SanekRoute.PLACES)
}

export function navToMapPage(): void {
  void router.navigate(SanekRoute.PLACES_MAP)
}

export function navToPlaceDetails(
  placeId: string | number,
  isOnlyView: boolean = false
): void {
  void router.navigate(`${SanekRoute.PLACE_DETAILS}/${placeId}`, {
    state: { isOnlyView },
  })
}
