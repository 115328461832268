import cn from 'classnames'
import style from './create-user-loading-screen.module.css'

import { FC, useEffect, useState } from 'react'

import { CellEmpty } from 'ui/cell-empty.tsx'
import { Loader } from '../loader/loader.tsx'

import DescSvg from 'assets/desc.svg'
import LeftRadiusTop from 'assets/left-radius-top.svg'
import RightRadiusTop from 'assets/right-radius-top.svg'
import RightRadiusBottom from 'assets/right-radius-bottom.svg'
import LeftRadiusBottom from 'assets/left-radius-bottom.svg'

import { ProgressiveImage } from 'components/progressive-image/progressive-image.tsx'

import { SanekUser } from 'api/models/sanek-user.model.ts'

const SHOW_AVATAR_DELAY = 2000

type Props = {
  user: SanekUser
}
export const CreateUserLoadingScreen: FC<Props> = ({ user }) => {
  const [isShowAvatar, setIsShowAvatar] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (user?.avatar) {
        setIsShowAvatar(true)
      }
    }, SHOW_AVATAR_DELAY)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    const themeColorMetaEl = document.querySelector('meta[name="theme-color"]')

    if (!themeColorMetaEl) {
      return
    }

    themeColorMetaEl.setAttribute('content', '#ffde00')

    return () => themeColorMetaEl.setAttribute('content', '#ffffff')
  }, [])

  return (
    <div className={style.loadingScreen}>
      <div className={style.headerBlock}>
        <div className={style.leftRadius}>
          <img src={LeftRadiusTop} alt="left-top" />
          <img src={LeftRadiusBottom} alt="left-bottom" />
        </div>
        <h2 className={style.header}>САНЁК ЗОВЁТ</h2>
        <div className={style.rightRadius}>
          <img src={RightRadiusTop} alt="right-top" />
          <img src={RightRadiusBottom} alt="right-bottom" />
        </div>
      </div>
      <CellEmpty height="30" />

      <Loader />
      <CellEmpty height="30" />

      <div className={style.footer}>
        <div className={style.textSlide}>
          <div>
            <div className={style.text}>
              <span>Анализируем результаты</span>
              <span>Будим Санька</span>
              <span>Подбираем аватар</span>
              <span>Готово!</span>
            </div>
          </div>
        </div>
        <img src={DescSvg} />
      </div>
      <div
        className={cn(style.avatar, {
          [style.visible]: isShowAvatar,
        })}
      >
        {user && user?.avatar && isShowAvatar && (
          <ProgressiveImage
            image={user.avatar.image}
            alt="Avatar"
            width="250px"
            height="250px"
          />
        )}
      </div>
    </div>
  )
}
