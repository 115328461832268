import { css } from '@emotion/css'

export const placeFiltersPage = css`
  padding: 0.5rem 2rem 2rem;
`

export const fieldLabel = css`
  font-family:
    Chalkboard SE,
    serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
`

export const placeFiltersFooter = css`
  padding: 0 2rem 2rem;
`

export const priceSegments = css`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 10px;
`

export const openMetroModalBtn = css`
  background-color: #fff;
  border: 2px solid #f2f2f2;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
  background: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Copperplate', sans-serif;
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  color: #000;
`
