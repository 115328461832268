import { css } from '@emotion/css'

export const phoneCoderInput = css`
  font-family: Copperplate, serif;
  width: 160px;
  font-size: 20px;
  padding: 8px 16px;
  background: #f6f6f6;
  border-radius: 12px;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
  border: 1px solid #f2f2f2;
  outline: none;
  box-sizing: border-box;
  text-align: center;

  &:focus {
    outline: none;
  }
`
