import { css } from '@emotion/css'

export const mainContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 30px;
  width: 100%;
`

export const title = css`
  display: flex;
  position: absolute;
  top: 400px;
  z-index: 10;
  background-color: white;
  height: auto;
  width: 80%;

  font-family: 'Chalkboard SE', sans-serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
  text-align: center;
  color: #000;
  white-space: normal;
  word-wrap: break-word;
`

export const buttonContainer = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 22px;
  margin-top: 90px;

  @media (max-width: 400px) {
    margin-top: 120px;
  }
`

export const vortex = css`
  @media (max-width: 400px) {
    margin-bottom: 90px;
  }
`

export const baseBtn = css`
  border-radius: 30px;
  width: 100%;
  height: 66px;
  box-shadow: 2px 3px 0 0 rgba(0, 0, 0, 0.25);
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Copperplate', sans-serif;
  font-weight: 700;
  line-height: 90%;
  letter-spacing: -0.01em;
`

export const refuseBtn = css`
  ${baseBtn};
  border: 3px solid #ff6242;
  font-size: 24px;
`

export const acceptBtn = css`
  ${baseBtn};
  border: 3px solid #68b984;
  font-size: 32px;
`
