import { cx } from '@emotion/css'
import { ForwardedRef, forwardRef, InputHTMLAttributes, JSX } from 'react'
import * as styles from './centered-input.style.ts'
import DropdownIcon from 'assets/dropdown-icon.svg'

type TCenteredInputProps = InputHTMLAttributes<HTMLInputElement> & {
  isDropdown?: boolean
  isBig?: boolean
}

export const CenteredInput = forwardRef(
  (
    { isBig, isDropdown, ...props }: TCenteredInputProps,
    ref: ForwardedRef<HTMLInputElement>
  ): JSX.Element => {
    return (
      <div className={styles.centeredInputWrapper}>
        <input
          ref={ref}
          className={cx(styles.centeredInput, {
            [styles.centeredInputBig]: isBig,
          })}
          {...props}
        />
        {isDropdown && (
          <img src={DropdownIcon} className={styles.dropdownIcon} />
        )}
      </div>
    )
  }
)
