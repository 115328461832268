import { CSSProperties, JSX, PropsWithChildren } from 'react'

type TFlexContainerProps = PropsWithChildren<{
  direction?: CSSProperties['flexDirection']
  wrap?: CSSProperties['flexWrap']
  justify?: CSSProperties['justifyContent']
  align?: CSSProperties['justifyContent']
  className?: string
  flex?: number
  gap?: CSSProperties['gap']
  style?: Partial<CSSProperties>
}>

export function FlexContainer({
  children,
  direction = 'row',
  wrap = 'wrap',
  justify = 'start',
  align = 'start',
  gap = '0px',
  flex,
  className,
  style,
}: TFlexContainerProps): JSX.Element {
  return (
    <div
      style={{
        ...style,
        flex,
        display: 'flex',
        flexDirection: direction,
        flexWrap: wrap,
        justifyContent: justify,
        alignItems: align,
        gap: gap,
      }}
      className={className}
    >
      {children}
    </div>
  )
}
