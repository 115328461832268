import { SanekUser } from 'api/models/sanek-user.model.ts'
import {
  TSurveyQuestionAnswer,
  TSurveyQuestionModel,
  TSurveyQuestionResponse,
} from 'api/models/survey-question.model.ts'
import { AxiosResponse } from 'axios'
import { transport } from './transport.ts'

export function getSurveyQuestions(): Promise<Array<TSurveyQuestionModel>> {
  return transport
    .get(
      `${
        import.meta.env.VITE_STRAPI_API
      }/api/survey-questions?pagination[limit]=50`
    )
    .then(({ data }: AxiosResponse<TSurveyQuestionResponse>) => {
      return data.data.map(({ attributes, id }) => ({ id, ...attributes }))
    })
}

export function saveSurveyQuestions(
  user: SanekUser,
  result: Array<TSurveyQuestionAnswer>
): Promise<void> {
  const questionsSurveyData = {
    data: {
      result: result,
      user: {
        connect: [user.id],
      },
    },
  }

  return transport
    .post(
      `${import.meta.env.VITE_STRAPI_API}/api/question-surveys`,
      questionsSurveyData
    )
    .then((response) => response.data)
}
