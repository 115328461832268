import { ImageModel } from 'api/models/image.model.ts'
import { SanekUser } from 'api/models/sanek-user.model.ts'
import { MeetingCounterpart } from 'api/user-meetings/models/meeting-counterpart.ts'
import { NotificationStatus } from 'enums/notification.enums.ts'
import { userStore } from 'store/user.store.ts'
import {
  deserialize,
  JsonArray,
  JsonName,
  JsonNameReadonly,
  JsonStruct,
} from 'tserialize'
import { PlaceModel } from 'api/places/models/place.model.ts'
import { TimeslotModel } from 'api/models/timeslot.model.ts'
import { MeetingStatus } from 'const/statuses'
import { InvitationShortModel } from 'api/user-invitations/models/invitation-short.model.ts'

class ShortModel {
  @JsonName()
  code: string = ''

  @JsonStruct(ImageModel)
  image?: ImageModel
}

export class UserMeetingModel {
  @JsonNameReadonly()
  id!: number

  @JsonStruct(SanekUser, 'sanek_user')
  sanekUser!: Pick<SanekUser, 'id'>

  @JsonNameReadonly('invitation_id')
  invitationId: number | null = null

  @JsonNameReadonly()
  status!: MeetingStatus

  @JsonNameReadonly('notification_status')
  notificationStatus: NotificationStatus | null = null

  @JsonNameReadonly('is_notifications_available')
  isNotificationsAvailable: boolean = false

  @JsonStruct(PlaceModel, 'meeting_place')
  place: PlaceModel = new PlaceModel()

  @JsonArray(TimeslotModel, 'meeting_timeslots')
  timeslots: Array<TimeslotModel> = []

  @JsonNameReadonly('meeting_rate')
  rate: number | null = null

  @JsonStruct(TimeslotModel, 'confirmed_timeslot')
  timeslot: TimeslotModel | null = null

  @JsonNameReadonly('confirmed_users')
  confirmedUsers: Array<Pick<SanekUser, 'id'>> = []

  @JsonStruct(SanekUser, 'rejected_user')
  rejectedUser: Pick<SanekUser, 'id'> | null = null

  @JsonStruct(ShortModel, 'user_meeting_mood')
  mood: ShortModel = new ShortModel()

  @JsonStruct(ShortModel, 'user_meeting_category')
  category: ShortModel = new ShortModel()

  @JsonStruct(MeetingCounterpart)
  counterpart: MeetingCounterpart | null = null

  @JsonNameReadonly('has_new_messages')
  hasNewMessages: boolean = false

  @JsonArray(InvitationShortModel)
  invitations: Array<InvitationShortModel> = []

  get isConfirmed(): boolean {
    const { user } = userStore
    if (!user) {
      return false
    }
    return this.confirmedUsers.some(
      (confirmedUser) => confirmedUser.id === user.id
    )
  }

  get isCreator(): boolean {
    const { user } = userStore
    if (!user) {
      return false
    }

    return this.sanekUser.id === user.id
  }

  get isFinished(): boolean {
    return ['rate_meeting', 'archive', 'cancelled'].includes(this.status)
  }

  get confirmedTimeslot(): TimeslotModel | null {
    if (!this.timeslot) {
      const timeslot = this.invitations.find(
        (invitation: InvitationShortModel) => invitation.confirmedTimeslot
      )?.confirmedTimeslot

      return timeslot ? TimeslotModel.fromServer(timeslot) : null
    }

    return this.timeslot
  }

  static fromServer(data: object): UserMeetingModel {
    return deserialize(data, UserMeetingModel)
  }
}
