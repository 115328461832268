import { Image, Input, TextArea } from 'antd-mobile'
import CircleCross from 'assets/circle-cross.svg'
import { LayoutPage } from 'components/layout-page'
import { observer } from 'mobx-react-lite'
import { JSX, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { chatMessageCreationStore } from 'store/chat-message-creation.store.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { FlexContainer } from 'ui/flex-container'
import { ResetButton } from 'ui/reset-button'
import { SanekButton } from 'ui/sanek-button'
import { Typography } from 'ui/typography'
import { navigateBack } from 'utils/navigation.ts'
import * as styles from './create-message.style.ts'
import { openDialog } from 'utils/dialog.ts'
import { Camera } from 'components/camera/camera.tsx'
import { AddPointMapModal } from 'components/add-point-map-modal/add-point-map-modal.tsx'
import TipQuestion from 'assets/tip-question.svg'
import { SeparatorBlue } from 'components/common/separator'

export const CreateMessagePage = observer((): JSX.Element => {
  const { meetingId, chatId } = useParams()
  const { state } = useLocation()
  const { text, link, file, isLoading } = chatMessageCreationStore
  const [preview, setPreview] = useState('')

  const isFormValid = !!text && !!file

  useEffect(() => {
    const previewUrl = file ? URL.createObjectURL(file) : ''
    setPreview(previewUrl)
    return () => URL.revokeObjectURL(previewUrl)
  }, [file])

  useEffect(() => {
    if (state?.message) {
      void chatMessageCreationStore.init(state.message)
    }

    return () => chatMessageCreationStore.reset()
  }, [state?.message])

  const handleShareLocation = (): void => {
    if (chatId) {
      void chatMessageCreationStore.editChatMessage(
        Number(meetingId),
        Number(chatId)
      )
      return
    }

    void chatMessageCreationStore.createChatMessage(Number(meetingId))
  }

  const handleResetForm = (): void => {
    chatMessageCreationStore.reset()
  }

  const handleAddPhoto = (): void => {
    openDialog({
      content: <Camera onAddPhoto={chatMessageCreationStore.setFile} />,
    })
  }

  const handleFileDelete = (): void => {
    chatMessageCreationStore.setFile(null)
  }

  const handleAddPointMapDialog = (): void => {
    openDialog({
      content: <AddPointMapModal />,
    })
  }

  return (
    <LayoutPage
      headerText={!chatId ? 'Добавить локацию' : 'Обновить локацию'}
      footerNode={
        <div className={styles.createMessagesFooter}>
          <SanekButton
            isLoading={isLoading}
            disabled={!isFormValid}
            onClick={handleShareLocation}
          >
            ПОДЕЛИТЬСЯ
          </SanekButton>
        </div>
      }
      backAction={navigateBack}
    >
      <div className={styles.createMessagesPage}>
        <ResetButton onClick={handleResetForm} />

        <FlexContainer align="center" gap="10px">
          <Typography family="Chalkboard" size="20" className={styles.required}>
            Где ты находишься?
          </Typography>
          <img src={TipQuestion} onClick={handleAddPointMapDialog} />
        </FlexContainer>
        <CellEmpty height="7" />

        <TextArea
          name="text"
          value={text}
          placeholder="Коротко опиши как тебя найти"
          rows={3}
          maxLength={200}
          showCount={renderCounter}
          className={styles.formControl}
          onChange={chatMessageCreationStore.setText}
        />
        <CellEmpty height="15" />

        <SeparatorBlue />
        <CellEmpty height="15" />

        <Typography family="Chalkboard" size="20">
          Ссылка на карте
        </Typography>
        <CellEmpty height="7" />

        <Input
          name="link"
          value={link}
          placeholder="Приложи точку на карте"
          // @ts-ignore
          style={{ '--background-color': '#f6f6f6' }}
          className={styles.formControl}
          onChange={chatMessageCreationStore.setLink}
        />
        <CellEmpty height="15" />

        <SeparatorBlue />
        <CellEmpty height="15" />

        <FlexContainer wrap="nowrap" gap="15px">
          <Typography family="Chalkboard" size="20" className={styles.required}>
            Фото
          </Typography>
          <Typography size="12" weight="300">
            Сфотографируй себя или где находишься, чтобы другу было легче тебя
            найти
          </Typography>
        </FlexContainer>
        <CellEmpty height="15" />

        <FlexContainer direction="column" justify="center" align="center">
          <label className={styles.addPhotoButton}>
            Добавить фото
            <button style={{ display: 'none' }} onClick={handleAddPhoto} />
          </label>
          <CellEmpty height="20" />

          {preview && (
            <div className={styles.photoPreviewContainer}>
              <Image src={preview} />
              <button
                className={styles.deleteImageButton}
                onClick={handleFileDelete}
              >
                <img src={CircleCross} alt="Удалить" />
              </button>
            </div>
          )}
        </FlexContainer>
      </div>
    </LayoutPage>
  )
})

function renderCounter(length: number, maxLength?: number): JSX.Element {
  return (
    <>
      <CellEmpty height="5" />
      <Typography size="12" align="right">
        {length}
        {maxLength && `/${maxLength}`}
      </Typography>
    </>
  )
}
