import Man from 'assets/man.png'
import { MessageAvatar } from 'components/message-avatar'
import { observer } from 'mobx-react-lite'
import { JSX } from 'react'
import { meetingDetailsStore } from 'store/meeting-details.store.ts'
import { userStore } from 'store/user.store.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { FlexContainer } from 'ui/flex-container'
import { Typography } from 'ui/typography'

export const CancelledContent = observer((): JSX.Element => {
  const { user } = userStore
  const { meeting } = meetingDetailsStore

  if (!meeting) {
    return <></>
  }

  const isCommonView =
    !meeting.rejectedUser || meeting.rejectedUser.id === user?.id

  return (
    <>
      <Typography family="Chalkboard" size="24" align="center">
        {isCommonView ? 'Очень жаль' : 'Друг не сможет прийти'}
      </Typography>
      <CellEmpty height="25" />

      <FlexContainer wrap="nowrap" gap="15px">
        <MessageAvatar avatarImg={Man} isOwnMessage={false} />
        <div style={{ flex: 1 }}>
          <CellEmpty height="10" />
          <Typography family="Chalkboard" size="14" lineHeight="1.07">
            {isCommonView ? (
              <>
                А я уже напрягся и всех позвал! Почему не сможешь?
                <br />
                <br />
                У всех бывает непредвиденное. Понимаю.
                <br />
                <br />В следующий раз находи время, которое у тебя точно
                свободно.
              </>
            ) : (
              <>
                У друга возникли непредвиденные дела. Он, к своему сожалению, не
                сможет прийти.
                <br />
                <br />
                Остальные мои друзья очень хотят с тобой поболтать!
                <br />
                <br />
                Скорее повторяй эту встречу, а если передумал, создавай новую!
              </>
            )}
          </Typography>
        </div>
      </FlexContainer>
      <CellEmpty height="25" />
    </>
  )
})
