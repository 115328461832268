import { userMeetingsTransport } from 'api/user-meetings/user-meetings.transport.ts'
import Clocks from 'assets/decorations/meeting-details/clocks.png'
import { observer } from 'mobx-react-lite'
import { JSX, useState } from 'react'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums.ts'
import { meetingDetailsStore } from 'store/meeting-details.store.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { SanekButton } from 'ui/sanek-button'
import { Typography } from 'ui/typography'
import { MeetingTimeslots } from './meeting-timeslots.tsx'
import * as styles from './owner-search-content.style.ts'
import { SeparatorGrayThin } from 'components/common/separator/separator-gray-thin.tsx'

export const OwnerSearchContent = observer((): JSX.Element => {
  const { meeting } = meetingDetailsStore
  const [isCancelling, setIsCancelling] = useState(false)

  const handleDeleteMeeting = (): void => {
    if (!meeting) {
      return
    }

    setIsCancelling(true)
    userMeetingsTransport
      .cancelMeeting(meeting.id)
      .then(() => {
        // TODO возможно, какой-то индикатор успеха, если клиент потом выделит время и прорисует
        void router.navigate(SanekRoute.MAIN, { replace: true })
      })
      .catch(() => {
        // TODO обработка ошибки
      })
      .finally(() => setIsCancelling(false))
  }

  return (
    <>
      <p className={styles.sendingInvitationsTitle}>
        Санёк отправляет
        <br />
        приглашения своим друзьям
      </p>
      <CellEmpty height="10" />

      <img src={Clocks} alt="Часы" style={{ width: '100%' }} />

      <p className={styles.sendingInvitationsDescription}>
        Конкретное время для встречи из твоих вариантов выберет приглашённый
      </p>
      <CellEmpty height="20" />

      <SeparatorGrayThin />
      <CellEmpty height="20" />

      <MeetingTimeslots />
      <CellEmpty height="44" />

      <SanekButton
        type="clear"
        size="s"
        isLoading={isCancelling}
        onClick={handleDeleteMeeting}
      >
        <Typography weight="700" align="center">
          ОТМЕНИТЬ ВСТРЕЧУ
        </Typography>
      </SanekButton>
    </>
  )
})
