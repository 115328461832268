import { css } from '@emotion/css'
import { flexCenterContent } from 'styles/common.style.ts'
import { buttonReset } from 'styles/reset.style.ts'

export const circleButton = css`
  ${buttonReset};
  ${flexCenterContent};
  width: 66px;
  height: 66px;
  background-color: #ffffff;
  border: 2px solid #f2f2f2;
  border-radius: 50%;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.25);
`
