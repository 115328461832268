import { JSX } from 'react'
import { LayoutPage } from 'components/layout-page'
import { SanekEmptyView } from 'components/sanek-empty-view'
import { navToMainPage } from 'utils/navigation.ts'

export const NotFoundPage = (): JSX.Element => {
  return (
    <LayoutPage backAction={navToMainPage}>
      <SanekEmptyView title="Страница не найдена" description="" />
    </LayoutPage>
  )
}
