import { MeetingSubcategoryModel } from 'api/meeting/models/meeting-subcategory.model.ts'
import { ImageModel } from 'api/models/image.model.ts'
import {
  deserialize,
  JsonArray,
  JsonNameReadonly,
  JsonStruct,
} from 'tserialize'

export class MeetingCategoryModel {
  @JsonNameReadonly()
  id!: number

  @JsonNameReadonly()
  text: string = ''

  @JsonNameReadonly('text_min')
  textMin: string = ''

  @JsonNameReadonly()
  code: string = ''

  @JsonStruct(ImageModel)
  image!: ImageModel

  @JsonStruct(ImageModel, 'image_min')
  imageMin!: ImageModel

  @JsonArray(MeetingSubcategoryModel)
  subcategories: Array<MeetingSubcategoryModel> = []

  static fromServer(data: object): MeetingCategoryModel {
    return deserialize(data, MeetingCategoryModel)
  }
}
