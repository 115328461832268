import { makeAutoObservable } from 'mobx'
import { UserLevelModel } from 'api/models/user-level.model'
import { userLevelTransport } from 'api/user-levels.transport'

class UserLevelsStore {
  levels: Array<UserLevelModel> = []
  isLoading: boolean = false
  hasError: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setLevels = (levels: Array<UserLevelModel>): void => {
    this.levels = levels
  }

  setIsLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading
  }

  setHasError = (hasError: boolean): void => {
    this.hasError = hasError
  }

  getLevels = (): void => {
    this.setIsLoading(true)
    this.setHasError(false)

    void userLevelTransport
      .getLevels()
      .then((data: Array<UserLevelModel>) => {
        this.setLevels(data)
      })
      .catch(() => {
        this.setHasError(true)
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }
}

export const userLevelsStore = new UserLevelsStore()
