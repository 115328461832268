import { css } from '@emotion/css'

export const changeAvatarWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  & button {
    border-radius: 16px;
    border: 3px solid #f2f2f2;
    background: #78c5e2;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.1);
    padding: 10px 15px;

    color: #000;
    text-align: center;
    font-family: Copperplate, serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.5px;
    letter-spacing: -0.408px;
  }
`

export const changeAvatarImageWrapper = css`
  width: 160px;
  height: 160px;
`
