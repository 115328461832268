import Edit from 'assets/edit.png'
import { Fragment, JSX } from 'react'
import { TPickerTimeslot } from 'types/time-picker.types.ts'
import { FlexContainer } from 'ui/flex-container'
import * as styles from './selected-timeslots.style.ts'
import { observer } from 'mobx-react-lite'
import { TimeslotItem } from './timeslot-item'

type TSelectedTimeslotsProps = {
  timeslots: Array<TPickerTimeslot>
  onRemoveItem?: (item: TPickerTimeslot) => void
  onEditClick?: VoidFunction
}

export const SelectedTimeslots = observer(({
  timeslots,
  onRemoveItem,
  onEditClick
}: TSelectedTimeslotsProps): JSX.Element => {
  if (timeslots.length === 0) {
    return <Fragment />
  }

  return (
    <div className={styles.timeslotsWrapper}>
      <FlexContainer justify="space-between">
        <p className={styles.timeslotsTitle}>Выбранное время</p>
        {onEditClick && (
          <button className={styles.editButton} onClick={onEditClick}>
            <img src={Edit} alt="Ред." width="23" height="23" />
          </button>
        )}
      </FlexContainer>
      <div>
        {timeslots.map((selectedTime: TPickerTimeslot) => (
          <TimeslotItem
            key={selectedTime.id}
            item={selectedTime}
            onRemove={onRemoveItem}
          />
        ))}
      </div>
    </div>
  )
})
