import React, { JSX, useEffect, useState } from 'react'
import { MetroStationModel } from '../../api/models/metro-station.model.ts'
import { observer } from 'mobx-react-lite'
import { metroStationsStore } from '../../store/metro-stations.store.ts'
import * as styles from './metro-station-dialog.style.ts'
import { CenteredLoader } from 'components/common/centered-loader'
import { SVGImg } from 'ui/svg-img'
import Eraser from 'assets/eraser.svg'
import Loupe from 'assets/loupe.svg'

type TMetroStationMultiSelectProps = {
  cityId?: number
  value: MetroStationModel[]
  onChange: (metroStations: MetroStationModel[]) => void
}

export const MetroStationMultiSelect = observer(
  ({ cityId, value, onChange }: TMetroStationMultiSelectProps): JSX.Element => {
    const { metroStations, isLoading } = metroStationsStore
    const [searchQuery, setSearchQuery] = useState('')

    useEffect(() => {
      metroStationsStore.getMetroStations(cityId)
    }, [cityId])

    if (isLoading) {
      return <CenteredLoader />
    }

    const handleCheckboxChange = (
      station: MetroStationModel,
      isChecked: boolean
    ) => {
      if (isChecked) {
        const updatedValue = value ? [...value, station] : [station]
        onChange(updatedValue)
      } else {
        const updatedValue = value?.filter((s) => s.id !== station.id) || []
        onChange(updatedValue)
      }
    }

    const handleResetFilters = () => {
      onChange([])
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value)
    }

    const filteredMetroStations = metroStations.filter((station) =>
      station.name.toLowerCase().includes(searchQuery.toLowerCase())
    )

    return (
      <div className={styles.mainContainer}>
        <div className={styles.headerBlock}>
          <p className={styles.title}>Список станций метро</p>
          <div className={styles.eraserBlock} onClick={handleResetFilters}>
            <SVGImg src={Eraser} />
            <h3>Сбросить</h3>
          </div>
        </div>

        <div className={styles.inputContainer}>
          <input
            className={styles.input}
            autoComplete="off"
            name="searchField"
            onChange={handleInputChange}
            value={searchQuery}
          />
          <SVGImg src={Loupe} />
        </div>

        <div className={styles.stationsListContainer}>
          {filteredMetroStations.map((station) => (
            <div key={station.id} className={styles.stationContainer}>
              <input
                className={styles.checkbox}
                type="checkbox"
                id={`station-${station.id}`}
                checked={value.some((s) => s.id === station.id)}
                onChange={(e) =>
                  handleCheckboxChange(station, e.target.checked)
                }
              />
              <label className={styles.label} htmlFor={`station-${station.id}`}>
                <span
                  className={styles.metroIcon}
                  style={{ color: station.color }}
                >
                  М
                </span>
                {station.name}
              </label>
            </div>
          ))}
        </div>
      </div>
    )
  }
)
