import * as styles from './phone-code-input.style.ts'

import { ChangeEvent, FC } from 'react'

type Props = {
  value?: string
  onChange?: (phone: string) => void
}
export const PhoneCodeInput: FC<Props> = ({ value, onChange }) => {
  // const inputRef = useMask({
  //   mask: '____',
  //   replacement: { _: /\d/ },
  // })

  const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value)
  }

  return (
    <div>
      <input
        type="tel"
        maxLength={4}
        // ref={inputRef}
        autoComplete="one-time-code"
        placeholder="xxxx"
        value={value}
        onChange={handleCodeChange}
        className={styles.phoneCoderInput}
      />
    </div>
  )
}
