import { transport } from 'api/transport.ts'
import { NotificationModel } from './model/notificationModel.ts'

class NotificationsTransport {
  fetchNotifications(): Promise<NotificationModel[]> {
    return transport
      .get<
        NotificationModel[]
      >(`${import.meta.env.VITE_STRAPI_API}/api/notification/my-notifications`)
      .then(({ data }: { data: NotificationModel[] }) =>
        data.map((notification) => NotificationModel.fromServer(notification))
      )
  }
  markNotificationAsRead(notificationId: number): Promise<void> {
    return transport
      .post<void>(
        `${import.meta.env.VITE_STRAPI_API}/api/notification/${notificationId}/mark-as-seen`
      )
      .then((data) => data.data)
  }
}

export const notificationsTransport = new NotificationsTransport()
