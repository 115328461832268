import { TariffModel } from 'api/tariff/models/tariff.model.ts'
import { deserialize, JsonArray } from 'tserialize'

export class GetTariffsResponse {
  @JsonArray(TariffModel)
  tariffs: Array<TariffModel> = []

  static fromServer(data: object): GetTariffsResponse {
    return deserialize(data, GetTariffsResponse)
  }
}
