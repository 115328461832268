import { css } from '@emotion/css'
import { textCommon } from 'styles/common.style.ts'

export const timeSelectWrapper = css`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 20px;
  justify-content: space-between;
  height: 100px;
`

export const addButton = css`
  margin-top: 12px;
  padding: 12px;
  background-color: #68b984;
  color: #fff;
  width: 100%;
  height: 100%;

  font-family: 'Copperplate', sans-serif;

  border: 2px solid #fff;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
`

export const pickerValue = css`
  flex: 1;
  border-radius: 8px;
  background: #fff7c4;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
  border: none;
  padding: 8px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 66px;

  ${textCommon}
`

export const timePickerWrapper = css`
  flex: 1;
  display: flex;
  gap: 4px;
  align-items: stretch;
`

export const timePicker = css`
  .adm-picker-view-column-item-label,
  .adm-picker-header-button {
    color: #000;
    font-family: 'Chalkboard SE', serif;
    font-size: 20px;
    font-weight: 700;
  }
`

export const pickerCol = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
`

export const label = css`
  font-family: Copperplate, serif;
  font-weight: 400;
  font-size: 16px;
  color: #000;

  margin-bottom: 4px;
`

export const pickerDisabled = css`
  pointer-events: none;
  opacity: 0.5;
`

export const line = css`
  display: flex;
  align-items: center;
  justify-content: center;
`
