type TOne = string
type TFew = string
type TMany = string
export type TPluralizeConfig = [TOne, TFew, TMany]

export function pluralize(forms: TPluralizeConfig, count: number) {
  let idx
  // @see http://docs.translatehouse.org/projects/localization-guide/en/latest/l10n/pluralforms.html
  if (count % 10 === 1 && count % 100 !== 11) {
    idx = 0 // one
  } else if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) {
    idx = 1 // few
  } else {
    idx = 2 // many
  }

  return forms[idx] || '';
}
