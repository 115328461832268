export enum FilterType {
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  DAY_AFTER_TOMORROW = 'dayAfterTomorrow',
  NEXT_WEEK = 'nextWeek',
  IS_RECIPIENT = 'isRecipient',
  IS_OWNER = 'isOwner',
}

export const FILTER_NAMES_MAP: Record<FilterType, string> = {
  [FilterType.TODAY]: 'Сегодня',
  [FilterType.TOMORROW]: 'Завтра',
  [FilterType.DAY_AFTER_TOMORROW]: 'Послезавтра',
  [FilterType.NEXT_WEEK]: 'В ближайшие 7 дней',
  [FilterType.IS_RECIPIENT]: 'Меня пригласили',
  [FilterType.IS_OWNER]: 'Я пригласил',
}

export const TIME_FILTERS: Array<FilterType> = [
  FilterType.TODAY,
  FilterType.TOMORROW,
  FilterType.DAY_AFTER_TOMORROW,
  FilterType.NEXT_WEEK,
]

export const TYPE_FILTERS: Array<FilterType> = [
  FilterType.IS_RECIPIENT,
  FilterType.IS_OWNER,
]
