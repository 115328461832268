import { ChatMessageModel } from 'api/meeting-chat/models/chat-message.model.ts'
import { JsonName, JsonStruct, serialize } from 'tserialize'

export class CreateMessageRequest {
  @JsonName('id')
  meetingId!: number

  @JsonStruct(ChatMessageModel)
  message!: ChatMessageModel

  constructor(data: Omit<CreateMessageRequest, 'toServer'>) {
    Object.assign(this, data)
  }

  toServer(): object {
    return serialize(this)
  }
}
