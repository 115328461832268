import { UserMeetingModel } from 'api/user-meetings/models/user-meeting.model.ts'
import { OwnerContent } from 'pages/meeting-details/content/owner-content.tsx'
import { RecipientContent } from 'pages/meeting-details/content/recipient-content.tsx'
import { JSX } from 'react'

type TMeetingDetailsContentProps = {
  meeting: UserMeetingModel
}

export function MeetingDetailsContent({ meeting }: TMeetingDetailsContentProps): JSX.Element {
  return meeting.isCreator
    ? <OwnerContent status={meeting.status} />
    : <RecipientContent status={meeting.status} />
}
