import { MeetingCategoryModel } from 'api/meeting/models/meeting-category.model.ts'
import { MeetingSubcategoryModel } from 'api/meeting/models/meeting-subcategory.model.ts'
import { PlacesSubcategories } from 'components/places-subcategories'
import { JSX, useMemo } from 'react'
import { meetingCategoriesStore } from 'store/meeting-categories.store.ts'
import { placesStore } from 'store/places.store.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'

type TPlacesCategoriesPanelProps = {
  selectedCategory?: MeetingCategoryModel | null
  onlyFavourites?: boolean
}

type TIterableCategory = MeetingCategoryModel | MeetingSubcategoryModel

export function PlacesCategoriesPanel({
  selectedCategory,
  onlyFavourites,
}: TPlacesCategoriesPanelProps): JSX.Element {
  const { categories } = meetingCategoriesStore

  const iterable: Array<TIterableCategory> = onlyFavourites
    ? categories
    : selectedCategory?.subcategories || []
  const options = useMemo(() => {
    return (
      iterable.map((element: TIterableCategory) => ({
        value: element.id,
        label: element.text,
      })) || []
    )
  }, [iterable])

  const handlePlacesSubcategoryChange = (
    meetingSubcategoryId: number
  ): void => {
    const {
      filters: { meetingSubcategoryId: currentMeetingSubcategoryId },
    } = placesStore

    const isSame = currentMeetingSubcategoryId === meetingSubcategoryId
    placesStore.updateFilters({
      meetingSubcategoryId: isSame ? undefined : meetingSubcategoryId,
    })
    placesStore.getPlaces()
  }

  const handlePlacesCategoryChange = (meetingCategoryId: number): void => {
    placesStore.updateFilters({ meetingCategoryId })
    placesStore.getPlaces()
  }

  const categoriesProps = onlyFavourites
    ? {
        selected: placesStore.filters.meetingCategoryId,
        options,
        onChange: handlePlacesCategoryChange,
      }
    : {
        selected: placesStore.filters.meetingSubcategoryId,
        options,
        onChange: handlePlacesSubcategoryChange,
      }

  return (
    <>
      {options.length > 0 && (
        <>
          <PlacesSubcategories {...categoriesProps} />
          <CellEmpty height={17} />
        </>
      )}
    </>
  )
}
