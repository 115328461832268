import { css } from '@emotion/css'

const textCommon = css`
  color: #000;
  text-align: center;
  line-height: 55px;
  letter-spacing: -0.3px;
  font-style: normal;
`

export const wrapper = css`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const headerBlock = css`
  display: flex;
  justify-content: space-between;
  gap: 50px;

  h1 {
    ${textCommon};
    font-size: 96px;
    font-weight: 700;
  }
`
export const rotatedVector = css`
  transform: scaleX(-1);
`

export const imgWrapper = css`
  position: relative;
`

export const vectorUnder = css`
  position: absolute;
  top: 450px;
  left: -310px;
  z-index: 2;
`
