import { css } from '@emotion/css'

export const filtersContainer = css`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 15px;
  width: 100%;

  h3 {
    font-family: 'Chalkboard SE', sans-serif;
    font-weight: 400;
    font-size: 22px;
    line-height: 136%;
    letter-spacing: -0.02em;
    color: #000;
  }
`

export const filter = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #f2f2f2;
  border-radius: 25px;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.25);
  background: #f6f6f6;
  width: 100%;
  height: 32px;
  font-family: 'Copperplate', sans-serif;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #000;

  &:focus,
  &:active {
    outline: none;
    border-color: #f2f2f2;
  }
`

export const active = css`
  background: #ffde00 !important;
`

export const delimiter = css`
  margin: 20px 0 10px;
`

export const eraserBlock = css`
  h3 {
    font-family: 'Copperplate', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 214%;
    letter-spacing: -0.03em;
    color: #000;
  }

  display: flex;
  align-items: center;
  gap: 3px;
  position: absolute;
  right: 20px;
  padding: 5px;
`
