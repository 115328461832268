import { css } from '@emotion/css'

export const mapWrapper = css`
  position: relative;
`

export const map = css`
  width: 100%;
  height: calc(100vh - 60px);
`

export const mapButton = css`
  z-index: 1000;
  border-radius: 14px;
  cursor: pointer;
  position: absolute;
  right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background: #9fcbdc;
`

export const plusButton = css`
  ${mapButton};
  top: 64px;
`

export const minusButton = css`
  ${mapButton};
  top: 144px;
`

export const zoomButton = css`
  ${mapButton};
  position: absolute;
  bottom: 56px;

  border-radius: 100%;

  img {
    margin-top: 8px;
    margin-right: 8px;
  }
`
