import { AxiosResponse } from 'axios'
import { transport } from './transport.ts'

export class StoriesTransport {
  getStories() {
    return transport
      .get(`${import.meta.env.VITE_STRAPI_API}/api/stories?populate=*`)
      .then(({ data }: AxiosResponse) => data.data)
  }
}

export const storiesTransport = new StoriesTransport()
