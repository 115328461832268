import * as styles from './global-feedback.style'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { LayoutPage } from 'components/layout-page'
import { navigateBack } from 'utils/navigation'
import { SanekButton } from 'ui/sanek-button'
import { CellEmpty } from 'ui/cell-empty'
import { useState } from 'react'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums'
import { globalFeedbackTransport } from 'api/global-feedback/global-feedback.transport'
import { useRollbar } from '@rollbar/react'

export const GlobalFeedbackPage = (): JSX.Element => {
  useScrollToTop()
  const [feedback, setFeedback] = useState('')

  const rollbar = useRollbar()

  const hasEmptyFeedback = !feedback.length

  const handleGetFeedback = async () => {
    if (feedback.trim()) {
      try {
        await globalFeedbackTransport.createFeedback(feedback)
        setFeedback('')
        void router.navigate(SanekRoute.MEETING_FEEDBACK_SUCCESS)
      } catch (error: unknown) {
        rollbar.error(
          'Произошла ошибка при создании обратной связи:',
          error as Error
        )
      }
    }
  }

  return (
    <LayoutPage headerText="Обратная связь" backAction={navigateBack}>
      <div className={styles.mainContainer}>
        <h3>Чем хочешь поделиться?</h3>
        <CellEmpty height="5" />
        <textarea
          className={styles.inputStyle}
          placeholder="Здесь ты можешь поделиться с нами обратной связью"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />
        <CellEmpty height="28" />
        <SanekButton disabled={hasEmptyFeedback} onClick={handleGetFeedback}>
          ОТПРАВИТЬ
        </SanekButton>
      </div>
    </LayoutPage>
  )
}
