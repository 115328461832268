/* eslint-disable */

export const initYandexMetrika = () => {
  const YANDEX_ID = 97751203

  const config = {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  }

  if (import.meta.env.VITE_APP_ENV !== 'production') {
    //@ts-ignore
    return (window.ym = () => {})
  }
  //@ts-ignore
  const metrika = function (m, e, t, r, i, k, a) {
    m[i] =
      m[i] ||
      function () {
        ;(m[i].a = m[i].a || []).push(arguments)
      }
    //@ts-ignore
    m[i].l = 1 * new Date()
    k = e.createElement(t)
    a = e.getElementsByTagName(t)[0]
    k.async = 1
    k.src = r
    a.parentNode.insertBefore(k, a)
  }
  //@ts-ignore
  metrika(
    window,
    document,
    'script',
    'https://mc.yandex.ru/metrika/tag.js',
    'ym'
  )

  //@ts-ignore
  window.ym(YANDEX_ID, 'init', config)

  const noscript = document.createElement('noscript')
  const img = document.createElement('img')
  img.src = `https://mc.yandex.ru/watch/${YANDEX_ID}`
  //@ts-ignore
  img.style = { position: 'absolute', left: -9999 }
  img.alt = ''
  noscript.appendChild(img)
  document.head.appendChild(noscript)
}
