import { UserMeetingModel } from 'api/user-meetings/models/user-meeting.model.ts'
import { JSX } from 'react'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { Typography } from 'ui/typography'
import * as styles from './chat-block.style.ts'

type TChatBlockProps = {
  meeting: UserMeetingModel
}

export function ChatBlock({ meeting }: TChatBlockProps): JSX.Element {
  const handleOpenChat = (): void => {
    void router.navigate(`${SanekRoute.MEETING_CHAT}/${meeting.id}`)
  }

  return (
    <div className={styles.chatBlock}>
      <div>
        {meeting.hasNewMessages && (
          <>
            <Typography size="10" weight="700" align="center" color="#ff6242">
              Есть новые
            </Typography>
            <CellEmpty height="3" />
          </>
        )}
        <button className={styles.chatButton} onClick={handleOpenChat}>
          <Typography size="18" weight="700" align="center">
            Показать где я
          </Typography>
        </button>
      </div>
    </div>
  )
}
