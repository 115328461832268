import { JsonName, serialize } from 'tserialize'
export class FilterModel {
  @JsonName()
  today: boolean = false

  @JsonName()
  tomorrow: boolean = false

  @JsonName('day_after_tomorrow')
  dayAfterTomorrow: boolean = false

  @JsonName('next_week')
  nextWeek: boolean = false

  @JsonName('is_recipient')
  isRecipient: boolean = false

  @JsonName('is_owner')
  isOwner: boolean = false

  constructor(data: object) {
    Object.assign(this, data)
  }

  toServer(): object {
    return serialize(this)
  }
}
