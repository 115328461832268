import axios, {
  AxiosInstance,
  AxiosRequestHeaders,
  AxiosRequestTransformer,
} from 'axios'
import { notAuthorizedRoutes, SanekRoute } from '../router/router.enums.ts'
import { router } from '../router'

/** Добавляем данные об авторизации пользователя, если таковые есть */
let transformRequestFunctions: Array<AxiosRequestTransformer> = [
  (data: unknown, headers?: AxiosRequestHeaders) => {
    const jwt = localStorage.getItem('jwt')
    if (jwt) {
      headers?.set('Authorization', `Bearer ${jwt}`)
    }

    return data
  },
]
if (axios.defaults.transformRequest) {
  transformRequestFunctions = transformRequestFunctions.concat(
    axios.defaults.transformRequest
  )
}

/**
 * Сконфигурированный axios транспорт
 */

const axiosInstance = axios.create({
  transformRequest: transformRequestFunctions,
})

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const responseURL = error.response.config.url
    const isUserResponseUrl =
      responseURL.includes('/api/users/me') ||
      responseURL.includes('/api/sanek-users')
    const pathName = router.state.location.pathname
    const shouldRedirect =
      isUserResponseUrl && !notAuthorizedRoutes.includes(pathName as SanekRoute)

    if (isUserResponseUrl && error.response.status === 401) {
      localStorage.removeItem('jwt')
    }

    if (shouldRedirect) {
      return await router.navigate(SanekRoute.INTRO_REGISTER_SMS)
    }

    return Promise.reject(error)
  }
)

export const transport: AxiosInstance = axiosInstance
