import { Picker } from 'antd-mobile'
import { PickerValue } from 'antd-mobile/es/components/picker-view'
import dayjs, { Dayjs } from 'dayjs'
import { JSX, useId, useMemo, useState } from 'react'
import { TPickerTimeslot } from 'types/time-picker.types.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { FlexContainer } from 'ui/flex-container'
import { Typography } from 'ui/typography'
import {
  getPickerValue,
  getTimePickerFromColumns,
  getTimePickerToColumns,
  getTimeValue,
} from 'utils/time-picker.utils.ts'
import * as styles from './timeslot-time-select.style.ts'

type TTimeslotTimeSelectProps = {
  timeslot: TPickerTimeslot
  onChange: (timeslot: TPickerTimeslot) => void
}

export function TimeslotTimeSelect({
  timeslot,
  onChange,
}: TTimeslotTimeSelectProps): JSX.Element {
  const id = useId()

  const [timeFrom, setTimeFrom] = useState<Dayjs>(timeslot.timeFrom)
  const [isTimeFromPickerOpen, setIsTimeFromPickerOpen] =
    useState<boolean>(false)

  const [timeTo, setTimeTo] = useState<Dayjs>(timeslot.timeTo)
  const [isTimeToPickerOpen, setIsTimeToPickerOpen] = useState<boolean>(false)
  const [originalTimeslot] = useState<TPickerTimeslot>(timeslot)

  const [selectedTimeFrom, setSelectedTimeFrom] = useState<[number, number]>([
    0, 0,
  ])
  const [selectedTimeTo, setSelectedTimeTo] = useState<[number, number]>([0, 0])

  const minDateTime = useMemo(() => {
    const currentDateTime = dayjs()
    const availableMinDate = currentDateTime.add(2, 'h')

    return originalTimeslot.timeFrom.isBefore(availableMinDate, 'm')
      ? availableMinDate
      : originalTimeslot.timeFrom
  }, [originalTimeslot.timeFrom])

  const maxDateTime = useMemo(() => {
    return originalTimeslot.timeTo
  }, [originalTimeslot.timeTo])

  // время начала не менее предложенного таймслотом и не более чем текущее выбранное время конца
  const timePickerFromColumns = useMemo(() => {
    return getTimePickerFromColumns(
      minDateTime,
      maxDateTime,
      selectedTimeFrom[0],
      timeTo
    )
  }, [minDateTime, maxDateTime, selectedTimeFrom, timeTo])

  // время конца не менее чем текущее выбранное время начала и не более предложенного таймслотом
  const timePickerToColumns = useMemo(() => {
    return getTimePickerToColumns(
      minDateTime,
      maxDateTime,
      selectedTimeTo[0],
      timeFrom
    )
  }, [minDateTime, maxDateTime, selectedTimeTo, timeFrom])

  const handleSelectTimeFrom = (value: Array<PickerValue>): void => {
    setSelectedTimeFrom(value as [number, number])
  }

  const handleSelectTimeTo = (value: Array<PickerValue>): void => {
    setSelectedTimeTo(value as [number, number])
  }

  const confirmChange = (
    timeData: Pick<TPickerTimeslot, 'timeFrom' | 'timeTo'>
  ): void => {
    onChange({
      id,
      date: timeslot.date,
      timeFrom: timeData.timeFrom,
      timeTo: timeData.timeTo,
    })
  }

  const handleOpenTimeFromPicker = (): void => {
    setIsTimeFromPickerOpen(true)
  }

  const handleCloseTimeFromPicker = (): void => {
    setIsTimeFromPickerOpen(false)
  }

  const handleTimeFromConfirm = (value: Array<PickerValue>): void => {
    const newTimeFrom = getTimeValue(value)

    setTimeFrom(newTimeFrom)
    handleCloseTimeFromPicker()
    confirmChange({ timeFrom: newTimeFrom, timeTo })
  }

  const handleOpenTimeToPicker = (): void => {
    setIsTimeToPickerOpen(true)
  }

  const handleCloseTimeToPicker = (): void => {
    setIsTimeToPickerOpen(false)
  }

  const handleTimeToConfirm = (value: Array<PickerValue>): void => {
    const newTimeTo = getTimeValue(value)

    setTimeTo(newTimeTo)
    handleCloseTimeToPicker()
    confirmChange({ timeFrom, timeTo: newTimeTo })
  }

  return (
    <FlexContainer justify="space-between" wrap="nowrap">
      <div>
        <Typography>С</Typography>
        <CellEmpty height="5" />

        <div className={styles.pickerValue} onClick={handleOpenTimeFromPicker}>
          {getTimeLabel(timeFrom)}
        </div>
        <Picker
          visible={isTimeFromPickerOpen}
          columns={timePickerFromColumns}
          value={getPickerValue(timeFrom)}
          onSelect={handleSelectTimeFrom}
          onConfirm={handleTimeFromConfirm}
          onCancel={handleCloseTimeFromPicker}
          onClose={handleCloseTimeFromPicker}
          popupClassName={styles.timePicker}
        />
      </div>

      <div>
        <Typography>По</Typography>
        <CellEmpty height="5" />

        <div className={styles.pickerValue} onClick={handleOpenTimeToPicker}>
          {getTimeLabel(timeTo)}
        </div>
        <Picker
          visible={isTimeToPickerOpen}
          columns={timePickerToColumns}
          value={getPickerValue(timeFrom)}
          onSelect={handleSelectTimeTo}
          onConfirm={handleTimeToConfirm}
          onCancel={handleCloseTimeToPicker}
          onClose={handleCloseTimeToPicker}
          popupClassName={styles.timePicker}
        />
      </div>
    </FlexContainer>
  )
}

export function getTimeLabel(time: Dayjs | null): string {
  if (!time) {
    return 'Время'
  }

  const hours = time.format('HH')
  const minutes = time.format('mm')
  return `${hours}\u00a0\u00a0:\u00a0\u00a0${minutes}`
}
