import { css } from '@emotion/css'
import Splash from 'assets/decorations/meeting-details/splash.png'
import { flexCenterContent, maxLines } from 'styles/common.style.ts'
import { buttonReset } from 'styles/reset.style.ts'

const splashDecoration = css`
  width: 22px;
  height: 22px;
  content: '';
  background: url(${Splash}) center/contain no-repeat;
`

export function getMeetingCardStyles(isCreator: boolean): string {
  return css`
    position: relative;
    border: 5px solid #f2f2f2;
    border-radius: 42px;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.25);
    background: ${isCreator ? '#ffde00' : '#9fcbdc'};
    padding: 10px;
    
    &::before {
      ${splashDecoration};
      position: absolute;
      top: -18px;
      left: -12px;
    }
  
    &::after {
      ${splashDecoration};
      position: absolute;
      bottom: -18px;
      right: -12px;
      transform: rotate(180deg);
    }
  `
}

export const placeNameBox = css`
  ${flexCenterContent};
  width: 75%;
  height: 60px;
  background: white;
  border-radius: 30px 10px 10px 10px;
  padding: 10px;
  text-align: center;
`

export const placeNameText = css`
  font-size: 20px;
  ${maxLines(2)};
`

export const placeLinkBox = css`
  ${flexCenterContent};
  width: 20%;
  height: 60px;
  background: white;
  border-radius: 10px 30px 10px 10px;
`

export const meetingTimeBox = css`
  ${flexCenterContent};
  flex-direction: column;
  width: 30%;
  height: 84px;
  background: white;
  border-radius: 10px 10px 10px 30px;
  padding: 10px;
  text-align: center;
`

export const placeAddressBox = css`
  width: 65%;
  height: 84px;
  background: white;
  border-radius: 10px 10px 30px 10px;
  padding: 10px;
`

export const addressText = css`
  width: 80%;
  font-size: 12px;
  line-height: 12px;
  font-weight: 300;
  ${maxLines(3)};
`

export const geolocationButton = css`
  ${buttonReset};
  width: 37px;
  height: 37px;
  border-radius: 18px;
  background-color: #FFDE00;
  padding: 4px;
`
export const geolocationImage= css`
  width: 17px;
  height: 28px;
`

export const placeDetailsButton= css`
  ${buttonReset};
  background-color: transparent;
`

export const placeDetailsButtonImage= css`
  width: 30px;
  height: 30px;
`
