import * as styles from './change-city.style'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { LayoutPage } from 'components/layout-page'
import { navigateBack } from 'utils/navigation'
import { SanekButton } from 'ui/sanek-button'
import { CellEmpty } from 'ui/cell-empty'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums'
import { CityPicker } from 'components/city-picker'
import { CityModel } from 'api/cities/models/city.model'
import { useState } from 'react'
import { userStore } from 'store/user.store'
import { USER_NOT_FOUND_ERROR } from 'const/common.const'
import { sanekUsersTransport } from 'api/sanek-users.transport'
import { useRollbar } from '@rollbar/react'

export const ChangeCityPage = (): JSX.Element => {
  useScrollToTop()
  const { user } = userStore

  const [city, setCity] = useState<CityModel | null>(user?.city || null)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const rollbar = useRollbar()

  const handleCityChange = (value: CityModel): void => {
    setCity(value)
  }

  const handleSaveCity = async () => {
    if (!user) {
      rollbar.error(USER_NOT_FOUND_ERROR)
      return
    }

    if (!city) {
      rollbar.error('Город не выбран')
      return
    }

    setIsProcessing(true)

    sanekUsersTransport
      .changeUserCityData(user.id, {
        cityId: city.id,
      })
      .then(() => {
        userStore.getMe().then(() => {
          void router.navigate(SanekRoute.PERSONAL, {
            replace: true,
          })
        })
      })
      .catch((error) => {
        rollbar.error('Ошибка при сохранении города для Sanek User:', error)
      })
      .finally(() => setIsProcessing(false))
  }

  return (
    <LayoutPage headerText="ГОРОД" backAction={navigateBack}>
      <div className={styles.mainContainer}>
        <h3>Выбери город по умолчанию</h3>
        <CellEmpty height="18" />
        <CityPicker onChange={handleCityChange} value={city?.name} />
        <CellEmpty height="5" />

        <div style={{ flexGrow: 1 }}></div>

        <SanekButton onClick={handleSaveCity} isLoading={isProcessing}>
          Сохранить
        </SanekButton>
      </div>
    </LayoutPage>
  )
}
