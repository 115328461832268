import { css } from '@emotion/css'

export const selectedPlace = css`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-radius: 16px;
  background: #f6f6f6;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.25);
  min-height: 70px;

  div {
    flex-grow: 1;
    flex-basis: 0;

    &:nth-of-type(2) {
      border-left: 2px #000 dotted;
      padding-left: 8px;
    }
  }

  p {
    font-family: Copperplate, serif;
    line-height: normal;
    font-style: normal;
  }
`

export const title = css`
  color: #384043;
  font-size: 16px;
  font-weight: 400;
`

export const name = css`
  color: #000;
  font-size: 14px;
  font-weight: 300;
  margin-top: 4px;
`
