import { MeetingCounterpart } from 'api/user-meetings/models/meeting-counterpart.ts'
import AvatarStroke from 'assets/decorations/meeting-details/avatar-stroke.png'
import { AudioPlayButton } from 'components/common/sound-play-button'
import { MATCH_TOP_LABEL_MAP } from 'const/counterpart.const.ts'
import { JSX } from 'react'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { FlexContainer } from 'ui/flex-container'
import { Typography } from 'ui/typography'
import * as styles from './sanek-friend-info.style.ts'

type TSanekFriendInfoProps = {
  friend: MeetingCounterpart
}

export function SanekFriendInfo({
  friend,
}: TSanekFriendInfoProps): JSX.Element {
  const matchedRoles = (friend.matchedRoles || []).slice(0, 3)

  return (
    <>
      <FlexContainer justify="space-between" align="end" wrap="nowrap">
        <div className={styles.avatarContainer}>
          {/* TODO скорее всего, показывать только в определенных статусах, как устаканим */}
          {friend.voice.url && <AudioPlayButton audioSrc={friend.voice.url} />}
          <img src={AvatarStroke} alt="" className={styles.avatarStroke} />
          <img
            src={friend.imageUrl}
            alt="Аватар"
            className={styles.avatarImage}
          />
        </div>

        <div className={styles.targetContainer}>
          <FlexContainer
            direction="column"
            align="center"
            className={styles.nameContainer}
          >
            <Typography size="28" weight="700">
              {friend.name}
            </Typography>
            <div className={styles.nameDivider} />
            <Typography size="10" weight="300">
              Имя
            </Typography>
          </FlexContainer>

          {friend.matchTop && (
            <FlexContainer
              direction="column"
              align="center"
              className={styles.compatibilityContainer}
            >
              <Typography size="16" weight="700">
                {MATCH_TOP_LABEL_MAP[friend.matchTop]}
              </Typography>
              <div className={styles.compatibilityDivider} />
              <Typography size="10" weight="300">
                Совместимость
              </Typography>
            </FlexContainer>
          )}
        </div>
      </FlexContainer>
      <CellEmpty height="35" />

      {matchedRoles.length > 0 && (
        <FlexContainer justify="space-around" wrap="nowrap">
          {matchedRoles.map((roleTitle: string) => (
            <div className={styles.roleItem} key={roleTitle}>
              <Typography size="14" weight="300" align="center" ellipsis>
                {roleTitle}
              </Typography>
            </div>
          ))}
        </FlexContainer>
      )}
    </>
  )
}
