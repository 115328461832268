import cx from 'classnames'
import { userMeetingsTransport } from 'api/user-meetings/user-meetings.transport.ts'
import AvatarStroke from 'assets/decorations/meeting-details/avatar-stroke.png'
import OnSpot from 'assets/decorations/meeting-details/on_the_spot.png'
import OnMyWay from 'assets/decorations/meeting-details/on_my_way.png'
import Late from 'assets/decorations/meeting-details/late.png'
import WontCome from 'assets/decorations/meeting-details/wont_come.png'
import { ConfirmMeetingRefusalModal } from 'components/confirm-meeting-refusal-modal'
import { NOTIFICATION_STATUS_LABEL_MAP } from 'const/notification.const.ts'
import { NotificationStatus } from 'enums/notification.enums.ts'
import { observer } from 'mobx-react-lite'
import { JSX, useState } from 'react'
import { meetingDetailsStore } from 'store/meeting-details.store.ts'
import { userStore } from 'store/user.store.ts'
import { FlexContainer } from 'ui/flex-container'
import { Typography } from 'ui/typography'
import * as styles from './self-presence-status.style.ts'
import { closeAllDialogs, openDialog } from 'utils/dialog.ts'

const BUTTONS = [
  {
    text: NOTIFICATION_STATUS_LABEL_MAP[NotificationStatus.IN_PLACE],
    icon: OnSpot,
    value: NotificationStatus.IN_PLACE,
  },
  {
    text: NOTIFICATION_STATUS_LABEL_MAP[NotificationStatus.ON_MY_WAY],
    icon: OnMyWay,
    value: NotificationStatus.ON_MY_WAY,
  },
  {
    text: NOTIFICATION_STATUS_LABEL_MAP[NotificationStatus.BE_LATE],
    icon: Late,
    value: NotificationStatus.BE_LATE,
  },
  {
    text: NOTIFICATION_STATUS_LABEL_MAP[NotificationStatus.WONT_COME],
    icon: WontCome,
    value: NotificationStatus.WONT_COME,
  },
]

type TSelfPresenceStatusProps = {
  notificationStatus: NotificationStatus | null
}

export const SelfPresenceStatus = observer(
  ({ notificationStatus }: TSelfPresenceStatusProps): JSX.Element => {
    const { user } = userStore
    const { meeting } = meetingDetailsStore
    const [chosenStatus, setChosenStatus] = useState<NotificationStatus | null>(
      notificationStatus
    )

    const handleRefuse = async () => {
      await handleWontCome()
      closeAllDialogs()
    }

    const handleStatusChange = (status: NotificationStatus): void => {
      if (!meeting) {
        return
      }

      if (status === NotificationStatus.WONT_COME) {
        openDialog({
          content: (
            <ConfirmMeetingRefusalModal
              onClose={closeAllDialogs}
              onRefuse={handleRefuse}
              onAccept={closeAllDialogs}
            />
          ),
        })
        return
      }

      setChosenStatus(status)
      void userMeetingsTransport.notifyMeetingStatus(meeting.id, status)
    }

    const handleWontCome = async (): Promise<void> => {
      if (!meeting) {
        return
      }

      try {
        await userMeetingsTransport.notifyMeetingStatus(
          meeting.id,
          NotificationStatus.WONT_COME
        )
        await userMeetingsTransport.cancelMeeting(meeting.id)
      } finally {
        meetingDetailsStore.getMeeting(meeting.id)
      }
    }

    return (
      <FlexContainer justify="space-between" align="end" wrap="nowrap">
        <div className={styles.avatarContainer}>
          <img src={AvatarStroke} alt="" className={styles.avatarStroke} />
          <img
            src={user?.avatar?.image.url}
            alt="Аватар"
            className={styles.avatarImage}
          />
        </div>

        <div className={styles.buttonsContainer}>
          {BUTTONS.map((data) => (
            <button
              key={data.value}
              className={cx(styles.statusButton, {
                [styles.activeButton]: data.value === chosenStatus,
              })}
              onClick={handleStatusChange.bind(null, data.value)}
            >
              <FlexContainer align="center" wrap="nowrap">
                <img src={data.icon} alt="" />
                <Typography weight="300">{data.text}</Typography>
              </FlexContainer>
            </button>
          ))}
        </div>
      </FlexContainer>
    )
  }
)
