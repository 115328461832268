import { Dialog } from 'antd-mobile'
import { ReactNode } from 'react'
import * as antdStyles from 'styles/antd-mobile.style.ts'
import { DialogShowHandler } from 'antd-mobile/es/components/dialog'

export function openDialog({
  content,
}: {
  content: ReactNode
}): DialogShowHandler {
  return Dialog.show({
    content,
    maskClassName: antdStyles.dialogMask,
    bodyClassName: antdStyles.dialogBody,
  })
}

export function closeAllDialogs(): void {
  Dialog.clear()
}
