import { JSX, PropsWithChildren, ReactNode } from 'react'
import styles from './layout-page.module.css'

type TLayoutPageProps = PropsWithChildren<{
  headerText?: string
  footerNode?: ReactNode
  accessoryRight?: JSX.Element
  backAction?: VoidFunction
}>

export function LayoutPage({
  children,
  headerText,
  footerNode,
  accessoryRight,
  backAction,
}: TLayoutPageProps): JSX.Element {
  return (
    <div className={styles.layoutPage}>
      <div className={styles.layoutPageHeader}>
        <div className={styles.accessoryContainer}>
          {!!backAction && (
            <button className={styles.backButton} onClick={backAction}></button>
          )}
        </div>
        <div className={styles.layoutPageHeaderCenter}>{headerText}</div>
        <div className={styles.accessoryContainer}>{accessoryRight} </div>
      </div>

      <div className={styles.layoutPageContent}>{children}</div>

      {footerNode && (
        <div className={styles.layoutPageFooter}>{footerNode}</div>
      )}
    </div>
  )
}
