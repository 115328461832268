import { css } from '@emotion/css'

export const richTextStyles = css`
  li,
  ul,
  ol {
    list-style-position: inside;
  }

  img {
    width: 100%;
    object-fit: contain;
  }
`
