import * as styles from './places.styles.ts'

import { JSX } from 'react'

import { PlaceModel } from 'api/places/models/place.model.ts'

import PlacePictureBottom from 'assets/decorations/places/place-picture-bottom.svg'

import { CellEmpty } from 'ui/cell-empty.tsx'
import { FlexContainer } from 'ui/flex-container'
import { ProgressiveImage } from 'components/progressive-image/progressive-image.tsx'

type TPlaceItemImageProps = {
  place: PlaceModel
}

export function PlaceItemImage({ place }: TPlaceItemImageProps): JSX.Element {
  if (place.isCreated && place.categories[0]) {
    return (
      <FlexContainer direction="column" align="center">
        <CellEmpty height="15" />
        <img src={place.categories[0].image.url} alt="Картинка категории" />
        <CellEmpty height="7" />
        <p className={styles.placeCategoryLabel}>{place.categories[0].text}</p>
      </FlexContainer>
    )
  }

  return (
    <>
      <div className={styles.imageWrapper}>
        {place.image && (
          <ProgressiveImage
            image={place.image}
            alt="Картинка заведения"
            className={styles.placeImage}
          />
        )}
      </div>

      <img
        src={PlacePictureBottom}
        alt=""
        className={styles.placeImageBottom}
      />
    </>
  )
}
