import { css } from '@emotion/css'

export const container = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 5px;
  margin-top: 20px;
`

export const counter = css`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 3px solid #ffde00;
  border-radius: 29px;
  width: 74px;
  height: 35px;
  box-shadow: 2px 3px 0 0 rgba(0, 0, 0, 0.25);
  background: #fafafa;
  font-family: 'Copperplate', sans-serif;
  font-weight: 700;
  font-size: 36px;
  text-align: center;
  color: #000;
`
export const text = css`
  font-family: 'Copperplate', sans-serif;
  font-weight: 300;
  font-size: 10px;
  text-align: center;
  color: #000;
`
