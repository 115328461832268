export type TGetBlobConfig = {
  url: string
}

export type TGetFileConfig = {
  url: string
  fileName: string
  mimeType: string
}

export function getBlobFromUrl({ url }: TGetBlobConfig): Promise<Blob> {
  return fetch(url).then(res => res.blob())
}

export function getFileFromUrl({ url, fileName, mimeType }: TGetFileConfig): Promise<File> {
  return getBlobFromUrl({ url })
    .then((blob: Blob) => new File(
      [blob],
      fileName,
      {
        type: mimeType
      })
    )
}
