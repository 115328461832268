import { transport } from 'api/transport.ts'

export class MeetingRateTransport {
  addMeetingRate(meetingId: number, value: number): Promise<void> {
    return transport.post(
      `${import.meta.env.VITE_STRAPI_API}/api/add-meeting-rate`,
      {
        data: {
          id: meetingId,
          value,
        }
      }
    )
  }
}

export const meetingRateTransport = new MeetingRateTransport()
