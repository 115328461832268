import { css } from '@emotion/css'
import { flexCenterContent, maxLines } from 'styles/common.style.ts'
import { buttonReset } from 'styles/reset.style.ts'

export const mainContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`

export const imageWrapper = css`
  width: 290px;
  height: 170px;
`

export const placesPage = css`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  padding: 0.5rem 2rem 2rem;
`

export const leftDecoration = css`
  position: absolute;
  top: 20px;
  left: 0;
`

export const rightDecoration = css`
  position: absolute;
  top: 20px;
  right: 10px;
`

export const searchButton = css`
  ${buttonReset};
  ${flexCenterContent};
  width: 34px;
  height: 33px;
  background: transparent;
  cursor: pointer;
  z-index: 10;
`

export const placeItem = css`
  box-sizing: border-box;
  width: 100%;
  padding: 12px 14px;
  border-radius: 16px;
  border: 4px solid #f2f2f2;
  background: #f6f6f6;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.25);
`

export const placeName = css`
  font-family: 'Chalkboard SE', serif;
  font-size: 20px;
  font-weight: 400;
`

export const placeFavouriteButton = css`
  ${buttonReset};
  width: 29px;
  height: 24px;
  margin-left: 10px;
  background-color: transparent;
`

export const placeHr = css`
  display: block;
  width: 100%;
`

export const commonText = css`
  font-size: 12px;
  font-weight: 300;

  @media (max-width: 400px) {
    font-size: 10px;
  }
`

export const placePriceSegment = css`
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
  min-width: 35px;
  text-align: right;

  @media (max-width: 400px) {
    margin-left: 0;
    font-size: 12px;
  }
`

export const placeImage = css`
  display: block;
  width: 100%;
  border-radius: 12px;
  border: 2px solid #cbcbcb;
  max-height: 170px;
  object-fit: cover;
`

export const placeImageBottom = css`
  display: block;
  width: 100%;
  position: relative;
  top: -3px;
`

export const placeCategoryLabel = css`
  max-width: 50%;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  text-align: center;
`

export const placeDescription = css`
  ${commonText};
  ${maxLines(3)}
`

export const placeGeolocationButton = css`
  ${buttonReset};
  width: 75px;
  height: 22px;
  border-radius: 9px;
  background: #ffde00;
  padding: 2px 5px;
  color: #000;
  font-family: 'Chalkboard SE', serif;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
`

export const placeMoreDetails = css`
  font-family: 'Chalkboard SE', serif;
  font-size: 18px;
  font-weight: 400;
`
export const modalMask = css`
  & + .adm-center-popup-wrap {
    width: 100%;
    min-width: 100%;

    .adm-modal-content {
      padding: 0 25px !important;
      height: 600px;
    }
  }
`
