import { cx } from '@emotion/css'
import { PriceSegment } from 'enums/places.enums.ts'
import { ChangeEvent, JSX } from 'react'
import { TRadioOption } from 'ui/radio'
import * as styles from './price-segment-radio.style.ts'

const SEGMENT_DESCRIPTION_MAP: Record<PriceSegment, string> = {
  [PriceSegment.BUDGET]: '≈ 500₽',
  [PriceSegment.MEDIUM]: '≈ 1500₽',
  [PriceSegment.EXPENSIVE]: '≈ 2500₽',
}

type TPriceSegmentRadioProps = {
  option: TRadioOption<PriceSegment>
  checked: boolean
  withDescription?: boolean
  onChange: (value: ChangeEvent<HTMLInputElement>) => void
}
export const PriceSegmentRadio = ({
  option,
  checked,
  withDescription,
  onChange,
}: TPriceSegmentRadioProps): JSX.Element => {
  return (
    <div className={styles.segmentWrapper}>
      <label
        className={cx(styles.segment, {
          [styles.segmentSelected]: checked,
        })}
      >
        <p>{option.label}</p>
        <input
          type="radio"
          name={option.value}
          value={option.value}
          checked={checked}
          id={option.value}
          onChange={onChange}
        />
      </label>
      {withDescription && (
        <p className={styles.segmentDescription}>
          {SEGMENT_DESCRIPTION_MAP[option.value]}
        </p>
      )}
    </div>
  )
}
