import { deserialize, JsonName, JsonNameReadonly, JsonStruct } from 'tserialize'
import { TimeslotModel } from 'api/models/timeslot.model.ts'

class RecipientModel {
  @JsonName()
  id!: number
}

export class InvitationShortModel {
  @JsonNameReadonly()
  id!: number

  @JsonStruct(RecipientModel)
  recipient!: RecipientModel

  @JsonName('confirmed_timeslot')
  confirmedTimeslot: TimeslotModel | null = null

  static fromServer(data: object): InvitationShortModel {
    return deserialize(data, InvitationShortModel)
  }
}
