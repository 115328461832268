import { ChangeEventHandler, JSX, PropsWithChildren } from 'react';
import styles from './places-tabs.module.css';

type TPlacesTabProps = PropsWithChildren<{
  value: string;
  isSelected: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}>;

export function PlacesTab({ children, value, isSelected, onChange }: TPlacesTabProps): JSX.Element {
  return (
    <label className={isSelected ? styles.tabSelected : styles.tab}>
      <input
        type="radio"
        value={value}
        checked={isSelected}
        onChange={onChange}
      />
      {children}
    </label>
  )
}
