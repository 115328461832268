import { deserialize, JsonName } from 'tserialize'

export class CityModel {
  @JsonName()
  id!: number

  @JsonName()
  name: string = ''

  @JsonName()
  code: string = ''

  @JsonName()
  latitude: string = ''

  @JsonName()
  longitude: string = ''

  static fromServer(data: object): CityModel {
    return deserialize(data, CityModel)
  }
}
