import { Slider } from 'antd-mobile'
import { SliderValue } from 'antd-mobile/es/components/slider'
import { meetingRateTransport } from 'api/meeting-rate/meeting-rate.transport.ts'
import { meetingUserRateTransport } from 'api/meeting-user-rate/meeting-user-rate.transport.ts'
import { MEETING_RATE_DATA } from 'const/meeting-rate.const.ts'
import { observer } from 'mobx-react-lite'
import { RateCounterpart } from 'pages/meeting-details/content/rate-meeting-content/rate-counterpart.tsx'
import { JSX, useState } from 'react'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums.ts'
import { meetingDetailsStore } from 'store/meeting-details.store.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { SanekButton } from 'ui/sanek-button'
import { Typography } from 'ui/typography'
import * as styles from './rate-meeting-content.style.ts'
import { SeparatorGrayThin } from 'components/common/separator/separator-gray-thin.tsx'

export const RateMeetingContent = observer((): JSX.Element => {
  const { meeting } = meetingDetailsStore
  const [meetingRate, setMeetingRate] = useState<number>(5)
  const [counterpartRate, setCounterpartRate] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  if (!meeting || !meeting.counterpart) {
    return <></>
  }

  const handleSubmit = (): void => {
    if (!meeting.counterpart || !counterpartRate) {
      return
    }

    setIsLoading(true)
    Promise.all([
      meetingRateTransport.addMeetingRate(meeting.id, meetingRate),
      meetingUserRateTransport.addMeetingUserRate(
        meeting.id,
        meeting.counterpart.id,
        counterpartRate
      ),
    ])
      .then(() =>
        router.navigate(
          `${SanekRoute.MEETING_EVOLUTION_SUCCESS}/${meeting.id}`,
          {
            replace: true,
          }
        )
      )
      // TODO обработка ошибок
      .finally(() => setIsLoading(false))
  }

  const handleMeetingRateChange = (value: SliderValue): void => {
    if (!Array.isArray(value)) {
      setMeetingRate(value)
    }
  }

  return (
    <>
      <Typography family="Chalkboard" size="20">
        Как прошла встреча?
      </Typography>
      <CellEmpty height="10" />

      <Slider
        ticks
        step={1}
        min={1}
        max={5}
        icon={<></>}
        value={meetingRate}
        onChange={handleMeetingRateChange}
        className={styles.getMeetingRateSliderStyle(MEETING_RATE_DATA)}
      />
      <CellEmpty height="20" />

      <SeparatorGrayThin />
      <CellEmpty height="5" />

      <Typography family="Chalkboard" size="20">
        Как тебе мой друг?
      </Typography>
      <CellEmpty height="20" />

      <RateCounterpart
        counterpart={meeting.counterpart}
        value={counterpartRate}
        onChange={setCounterpartRate}
      />
      <CellEmpty height="20" />

      <SanekButton
        isLoading={isLoading}
        disabled={!counterpartRate}
        onClick={handleSubmit}
      >
        ОЦЕНИТЬ ВСТРЕЧУ
      </SanekButton>
    </>
  )
})
