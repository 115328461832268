import { deserialize } from 'tserialize'

// TODO лучше писать кастомные контроллеры под выборки, но в качестве быстрой меры можно юзать утилиты и десериализаторы

export type TStrapiDataArray = {
  data: Array<TStrapiAttrStruct>
}

export type TStrapiDataStruct = {
  data: TStrapiAttrStruct
}

export type TStrapiAttrStruct = {
  id: number
  attributes: object
}

type TConstructor<T> = { new (...args: Array<unknown>): T }

export function makeFlatStrapiStructure({
  id,
  attributes,
}: TStrapiAttrStruct): object {
  return {
    id,
    ...attributes,
  }
}

export function getStrapiStructDeserializer<T>(
  TargetClass: TConstructor<T>
): (raw: TStrapiDataStruct) => T {
  return function (strapiDataStruct: TStrapiDataStruct) {
    return deserialize(
      makeFlatStrapiStructure(strapiDataStruct.data),
      TargetClass
    )
  }
}

export function getStrapiArrayDeserializer<T>(
  TargetClass: TConstructor<T>
): (raw: TStrapiDataArray) => Array<T> {
  return function (strapiDataArray: TStrapiDataArray) {
    return strapiDataArray.data.map((item: TStrapiAttrStruct) =>
      deserialize(makeFlatStrapiStructure(item), TargetClass)
    )
  }
}

export function serializeAddRelation(data: number): object {
  return {
    connect: [data],
  }
}
