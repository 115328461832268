import { JSX, useEffect } from 'react'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { Loader } from '../loader/loader.tsx'
import style from './loading-screen.module.css'

import LeftRadiusTop from 'assets/left-radius-top.svg'
import RightRadiusTop from 'assets/right-radius-top.svg'
import RightRadiusBottom from 'assets/right-radius-bottom.svg'
import LeftRadiusBottom from 'assets/left-radius-bottom.svg'
import DescSvg from 'assets/desc.svg'

export const LoadingScreen = (): JSX.Element => {
  useEffect(() => {
    const themeColorMetaEl = document.querySelector('meta[name="theme-color"]')

    if (!themeColorMetaEl) {
      return
    }

    themeColorMetaEl.setAttribute('content', '#ffde00')

    return () => themeColorMetaEl.setAttribute('content', '#ffffff')
  }, [])

  return (
    <div className={style.loadingScreen}>
      <div className={style.headerBlock}>
        <div className={style.leftRadius}>
          <img src={LeftRadiusTop} />
          <img src={LeftRadiusBottom} />
        </div>
        <h2 className={style.header}>САНЁК ЗОВЁТ</h2>
        <div className={style.rightRadius}>
          <img src={RightRadiusTop} />
          <img src={RightRadiusBottom} />
        </div>
      </div>
      <CellEmpty height="30" />

      <Loader />
      <CellEmpty height="30" />

      <div className={style.footer}>
        <p className={style.description}>Мои друзья ждут тебя</p>
        <img src={DescSvg} />
      </div>
    </div>
  )
}
