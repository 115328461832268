import { css } from '@emotion/css'

export const notificationCard = css``

export const time = css`
  padding-right: 12px;
  text-align: right;
  font-weight: bold;
  font-size: 14px;
`
export const content = css`
  padding: 12px;
  border-radius: 20px;
  border: 5px solid #ffde00;
`

export const seen = css`
  padding: 12px;
  border-radius: 20px;
  border: 5px solid #f2f2f2;
`

export const title = css`
  font-size: 14px;
  font-weight: bold;
`
export const body = css`
  margin-top: 12px;
  font-size: 14px;
  line-height: 14px;
`

export const action = css`
  margin-top: 12px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: underline;
`
