import { css } from '@emotion/css'

const textStyles = css`
  color: #000;
  font-family: Copperplate, serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: -0.408px;
`

export const audioProgressBar = css`
  border-radius: 28px;
  background: #d0d0d0;
  width: 100%;
  height: 24px;
  position: relative;
`

export const audioProgressBarTimeTipWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  p {
    ${textStyles}
  }
`

export const progressValue = css`
  position: absolute;
  right: 10px;
  top: 5px;

  ${textStyles}
`
