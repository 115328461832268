import { css } from '@emotion/css'

export const dialogMask = css`
  & + div[role='dialog'] {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;

    & > div {
      height: 100%;
    }

    .adm-dialog-content {
      max-height: 100%;
      padding: 0 !important;
    }
  }
`
export const dialogBody = css`
  border-radius: 0 !important;
  max-height: 100% !important;
  height: 100% !important;
`

export const picker = css`
  .adm-picker-view-column-item-label,
  .adm-picker-header-button {
    color: #000;
    font-family: 'Chalkboard SE', serif;
    font-size: 20px;
    font-weight: 700;
  }
`

export const datePicker = css`
  .adm-picker-header-title,
  .adm-picker-header-button,
  .adm-picker-view-column-item-label {
    font-family: 'Chalkboard SE', serif;
    font-weight: 700;
    font-size: 20px;
    color: #000;
  }
`
