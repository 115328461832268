import Check from 'assets/decorations/meeting-details/check.png'
import { SanekFriendInfo } from 'components/sanek-friend-info'
import { observer } from 'mobx-react-lite'
import { ChatBlock } from 'pages/meeting-details/components/chat-block'
import { JSX } from 'react'
import { meetingDetailsStore } from 'store/meeting-details.store.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { FlexContainer } from 'ui/flex-container'
import { Typography } from 'ui/typography'
import * as styles from './meeting-in-progress-content.style.ts'
import { SelfPresenceStatus } from '../meeting-soon-content/self-presence-status.tsx'
import { SeparatorGrayThin } from 'components/common/separator/separator-gray-thin.tsx'

export const MeetingInProgressContent = observer((): JSX.Element => {
  const { meeting } = meetingDetailsStore

  if (!meeting) {
    return <></>
  }

  return (
    <>
      {meeting.isNotificationsAvailable ? (
        <>
          <SelfPresenceStatus notificationStatus={meeting.notificationStatus} />
          <CellEmpty height="25" />
        </>
      ) : (
        <>
          <Typography family="Chalkboard" size="24" weight="700" align="center">
            Встреча идет
          </Typography>

          <FlexContainer justify="center">
            <img src={Check} alt="Готово" className={styles.checkImage} />
          </FlexContainer>
          <CellEmpty height="25" />
        </>
      )}

      <Typography family="Chalkboard" size="24">
        Кто где находится
      </Typography>
      <CellEmpty height="10" />

      <ChatBlock meeting={meeting} />

      <SeparatorGrayThin className={styles.separatorImage} />

      {meeting?.counterpart && (
        <>
          <Typography family="Chalkboard" size="26" weight="700">
            Друг Санька
          </Typography>
          <CellEmpty height="10" />

          <SanekFriendInfo friend={meeting.counterpart} />
          <CellEmpty height="20" />
        </>
      )}
    </>
  )
})
