import { PlaceModel } from 'api/places/models/place.model.ts'
import { placesTransport } from 'api/places/places.transport.ts'
import { makeAutoObservable } from 'mobx'

class PlaceDetailsStore {
  place: PlaceModel | null = null
  isLoading: boolean = false
  hasError: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setPlace = (place: PlaceModel): void => {
    this.place = place
  }

  setPlaceFavourite = (isFavourite: boolean = false) => {
    if (!this.place) return
    //@ts-ignore
    this.place = {
      ...this.place,
      isFavourite,
    }
  }

  setIsLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading
  }

  setHasError = (hasError: boolean): void => {
    this.hasError = hasError
  }

  getPlace = (placeId: string | number): void => {
    this.setIsLoading(true)
    this.setHasError(false)

    placesTransport
      .getPlace(placeId)
      .then(this.setPlace)
      .catch(() => {
        this.setHasError(true)
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }
}

export const placeDetailsStore = new PlaceDetailsStore()
