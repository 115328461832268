import { Image } from 'antd-mobile'
import { ImageModel } from 'api/models/image.model.ts'
import { JSX } from 'react'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { Typography } from 'ui/typography'
import * as styles from './chat-message-item.style.ts'

type TMessageContentProps = {
  text: string
  link: string
  photo: ImageModel | null
  isOwnMessage: boolean
}

export function MessageContent({
  text,
  link,
  photo,
  isOwnMessage,
}: TMessageContentProps): JSX.Element {
  return (
    <div className={styles.getMessageContentStyle(isOwnMessage)}>
      {photo && (
        <>
          <Image src={photo.url} />
          <CellEmpty height="15" />
        </>
      )}
      {text && (
        <>
          <Typography size="14" wordBreak="break-word">
            {text}
          </Typography>
          <CellEmpty height="15" />
        </>
      )}
      {link && (
        <>
          <a href={link}>
            <Typography
              type="span"
              size="14"
              wordBreak="break-word"
              decoration="underline"
            >
              {link}
            </Typography>
          </a>
          <CellEmpty height="15" />
        </>
      )}
    </div>
  )
}
