import { css } from '@emotion/css'

export const pageContainer = css`
  padding: 24px;

  h1 {
    text-align: center;
  }
`

export const description = css`
  margin-top: 55px;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
`

export const inputWrapper = css`
  margin-top: 12px;
`

export const agreement = css`
  padding: 24px 0;

  .adm-checkbox .adm-checkbox-custom-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const agreementText = css`
  font-family: 'Copperplate', sans-serif;
  font-size: 12px;
  line-height: 12px;
`

export const action = css`
  text-align: center;
  margin-top: 4px;
`

export const text = css`
  border-bottom: 1px solid black;
`

export const sendPhoneButton = css`
  margin-top: 20px;
`
export const smsCodeForm = css`
  margin-top: 12px;
  display: flex;
  justify-content: center;
`

export const hint = css`
  text-align: center;
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 8px;
`

export const loginButton = css`
  margin-top: 12px;
`
export const errorText = css`
  text-align: center;
  margin-top: 8px;
  font-size: 14px;
  color: #c80000;
`
export const man = css`
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  margin: 0 auto;
`
