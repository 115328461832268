import { css } from '@emotion/css'

export const mainContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 30px;
  width: 100%;
`

export const title = css`
  display: flex;
  height: auto;
  width: 90%;

  font-family: 'Chalkboard SE', sans-serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
  text-align: center;
  color: #000;
  white-space: normal;
  word-wrap: break-word;
`

export const imagesContainer = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ManTall = css`
  z-index: 0;
`

export const delimeter = css`
  position: absolute;
  bottom: -5px;
  z-index: 1;
  width: 316px;
  height: 8px;
`

export const description = css`
  width: 80%;
  font-family: 'Copperplate', sans-serif;
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  color: #000;
`

export const buttonContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  position: fixed;
  bottom: 46px;
`
