import { makeAutoObservable } from 'mobx'
import { meetingTransport } from 'api/meeting/meeting.transport.ts'
import { MeetingMoodModel } from 'api/meeting/models/meeting-mood.model.ts'

class MeetingMoodsStore {
  moods: Array<MeetingMoodModel> = []
  isLoading: boolean = false
  hasError: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setMoods = (moods: Array<MeetingMoodModel>): void => {
    this.moods = moods
  }

  setIsLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading
  }

  setHasError = (hasError: boolean): void => {
    this.hasError = hasError
  }

  getMoods = (): void => {
    this.setIsLoading(true)
    this.setHasError(false)

    void meetingTransport
      .getMeetingMoods()
      .then((data: Array<MeetingMoodModel>) => {
        this.setMoods(data)
      })
      .catch(() => {
        this.setHasError(true)
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }
}

export const meetingMoodsStore = new MeetingMoodsStore()
