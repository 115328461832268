import { JSX } from 'react'
import { TPickerTimeslot } from 'types/time-picker.types.ts'
import * as styles from './timeslot-item.style.ts'
import { getFormattedTime } from 'utils/date.tsx'
import CircleCross from 'assets/circle-cross.svg'

type TSelectedTimeItemProps = {
  item: TPickerTimeslot
  onRemove?: (item: TPickerTimeslot) => void
}
export const TimeslotItem = ({ item, onRemove }: TSelectedTimeItemProps): JSX.Element => {
  const handleRemoveTimeItem = (): void => {
    onRemove?.(item)
  }

  return (
    <div className={styles.timeslot}>
      <p>{item.date.format('dd, DD MMMM')}</p>
      <p>
        {getFormattedTime(item.timeFrom)} - {getFormattedTime(item.timeTo)}
      </p>
      {onRemove ? (
        <button className={styles.removeButton} onClick={handleRemoveTimeItem}>
          <img src={CircleCross} alt="Удалить" />
        </button>
      ) : (
        <div className={styles.removeButton} />
      )}
    </div>
  )
}
