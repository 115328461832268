import { JSX, useState } from 'react'
import { StubMain } from './stub-main/stub-main.tsx'
import * as styles from './desktop-stub.style.ts'
import cx from 'classnames'
import { CellEmpty } from '../../ui/cell-empty.tsx'
import { StubSubscription } from './stub-subscription/stub-subscription.tsx'
import { StubHeader } from './stub-header/stub-header.tsx'
import { StubLegal } from './stub-legal/stub-legal.tsx'

enum DesktopSection {
  MAIN = 'main',
  SUBSCRIPTION = 'subscription',
  LEGAL = 'legal',
}

type TDesktopSection = {
  value: DesktopSection
  title: string
}

const SECTIONS: Array<TDesktopSection> = [
  {
    value: DesktopSection.MAIN,
    title: 'Главная',
  },
  {
    value: DesktopSection.SUBSCRIPTION,
    title: 'Подписка',
  },
  {
    value: DesktopSection.LEGAL,
    title: 'Правовая информация',
  },
]

const CONTENT_MAP: Record<DesktopSection, JSX.Element> = {
  [DesktopSection.MAIN]: <StubMain />,
  [DesktopSection.SUBSCRIPTION]: <StubSubscription />,
  [DesktopSection.LEGAL]: <StubLegal />,
}
export const DesktopStub = (): JSX.Element => {
  const [selectedSection, setSelectedSection] = useState<DesktopSection>(
    DesktopSection.MAIN
  )

  return (
    <div>
      <div className={styles.sectionsWrapper}>
        {SECTIONS.map((section: TDesktopSection) => (
          <p
            key={section.value}
            className={cx(styles.section, {
              [styles.sectionActive]: section.value === selectedSection,
            })}
            onClick={setSelectedSection.bind(null, section.value)}
          >
            {section.title}
          </p>
        ))}
      </div>
      <CellEmpty height="30" />

      <StubHeader />

      <CellEmpty height={127} />

      {CONTENT_MAP[selectedSection]}
    </div>
  )
}
