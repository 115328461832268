import React, { useState, useMemo, useCallback, ChangeEvent } from 'react'
import * as styles from './search-modal.styles.ts'
import { PlaceModel } from 'api/places/models/place.model.ts'
import { SVGImg } from 'ui/svg-img'
import Loupe from 'assets/loupe.svg'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { SanekEmptyView } from 'components/sanek-empty-view/sanek-empty-view.tsx'
import { SearchItemCard } from './search-item-card.tsx'

type TSearchModalProps = {
  places: PlaceModel[]
  onClose: VoidFunction
}

export const SearchModal: React.FC<TSearchModalProps> = ({
  places,
  onClose,
}) => {
  const [searchQuery, setSearchQuery] = useState('')

  const filteredPlaces = useMemo(() => {
    const query = searchQuery.trim().toLowerCase()
    return query
      ? places.filter((place) => place.name.toLowerCase().includes(query))
      : []
  }, [searchQuery, places])

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value)
    },
    []
  )

  const emptyData = useMemo(
    () => ({
      title:
        searchQuery === ''
          ? 'Чтобы что-то найти - надо это поискать'
          : 'Санёк не может найти такое заведение',
      description:
        searchQuery === ''
          ? 'Введи название места/заведения, и Санёк покажет всё, что у него есть'
          : 'Попробуй изменить запрос — и Санёк попытается найти нужное заведение снова',
    }),
    [searchQuery]
  )

  return (
    <>
      <div className={styles.inputContainer}>
        <input
          className={styles.input}
          autoComplete="off"
          name="searchField"
          placeholder="Введите название заведения"
          onChange={handleInputChange}
          value={searchQuery}
        />
        <SVGImg src={Loupe} />
      </div>

      <CellEmpty height={16} />
      <div className={styles.resultsContainer}>
        {filteredPlaces.length === 0 ? (
          <div className={styles.emptyBlock}>
            <SanekEmptyView {...emptyData} />
          </div>
        ) : (
          <>
            {filteredPlaces.map((place) => (
              <SearchItemCard key={place.id} place={place} onClick={onClose} />
            ))}
          </>
        )}
      </div>
    </>
  )
}
