import { JSX } from 'react'
import styles from './survey-roles.module.css'
import { ProgressiveImage } from '../../../components/progressive-image/progressive-image.tsx'
import { ImageModel } from '../../../api/models/image.model.ts'

type TSurveyTipProps = {
  /** Заголовок */
  title: string
  /** Путь для картинки */
  image: ImageModel
  /** Select карточки */
  isSelected: boolean
  /** Onclick в родителя */
  onClick: () => void
}

export function SurveyRoleTip({
  title,
  image,
  isSelected,
  onClick,
}: TSurveyTipProps): JSX.Element {
  return (
    <div
      className={`${styles.card} ${isSelected ? styles.selected : ''}`}
      onClick={onClick}
    >
      <ProgressiveImage image={image} alt="Роль" className={styles.cardImg} />
      <p className={styles.cardTitle}>{title}</p>
    </div>
  )
}
