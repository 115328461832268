import { JSX } from 'react'
import styles from './progress-bar.module.css'

type TProgressBarProps = {
  /** Процент заполнения прогресс-бара */
  percentage: number
}

export function ProgressBar({ percentage }: TProgressBarProps): JSX.Element {
  const isCompleted = percentage === 100

  return (
    <div className={styles.progressBarContainer}>
      <div
        className={styles.progressBarContent}
        style={{ width: `${percentage}%` }}
      />

      <span
        className={styles.progressBarLabel}
        style={
          isCompleted
            ? {
                position: 'absolute',
                left: 'calc(50% - 17px)',
              }
            : undefined
        }
      >
        {percentage}%
      </span>
    </div>
  )
}
