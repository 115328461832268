import { css } from '@emotion/css'
import { buttonReset } from 'styles/reset.style.ts'

export const subscriptionsPage = css`
  padding: 0 1.5rem 2rem;
`

export const tariffButtonContainer = css`
  width: 30%;
`

export const tariffButton = css`
  ${buttonReset};
  width: 100%;
  height: 56px;
  padding: 5px 13px;
  border-radius: 37px;
  background-color: #fff7bf;
`

export const tariffButtonSelected = css`
  background-color: #ffde00;
`

export const splash = css`
  width: 38px;
  position: relative;
  left: -5px;
  top: -7px;
`
