import { ChatMessageModel } from 'api/meeting-chat/models/chat-message.model.ts'
import { MessageAvatar } from 'components/message-avatar'
import { JSX } from 'react'
import { FlexContainer } from 'ui/flex-container'
import { MessageContent } from './message-content.tsx'

type TChatMessageItemProps = {
  message: ChatMessageModel
}

export function ChatMessageItem({ message }: TChatMessageItemProps): JSX.Element {
  return (
    <FlexContainer justify="space-around" wrap="nowrap">
      {message.sanekUser.avatar && (
        <MessageAvatar
          avatarImg={message.sanekUser.avatar.image.url}
          isOwnMessage={message.isOwnMessage}
        />
      )}

      <MessageContent
        text={message.text}
        link={message.link}
        photo={message.photo}
        isOwnMessage={message.isOwnMessage}
      />
    </FlexContainer>
  )
}
