import { JSX, PropsWithChildren } from 'react'
import styles from './radio.module.css'

type TRadioProps<T> = PropsWithChildren<{
  value: T;
  checked: boolean;
  isBig?: boolean;
  onChange: (value: T) => void;
}>

export function Radio<T extends string | number>({
  children,
  value,
  checked,
  isBig,
  onChange,
}: TRadioProps<T>): JSX.Element {
  const handleRadioChange = (): void => {
    onChange(value)
  }

  return (
    <label
      className={isBig ? styles.radioButtonBig : styles.radioButton}
      style={{ backgroundColor: checked ? '#ffde00' : '#FFFFFF' }}
    >
      {children}
      <input
        type="radio"
        value={value}
        checked={checked}
        onChange={handleRadioChange}
      />
    </label>
  )
}
