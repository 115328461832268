import { JSX } from 'react'
import * as styles from './category-card.style.ts'
import { observer } from 'mobx-react-lite'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { cx } from '@emotion/css'
import { MeetingCategoryModel } from 'api/meeting/models/meeting-category.model.ts'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums.ts'
import { meetingCreationStore } from 'store/meeting-creation.store.ts'

type TMeetingCategoryProps = {
  category: MeetingCategoryModel
}
export const CategoryCard = observer(
  ({ category }: TMeetingCategoryProps): JSX.Element => {
    const { selectedCategory } = meetingCreationStore

    const isSelected = selectedCategory?.code === category.code
    const handleSelectCategory = (): void => {
      meetingCreationStore.setSelectedCategory(category)
      void router.navigate(SanekRoute.PLACES)
    }

    return (
      <div
        className={cx(styles.categoryCard, { [styles.selected]: isSelected })}
        onClick={handleSelectCategory}
      >
        <img src={category.image.url} alt={category.text} />
        <CellEmpty height="12" />

        <p>{category.text}</p>
      </div>
    )
  }
)
