import { deserialize, JsonName } from 'tserialize'

export class BuySubscriptionResponse {
  @JsonName('payment_id')
  paymentId!: number

  static fromServer(data: object): BuySubscriptionResponse {
    return deserialize(data, BuySubscriptionResponse)
  }
}
