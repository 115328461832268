import { JSX } from 'react'
import * as styles from './stub-header.style.ts'
import VectorRight from 'assets/vector-right-stug.png'
import { CellEmpty } from 'ui/cell-empty.tsx'
import ManTall from 'assets/man_tall.png'
import VectorUnderSanek from 'assets/stug-under-sanek.png'

export const StubHeader = (): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.headerBlock}>
        <img src={VectorRight} alt="Стрелка" />
        <h1>САНЁК ЗОВЁТ</h1>
        <img src={VectorRight} alt="Стрелка" className={styles.rotatedVector} />
      </div>
      <CellEmpty height={54} />
      <div className={styles.imgWrapper}>
        <img src={ManTall} alt="Санёк" width="312" height="453" />
        <img
          src={VectorUnderSanek}
          alt="Черточка"
          className={styles.vectorUnder}
        />
      </div>
    </div>
  )
}
