import { css } from '@emotion/css'

export const sectionsWrapper = css`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1082px;
`

export const section = css`
  font-family: Copperplate, serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 183%;
  letter-spacing: -0.01em;
  text-align: center;
  color: #000;

  flex: 1 1 0;
  margin: 0 10px;
  cursor: pointer;

  border-bottom: 5px solid #000;
`

export const sectionActive = css`
  border-bottom: 5px solid #ffde00;
`
