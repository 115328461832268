import { observer } from 'mobx-react-lite'
import { Fragment, JSX, PropsWithChildren, useEffect } from 'react'
import { router } from 'router'
import { SURVEY_ROUTES } from 'router/router.const.ts'
import { SanekRoute } from 'router/router.enums.ts'
import { getSurveyRouteByUser } from 'utils/navigation.ts'
import { userStore } from './store/user.store.ts'
import { useMobileOrientation, isMobile } from 'react-device-detect'
import { AppService } from './api/services/app.service.ts'
import { sanekUsersTransport } from './api/sanek-users.transport.ts'
import { DesktopStub } from './pages/desktop-stub'

export const App = observer(({ children }: PropsWithChildren): JSX.Element => {
  const { user } = userStore
  const { isLandscape } = useMobileOrientation()

  useEffect(() => {
    if (!isMobile) {
      return
    }

    void userStore.getMe()
    AppService.initStoreData()
  }, [])

  const handleVisibilityChange = (): void => {
    const state = document.visibilityState

    if (state === 'visible' && isMobile) {
      AppService.initUserData()
    }
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    if (!isMobile || !user) {
      return
    }

    void sanekUsersTransport.setLastSeen(user.id)
  }, [user])

  useEffect(() => {
    if (!isMobile) {
      return
    }

    // @ts-expect-error
    if (!SURVEY_ROUTES.includes(location.pathname)) {
      return
    }

    if (user?.hasPassedSurvey) {
      const isNotificationsURl = location.pathname.includes('notifications')

      if (!isNotificationsURl) {
        void router.navigate(SanekRoute.MAIN, { replace: true })
        return
      }

      return
    }

    if (user?.surveyStep) {
      const surveyRoute = getSurveyRouteByUser(user)
      void router.navigate(surveyRoute, { replace: true })
    }
  }, [user])

  if (!isMobile) {
    return <DesktopStub />
  }

  if (isLandscape) {
    return <h2>Переверните телефон</h2>
  }

  return <Fragment>{children}</Fragment>
})
