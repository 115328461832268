/**
 * Метод делает query строку, просеивая пустые значения, возвращает query параметры.
 * Если корректных параметров нет, вернется пустая строка.
 *
 * @param records произвольный объект того, что нужно превратить в query параметры. nullish значения фильтруются
 * @param withQuestionMark признак того нужно ли отдавать ведущий знак вопроса. Не учитывается при пустом объекте.
 */
export function makeQueryString(
  records: Record<string, unknown>,
  withQuestionMark: boolean = true
): string {
  const filteredEntries = Object.entries(records).filter(
    (entry: [string, unknown]) => !!entry[1]
  )

  const searchParams = new URLSearchParams()
  filteredEntries.forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => searchParams.append(key, String(item)))
    } else {
      searchParams.set(key, String(value))
    }
  })

  if (searchParams.size === 0) {
    return ''
  }

  const stringParams = searchParams.toString()
  return withQuestionMark ? `?${stringParams}` : stringParams
}
