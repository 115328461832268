import { SurveyStep } from 'enums/user.enum.ts'
import { SanekRoute } from 'router/router.enums.ts'

export const SURVEY_ROUTES: Array<SanekRoute> = [
  SanekRoute.SURVEY_QUESTIONS,
  SanekRoute.SURVEY_ROLES,
  SanekRoute.SURVEY_COMMON,
  SanekRoute.SURVEY_CUSTOMIZATION,
] as const

export const SURVEY_STEP_TO_ROUTE_MAP: Record<
  SurveyStep,
  (typeof SURVEY_ROUTES)[number]
> = {
  [SurveyStep.QUESTIONS]: SanekRoute.SURVEY_QUESTIONS,
  [SurveyStep.ROLES]: SanekRoute.SURVEY_ROLES,
  [SurveyStep.COMMON]: SanekRoute.SURVEY_COMMON,
  [SurveyStep.CUSTOMIZATION]: SanekRoute.SURVEY_CUSTOMIZATION,
}
