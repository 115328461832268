import { JSX } from 'react'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { FlexContainer } from 'ui/flex-container'
import { Typography } from 'ui/typography'
import * as styles from './meeting-details-card.style.ts'

type TPlaceDetailsButtonProps = {
  icon: string
  text: string
  onClick: VoidFunction
}

export function PlaceDetailsButton({ icon, text, onClick }: TPlaceDetailsButtonProps): JSX.Element {
  return (
    <button className={styles.placeDetailsButton} onClick={onClick}>
      <FlexContainer direction="column" align="center">
        <img src={icon} alt="Иконка" className={styles.placeDetailsButtonImage} />
        <CellEmpty height="3" />
        <Typography size="10" weight="300">{text}</Typography>
      </FlexContainer>
    </button>
  )
}
