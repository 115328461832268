import { css } from '@emotion/css'
import { buttonReset } from 'styles/reset.style.ts'

export const resetButton = css`
  display: flex;
  align-items: center;
  ${buttonReset};
  background-color: transparent;

  p {
    color: #000;
    font-family: Copperplate, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.408px;

    padding-left: 2px;
  }
`
