import { DotLoading } from 'antd-mobile'
import cx from 'classnames'
import { CSSProperties, JSX } from 'react'
import * as styles from './circle-button.style.ts'

type TCircleButtonProps = {
  buttonStyle?: CSSProperties
  icon: string
  className?: string
  isLoading?: boolean
  onClick?: () => void
}
export const CircleButton = ({
  icon,
  buttonStyle,
  className,
  isLoading,
  onClick,
}: TCircleButtonProps): JSX.Element => {
  return (
    <button
      className={cx(styles.circleButton, className)}
      style={buttonStyle}
      onClick={onClick}
    >
      {isLoading ? <DotLoading /> : <img src={icon} alt="Кнопка" />}
    </button>
  )
}
