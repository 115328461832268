import PersonIcon from 'assets/person.svg'
import CalendarIcon from 'assets/calendar.svg'
import TargetIcon from 'assets/target.svg'

export type TRegisterBenefit = {
  id: number
  text: string
  icon: string
}
export const REGISTER_BENEFITS: Array<TRegisterBenefit> = [
  {
    id: 1,
    text: 'Встретить лучших друзей',
    icon: TargetIcon,
  },
  {
    id: 2,
    text: 'Посещать любимые заведения в дружеской компании',
    icon: PersonIcon,
  },
  {
    id: 3,
    text: 'Общаться и расширять свои интересы',
    icon: CalendarIcon,
  },
]
