import { ChatMessageModel } from 'api/meeting-chat/models/chat-message.model.ts'
import ChatHeaderLines from 'assets/decorations/chat/chat-header-lines.png'
import MessagesSeparator from 'assets/decorations/chat/messages-separator.png'
import { CenteredLoader } from 'components/common/centered-loader'
import { LayoutPage } from 'components/layout-page'
import { SanekEmptyView } from 'components/sanek-empty-view'
import { observer } from 'mobx-react-lite'
import { ChatMessageItem } from 'pages/meeting-chat/chat-messages/components'
import { Fragment, JSX, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums.ts'
import { meetingChatStore } from 'store/meeting-chat.store.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { SanekButton } from 'ui/sanek-button'
import { navigateBack } from 'utils/navigation.ts'
import * as styles from './chat-messages.style.ts'

export const ChatMessagesPage = observer((): JSX.Element => {
  const { meetingId } = useParams()
  const { isLoading, chat } = meetingChatStore
  const messages = chat?.messages || []
  const ownMessage = messages.find(
    (message: ChatMessageModel) => message.isOwnMessage
  )

  useEffect(() => {
    meetingChatStore.getChatMessages(Number(meetingId))
  }, [])

  if (isLoading) {
    return <CenteredLoader />
  }

  const handleCreateMessage = (): void => {
    void router.navigate(`${SanekRoute.CREATE_CHAT_MESSAGE}/${meetingId}`)
  }

  const handleEditMessage = (message: ChatMessageModel): void => {
    if (!chat || !ownMessage) {
      return
    }

    void router.navigate(
      `${SanekRoute.CREATE_CHAT_MESSAGE}/${meetingId}/${chat.id}`,
      {
        state: {
          message,
        },
      }
    )
  }

  return (
    <LayoutPage
      headerText="Кто где находится"
      footerNode={
        <div className={styles.chatMessagesFooter}>
          {!ownMessage ? (
            <SanekButton onClick={handleCreateMessage}>
              ДОБАВИТЬ ЛОКАЦИЮ
            </SanekButton>
          ) : (
            <SanekButton onClick={handleEditMessage.bind(null, ownMessage)}>
              ОБНОВИТЬ ЛОКАЦИЮ
            </SanekButton>
          )}
        </div>
      }
      backAction={navigateBack}
    >
      <img src={ChatHeaderLines} alt="" className={styles.chatHeaderImage} />
      <div className={styles.chatMessagesPage}>
        {messages.length > 0 ? (
          <>
            <CellEmpty height="20" />
            {messages.map((message: ChatMessageModel, index: number) => (
              <Fragment key={message.id}>
                <ChatMessageItem message={message} />
                {index !== messages.length - 1 && (
                  <img
                    src={MessagesSeparator}
                    alt=""
                    className={styles.chatMessagesSeparator}
                  />
                )}
              </Fragment>
            ))}
          </>
        ) : (
          <>
            <CellEmpty height="40" />
            <SanekEmptyView
              title="Объясни другу, где ты находишься"
              description="Прикрепи фото, поделись локацией или подробно опиши, где ты находишься, чтобы друг тебя нашел"
            />
          </>
        )}
      </div>
    </LayoutPage>
  )
})
