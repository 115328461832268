import { transport } from 'api/transport.ts'
import { CreateGlobalFeedbackRequest } from './models/create-global-feedback.request'

export class GlobalFeedbackTransport {
  createFeedback(feedbackText: string): Promise<void> {
    const request = new CreateGlobalFeedbackRequest({
      text: feedbackText,
    })

    return transport.post(
      `${import.meta.env.VITE_STRAPI_API}/api/add-global-feedback`,
      {
        data: request.toServer(),
      }
    )
  }
}

export const globalFeedbackTransport = new GlobalFeedbackTransport()
