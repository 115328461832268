import styles from './slider.module.css'

import { Navigation, Autoplay, Pagination, Mousewheel } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import SwiperCore from 'swiper'
import { CenteredLoader } from 'components/common/centered-loader'
import { storiesStore } from 'store/stories.store'
import { observer } from 'mobx-react-lite'
import { TStoryModel } from 'api/models/story.model'
import { getTime } from 'utils/date'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums'
import { ProgressiveImage } from '../progressive-image/progressive-image.tsx'

SwiperCore.use([Pagination, Autoplay, Mousewheel, Navigation])

export const sortStories = (
  stories: Array<TStoryModel>
): Array<TStoryModel> => {
  const fixedStories = stories
    .filter((story) => story.attributes.fixed)
    .sort(
      (a, b) =>
        getTime(a.attributes.createdAt) - getTime(b.attributes.createdAt)
    )

  const otherStories = stories
    .filter((story) => !story.attributes.fixed)
    .sort(
      (a, b) =>
        getTime(a.attributes.createdAt) - getTime(b.attributes.createdAt)
    )

  return [...fixedStories, ...otherStories]
}

export const Slider = observer((): JSX.Element => {
  const { stories, isLoading } = storiesStore

  const handleNavigate = (story: TStoryModel): void => {
    story.attributes.nested &&
      void router.navigate(`${SanekRoute.NEWS}/${story.id}`)
  }

  if (isLoading) {
    return <CenteredLoader />
  }

  const sortedStories = sortStories(stories)

  return (
    <Swiper
      loop={false}
      className={styles.slider}
      modules={[Navigation, Autoplay, Pagination, Mousewheel]}
      slidesPerView={1}
      spaceBetween={30}
      speed={500}
      mousewheel={false}
      scrollbar={{ draggable: true }}
      autoplay={{
        delay: 3000,
        disableOnInteraction: true,
      }}
      pagination={{
        renderBullet: (_, className) => {
          return '<span class="' + className + '">' + '</span>'
        },
      }}
    >
      {sortedStories &&
        sortedStories.map((story, index) => (
          <SwiperSlide key={index} onClick={() => handleNavigate(story)}>
            <div className={styles.card}>
              <ProgressiveImage
                image={story.attributes.image.data.attributes}
                alt="лого"
                className={styles.image}
              />
              <h4 className={styles.title}>{story?.attributes?.title}</h4>
              <p className={styles.description}>
                {story?.attributes?.description}
              </p>
            </div>
          </SwiperSlide>
        ))}
    </Swiper>
  )
})
