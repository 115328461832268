import { JSX } from 'react'
import * as styles from './place-category.style.ts'
import { FlexContainer } from 'ui/flex-container'
import { MeetingCategoryModel } from 'api/meeting/models/meeting-category.model.ts'
import { cx } from '@emotion/css'

type TPlaceCategoryProps = {
  category: MeetingCategoryModel
  onClick: (category: MeetingCategoryModel) => void
  isSelected: boolean
}
export const PlaceCategory = ({
  onClick,
  category,
  isSelected,
}: TPlaceCategoryProps): JSX.Element => {
  const handleClick = (): void => {
    onClick(category)
  }

  return (
    <div
      className={cx(styles.placeCategoryCard, {
        [styles.selected]: isSelected,
      })}
      onClick={handleClick}
    >
      <FlexContainer justify="space-between" align="center" wrap="nowrap">
        <p className={styles.textMin}>{category.textMin}</p>
        <img
          src={category.imageMin.url}
          alt={category.textMin}
          width="40"
          height="40"
        />
      </FlexContainer>
    </div>
  )
}
