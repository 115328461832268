import { CityModel } from 'api/cities/models/city.model.ts'
import { AxiosResponse } from 'axios'
import { transport } from '../transport.ts'

export class CitiesTransport {
  static getCities() {
    return transport
      .get(`${import.meta.env.VITE_STRAPI_API}/api/cities?populate=*`)
      .then(({ data }: AxiosResponse<Array<object>>) => {
        return data.map((item: object) => CityModel.fromServer(item))
      })
  }
}
