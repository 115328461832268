import { css } from '@emotion/css'
import { flexCenterContent } from 'styles/common.style.ts'

export const mainContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px 120px;
`

export const cardsContainer = css`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px 15px;
  width: 100%;
  box-sizing: border-box;
`

export const card = css`
  ${flexCenterContent};
  flex-direction: column;
  height: 169px;
  overflow: hidden;
`

export const cardImg = css`
  height: 160px;
  object-fit: contain;
`

export const bottomStand = css`
  width: 100%;
  height: 9px;
  border: 4px solid #dbdbdb;
  border-radius: 5px;
  background-color: #fff;
`

export const selected = css`
  border-color: #ffde00;
`
