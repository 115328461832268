import { css } from '@emotion/css'

export const flexCenterContent = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export function maxLines(count: number): string {
  return css`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${count};
    overflow: hidden;
  `
}

export const textCommon = css`
  color: #000;
  text-align: center;
  font-family: Copperplate, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`

export const policy = css`
  font-family: 'Copperplate', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: -0.04em;
  color: #000;

  a {
    color: #000;
    text-decoration: underline;
  }
`
