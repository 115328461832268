import styles from './meetings-block.module.css'
import Man from 'assets/man.png'
import { Fragment } from 'react'

export const Empty = () => {
  return (
    <Fragment>
      <div className={styles.body}>
        <p>Пока у тебя нет запланированных встреч</p>
      </div>
      <div className={styles.footer}>
        <div className={styles.imageContainer}>
          <img src={Man} className={styles.manImg} />
        </div>
        <span>- Создай свою встречу или посмотри приглашения</span>
      </div>
    </Fragment>
  )
}
