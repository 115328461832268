import { Footer, LayoutPage } from 'components/layout-page'
import { JSX, useEffect, useState } from 'react'
import { avatarsTransport } from 'api/avatars.transport'
import { SanekButton } from 'ui/sanek-button'
import { AvatarCard } from './avatar-card'
import { CenteredLoader } from 'components/common/centered-loader'
import { sanekUsersTransport } from 'api/sanek-users.transport.ts'
import { observer } from 'mobx-react-lite'
import { userStore } from 'store/user.store.ts'
import { USER_NOT_FOUND_ERROR } from 'const/common.const.ts'
import { navigateBack } from 'utils/navigation.ts'
import * as styles from './survey-avatars.slyle.ts'
import { useRollbar } from '@rollbar/react'
import { AvatarModel } from 'api/avatars/models/avatar.model.ts'

export const SurveyAvatars = observer((): JSX.Element => {
  const { user } = userStore
  const [avatars, setAvatars] = useState<Array<AvatarModel>>([])
  const [selectedAvatarId, setSelectedAvatarId] = useState<number>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const rollbar = useRollbar()

  const isButtonDisabled =
    !selectedAvatarId || selectedAvatarId === user?.avatar?.id

  useEffect(() => {
    setIsLoading(true)
    avatarsTransport
      .getAvatars()
      .then(setAvatars)
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    if (!user?.avatar?.id) {
      return
    }

    setSelectedAvatarId(user.avatar.id)
  }, [user?.avatar?.id])

  const handleAvatarClick = (roleId: number) => {
    setSelectedAvatarId(roleId)
  }

  function handleSave(): void {
    if (!selectedAvatarId) {
      return
    }

    if (!user) {
      rollbar.error(USER_NOT_FOUND_ERROR)
      return
    }

    setIsProcessing(true)

    // ToDo вернуть с бэка image и не запрашивать по-новой юзера, а проапдейтить стор
    void sanekUsersTransport.setAvatar(user.id, selectedAvatarId).then(() => {
      userStore
        .getMe()
        .then(navigateBack)
        .finally(() => setIsProcessing(false))
    })
  }

  if (isLoading) {
    return <CenteredLoader />
  }

  return (
    <LayoutPage
      headerText="Выбери аватар"
      footerNode={
        <Footer>
          <SanekButton
            disabled={isButtonDisabled}
            onClick={handleSave}
            isLoading={isProcessing}
          >
            СОХРАНИТЬ
          </SanekButton>
        </Footer>
      }
      backAction={navigateBack}
    >
      <div className={styles.mainContainer}>
        <div className={styles.cardsContainer}>
          {avatars.map((avatar) => (
            <AvatarCard
              key={avatar.id}
              image={avatar.image}
              isSelected={selectedAvatarId === avatar.id}
              onClick={() => handleAvatarClick(avatar.id)}
            />
          ))}
        </div>
      </div>
    </LayoutPage>
  )
})
