import { MeetingStatus } from 'const/statuses.ts'
import { JSX } from 'react'
import { ArchiveContent } from './archive-content'
import { CancelledContent } from './cancelled-content'
import { FoundContent } from './found-content'
import { MeetingInProgressContent } from './meeting-in-progress-content'
import { MeetingSoonContent } from './meeting-soon-content'
import { NotFoundContent } from './not-found-content'
import { RateMeetingContent } from './rate-meeting-content'
import { RecipientSearchContent } from './recipient-search-content'
import { WaitConfirmationContent } from './wait-confirmation-content'

type TRecipientContentProps = {
  status: MeetingStatus
}

export function RecipientContent({
  status,
}: TRecipientContentProps): JSX.Element {
  switch (status) {
  case MeetingStatus.SEARCH:
    return <RecipientSearchContent />
  case MeetingStatus.FOUND:
    return <FoundContent />
  case MeetingStatus.NOT_FOUND:
    return <NotFoundContent />
  case MeetingStatus.WAIT_CONFIRMATION:
    return <WaitConfirmationContent />
  case MeetingStatus.MEETING_SOON:
    return <MeetingSoonContent />
  case MeetingStatus.MEETING_IN_PROGRESS:
    return <MeetingInProgressContent />
  case MeetingStatus.RATE_MEETING:
    return <RateMeetingContent />
  case MeetingStatus.ARCHIVE:
    return <ArchiveContent />
  case MeetingStatus.CANCELLED:
    return <CancelledContent />
  default:
    return <></>
  }
}
