import * as styles from './push-notifications-popup.style.ts'

import { DotLoading, Toast } from 'antd-mobile'
import { observer } from 'mobx-react-lite'

import { FC, ReactNode, useEffect, useState } from 'react'

import { urlBase64ToUint8Array } from 'utils/urlB64ToUint8Array.ts'

import { sanekUsersTransport } from 'api/sanek-users.transport.ts'
import { userStore } from 'store/user.store.ts'

import Man from 'assets/man.png'

type Props = {
  title: string
  description: string
  content: ReactNode
}

enum PermissionStatus {
  GRANTED = 'granted',
  DENIED = 'denied',
  DEFAULT = 'default',
}

export const PushNotificationsPopup: FC<Props> = observer(
  ({ title, description, content }) => {
    const { user, setUser, isLoading: isLoadingUser } = userStore

    const [isLoading, setIsLoading] = useState(false)

    const [isShowPopup, setIsShowPopup] = useState(false)

    const closePopup = () => {
      setIsShowPopup(false)
    }

    const hasSameSubscription = (subscription?: PushSubscription | null) => {
      if (!user || !user.pushSubscription || !subscription) return false

      const currentSubscription = user.pushSubscription

      const preparedSubscription = subscription.toJSON()

      if (!preparedSubscription) return false

      return (
        preparedSubscription.keys?.auth === currentSubscription.auth &&
        preparedSubscription.endpoint === currentSubscription.endpoint &&
        preparedSubscription.keys?.p256dh === currentSubscription.p256dh
      )
    }

    useEffect(() => {
      const checkPermission = async () => {
        if (!user) return

        const serviceWorkerRegistration =
          await navigator.serviceWorker.getRegistration()

        const subscription =
          await serviceWorkerRegistration?.pushManager.getSubscription()

        const sameSubscription = hasSameSubscription(subscription)

        if (!sameSubscription) {
          setIsShowPopup(true)
        }
      }

      checkPermission()
    }, [user])

    const enableNotifications = async () => {
      console.log(user)

      if (!user) return

      const permission = await Notification.requestPermission()
      console.log(permission, '<=== permission')

      if (permission === PermissionStatus.DENIED) {
        setIsShowPopup(false)
        Toast.show('Включите уведомления в настройках телефона')
      }

      setIsLoading(true)

      const serviceWorkerRegistration =
        await navigator.serviceWorker.getRegistration()
      console.log(serviceWorkerRegistration, '<== serviceWorkerRegistration')
      const options = {
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(
          import.meta.env.VITE_WEB_PUSH_PUBLIC_KEY
        ),
      }
      console.log(options)

      if (!serviceWorkerRegistration) {
        setIsLoading(false)
        setIsShowPopup(false)
        return
      }

      const subscription =
        await serviceWorkerRegistration?.pushManager.subscribe(options)
      console.log(subscription, '<== subscription')

      try {
        const updatedUser = await sanekUsersTransport.savePushSubscription({
          sanekUserId: user.id,
          pushSubscription: subscription,
        })

        setUser(updatedUser)
      } catch (e) {
        Toast.show('Ошибка подключения нотификаций, обратитесь в поддержку')
      } finally {
        setIsLoading(false)
        setIsShowPopup(false)
      }
    }

    if (!isShowPopup || isLoadingUser) return null

    return (
      <div className={styles.popupOverlay}>
        <div className={styles.popup}>
          <div className={styles.image}>
            <img src={Man} height="90px" width="90px" />
          </div>
          <div className={styles.title}>{title}</div>
          <div className={styles.divider} />
          <div className={styles.description}>{description}</div>
          <div className={styles.content}>{content}</div>

          <div className={styles.actions}>
            <button
              onClick={closePopup}
              className={styles.cancel}
              disabled={isLoading}
            >
              Отказаться
            </button>
            <button
              onClick={enableNotifications}
              className={styles.confirm}
              disabled={isLoading}
            >
              {isLoading ? <DotLoading /> : 'Разрешить'}
            </button>
          </div>
        </div>
      </div>
    )
  }
)
