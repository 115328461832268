import { css } from '@emotion/css'
import ChatBg from 'assets/decorations/meeting-details/chat-bg.jpg'
import { flexCenterContent } from 'styles/common.style.ts'
import { buttonReset } from 'styles/reset.style.ts'

export const chatBlock = css`
  ${flexCenterContent};
  height: 141px;
  background: url(${ChatBg}) center/contain no-repeat;
`

export const chatButton = css`
  ${buttonReset};
  width: 173px;
  height: 58px;
  border: 3px solid #e2e2e2;
  border-radius: 40px;
  box-shadow: 2px 3px 0 0 rgba(0, 0, 0, 0.25);
  background: #f8f8f8;
`
