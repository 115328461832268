import { JSX, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import * as antdStyles from 'styles/antd-mobile.style.ts'
import { CenteredInput } from 'ui/centered-input'
import { DatePicker } from 'antd-mobile'
import { PickerDate } from 'antd-mobile/es/components/date-picker/util'
import { noop } from 'antd-mobile/es/utils/noop'
import { renderPickerLabel } from 'utils/date.tsx'

type TDateOfBirthPickerProps = {
  value: Dayjs | null
  onChange: (value: Dayjs | null) => void
}

const MIN_DATE: PickerDate = new Date(1970, 0, 1)
const MAX_DATE: PickerDate = dayjs().subtract(18, 'year').toDate()

export function DateOfBirthPicker({
  value,
  onChange,
}: TDateOfBirthPickerProps): JSX.Element {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const handleDateChange = (date: PickerDate) => {
    onChange(dayjs(date))
  }

  return (
    <>
      <DatePicker
        className={antdStyles.datePicker}
        visible={isVisible}
        onClose={setIsVisible.bind(null, false)}
        min={MIN_DATE}
        max={MAX_DATE}
        onConfirm={handleDateChange}
        value={value?.toDate()}
        cancelText="Отмена"
        renderLabel={renderPickerLabel}
        confirmText="Подтвердить"
      />
      <CenteredInput
        readOnly
        isBig
        placeholder="Выберите дату рождения"
        value={value?.format('D MMMM YYYYг.')}
        onChange={noop}
        onClick={setIsVisible.bind(null, true)}
      />
    </>
  )
}
