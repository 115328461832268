import { CSSProperties, JSX } from 'react'

type TSVGImgProps = {
  src: string
  className?: string
  style?: CSSProperties
  width?: number | string
  height?: number | string
}
export const SVGImg = ({
  src,
  className,
  style,
  width,
  height,
}: TSVGImgProps): JSX.Element => {
  return (
    <object
      data={src}
      type="image/svg+xml"
      className={className}
      style={style}
      width={width}
      height={height}
    />
  )
}
