import { CellEmpty } from 'ui/cell-empty.tsx'
import * as styles from './stub-main.style.ts'

import Close from 'assets/close-stug.png'
import Stroke from 'assets/stug-stroke.svg'
import HighLightLeft from 'assets/stug-highlight-left.svg'
import LeftBottomVortex from 'assets/stug-left-bottom.svg'
import CheckMark from 'assets/stug-check-mark.svg'
import VortexRight from 'assets/stug-vortex-right.svg'
import { JSX } from 'react'

export const StubMain = (): JSX.Element => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.infoBlock}>
        <img src={HighLightLeft} />
        <h2>Ой, Санёк увидел, что ты перешел по ссылке с компьютера</h2>
        <img
          src={HighLightLeft}
          alt="Стрелка"
          className={styles.rotatedVector}
        />
      </div>
      <CellEmpty height={75} />
      <div className={styles.closeContainer}>
        <img src={Close} alt="Крест" />
        <img src={Close} alt="Крест" />
        <img src={Close} alt="Крест" />
      </div>
      <CellEmpty height={63} />
      <p>Приложение “Санёк Зовёт” работает только с мобильных устройств</p>
      <CellEmpty height={112} />
      <div className={styles.guideContainer}>
        <h3>КАК УСТАНОВИТЬ ПРИЛОЖЕНИЕ НА ТЕЛЕФОН?</h3>
        <img src={Stroke} className={styles.stroke} />
      </div>
      <CellEmpty height={92} />
      <div className={styles.footer}>
        <div className={styles.footerStroke}>
          <div className={styles.circle}>1</div>
          <p>Открыть ссылку на web-приложение с мобильного устройства</p>
        </div>

        <a href="https://app.sanek.io">app.sanek.io</a>

        <div className={styles.footerStroke}>
          <div className={styles.circle}>2</div>
          <p>Сохранить интернет страницу на главном экране устройства</p>
        </div>

        <div className={styles.footerStroke}>
          <div className={styles.circle}>3</div>
          <p>Готово! Теперь вы можете использовать приложения</p>
        </div>

        <img src={LeftBottomVortex} className={styles.leftBottomVortex} />
        <img src={CheckMark} className={styles.checkMark} />
        <img src={VortexRight} className={styles.vortexRight} />
      </div>
    </div>
  )
}
