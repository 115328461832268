import { JSX, PropsWithChildren } from 'react'
import * as styles from './places-subcategories.style.ts'

type TPlacesSubcategoryItemProps<T> = PropsWithChildren<{
  value: T
  checked: boolean
  onChange: (value: T) => void
}>

export function PlacesSubcategoryItem<T extends number | string>({
  children,
  value,
  checked,
  onChange,
}: TPlacesSubcategoryItemProps<T>): JSX.Element {
  const handleRadioChange = (): void => {
    onChange(value)
  }

  return (
    <div
      className={styles.item}
      style={{ backgroundColor: checked ? '#9fcbdc' : '#FFFFFF' }}
      onClick={handleRadioChange}
    >
      {children}
    </div>
  )
}
