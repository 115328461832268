import ManTall from 'assets/man_tall_attention.png'
import SanekButtonVortex from 'assets/sanek-button-vortex.svg'
import SpraySvg from 'assets/spray-success-page.svg'
import SuccessSvg from 'assets/success-page.svg'
import { LayoutPage } from 'components/layout-page'
import { useScrollToTop } from 'hooks/useScrollToTop.ts'
import { JSX } from 'react'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums'
import { SanekButton } from 'ui/sanek-button'
import * as styles from './flow-result-page.style.ts'

type TFlowResultPageProps = {
  headerText?: string
  text: string
  description?: string
}

export const FlowResultPage = ({
  headerText,
  text,
  description,
}: TFlowResultPageProps): JSX.Element => {
  useScrollToTop()

  const handleNavigateToMainPage = (): void => {
    void router.navigate(SanekRoute.MAIN)
  }

  return (
    <LayoutPage headerText={headerText}>
      <div className={styles.mainContainer}>
        <img src={SuccessSvg} alt="Успех!" className={styles.successSvg} />
        <h2 className={styles.info}>{text}</h2>
        {description && <div className={styles.description}>{description}</div>}
        <div className={styles.footer}>
          <img
            src={ManTall}
            alt="Санёк"
            width="340px"
            height="340px"
            className={styles.sanekPng}
          />
          <img src={SpraySvg} height="534" className={styles.spraySvg} />
          <div className={styles.buttonBlock}>
            <SanekButton onClick={handleNavigateToMainPage}>
              НА ГЛАВНУЮ
            </SanekButton>
            <img
              src={SanekButtonVortex}
              alt=""
              className={styles.sanekButtonVortex}
            />
          </div>
        </div>
      </div>
    </LayoutPage>
  )
}
