import ManImg from 'assets/man.png'
import PhoneAccept from 'assets/phone-accept.png'
import PhoneDecline from 'assets/phone-decline.png'
import { JSX } from 'react'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums.ts'
import { CellEmpty } from 'ui/cell-empty'
import { IntroButton } from './intro-button'
import style from './intro-lets-talk.module.css'
import RaySvg from 'assets/ray.svg'
import VortexSvg from 'assets/vortex.svg'

export function IntroLetsTalkPage(): JSX.Element {
  return (
    <div className={style.letsTalkPage}>
      <div className={style.infoWrapper}>
        <h2 className={style.headerText}>Санёк</h2>
        <img src={RaySvg} className={style.headerSvg} />
        <CellEmpty height={30} />

        <div className={style.imageWrapper}>
          <img className={style.image} src={ManImg} height="230" width="230" />
        </div>
        <CellEmpty height={30} />

        <img src={VortexSvg} className={style.centerSvg} />

        <p className={style.description}>Давай поговорим по&nbsp;душам</p>
      </div>

      <div className={style.buttonsWrapper}>
        <IntroButton src={PhoneDecline} onClick={handleDeclineClick}>
          Отклонить
        </IntroButton>
        <IntroButton src={PhoneAccept} onClick={handleAcceptClick}>
          Ответить
        </IntroButton>
      </div>
    </div>
  )
}

function handleDeclineClick(): void {
  void router.navigate(SanekRoute.INTRO_SANEK_GREETING, {
    state: { isAcceptedCall: false },
  })
}

function handleAcceptClick(): void {
  void router.navigate(SanekRoute.INTRO_SANEK_GREETING, {
    state: { isAcceptedCall: true },
  })
}
