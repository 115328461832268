import { transport } from 'api/transport.ts'
import { AxiosResponse } from 'axios'
import { BuySubscriptionResponse } from './responses/buy-subscription.response.ts'

class SubscriptionTransport {
  buySubscription(tariffId: number): Promise<BuySubscriptionResponse> {
    return transport
      .post(
        `${import.meta.env.VITE_STRAPI_API}/api/subscription/buy-subscription`,
        {
          data: {
            tariff_id: tariffId,
          },
        }
      )
      .then(({ data }: AxiosResponse<object>) =>
        BuySubscriptionResponse.fromServer(data)
      )
  }
}

export const subscriptionTransport = new SubscriptionTransport()
