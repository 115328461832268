import { css } from '@emotion/css'

export function getMessageContentStyle(isOwnMessage: boolean): string {
  return css`
    width: 224px;
    border-radius: 16px;
    background-color: ${isOwnMessage ? '#ffde00' : '#9fcbdc'};
    padding: 13px 12px;
  `
}
