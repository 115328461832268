import { AxiosResponse } from 'axios'
import { transport } from './transport.ts'
import { UserLevelModel } from './models/user-level.model.ts'

export class AllUserLevels {
  getLevels() {
    return transport
      .get(`${import.meta.env.VITE_STRAPI_API}/api/sanek-user-levels`)
      .then(({ data }: AxiosResponse) =>
        data.map((el: object) => UserLevelModel.fromServer(el))
      )
  }
}

export const userLevelTransport = new AllUserLevels()
