import React, { useEffect, useState } from 'react'
import * as styles from './invitations-list.style'
import cx from 'classnames'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { CenteredLoader } from 'components/common/centered-loader'
import { userInvitationsStore } from 'store/invitations.store'
import { LayoutPage } from 'components/layout-page'
import { MeetingCard } from 'components/meeting-card'
import SettingsSvg from 'assets/settings.svg'
import { SanekRoute } from 'router/router.enums'
import { InvitationSortType } from 'enums/invitations-sort-types.enum'
import { SanekEmptyView } from 'components/sanek-empty-view'
import { UserMeetingModel } from 'api/user-meetings/models/user-meeting.model.ts'
import { SeparatorBlue } from 'components/common/separator'

export const InvitationsListPage = observer(() => {
  const navigate = useNavigate()
  const { isLoading: isUserInvitationsLoading, invitations } =
    userInvitationsStore

  const [sortType, setSortType] = useState<InvitationSortType>(
    InvitationSortType.NEWEST
  )

  const filtersCount = userInvitationsStore.activeFiltersCount

  useEffect(() => {
    userInvitationsStore.getInvitations(sortType)
  }, [sortType])

  const handleSetClosest = (): void => {
    setSortType(InvitationSortType.CLOSEST)
  }

  const handleSetNewest = (): void => {
    setSortType(InvitationSortType.NEWEST)
  }

  const handleSettingsClick = () => {
    navigate(`${SanekRoute.INVITATIONS_FILTERS}`)
  }

  const handleGoMainClick = () => {
    navigate(`${SanekRoute.MAIN}`)
  }

  if (isUserInvitationsLoading) {
    return <CenteredLoader />
  }

  return (
    <LayoutPage headerText="Приглашения" backAction={handleGoMainClick}>
      <div className={styles.header}>
        <div className={styles.sortingBlock}>
          <button
            className={cx(styles.button, {
              [styles.active]: sortType === InvitationSortType.CLOSEST,
            })}
            onClick={handleSetClosest}
          >
            Ближайшие
          </button>
          <button
            className={cx(styles.button, {
              [styles.active]: sortType === InvitationSortType.NEWEST,
            })}
            onClick={handleSetNewest}
          >
            Новое
          </button>
        </div>

        <div className={styles.settingsBlock}>
          <img
            src={SettingsSvg}
            className={styles.settingsSvg}
            onClick={handleSettingsClick}
          />
          {filtersCount > 0 && (
            <span className={styles.filtersCount}>{filtersCount}</span>
          )}
        </div>
      </div>
      <div className={styles.meetingsContainer}>
        {invitations.length === 0 ? (
          <SanekEmptyView
            title="Упс, Санёк ничего не нашел"
            description="Здесь пока нет приглашений."
          />
        ) : (
          invitations.map((invitation: UserMeetingModel) => (
            <React.Fragment key={invitation.id}>
              <MeetingCard meeting={invitation} />
              <SeparatorBlue />
            </React.Fragment>
          ))
        )}
      </div>
    </LayoutPage>
  )
})
