import { deserialize, JsonName, JsonNameReadonly } from 'tserialize'
import {
  deserializeDate,
  deserializeTime,
} from '../deserializers/common.deserializers.ts'
import dayjs, { Dayjs } from 'dayjs'
import { MEETING_TIME_TEMPLATE, STRAPI_DATE_FORMAT } from 'const/date.const.ts'

export class TimeslotModel {
  @JsonNameReadonly()
  id!: number

  @JsonName(void 0, void 0, deserializeDate)
  date!: Dayjs

  @JsonName('time_from', dayjs, deserializeTime)
  from!: Dayjs

  @JsonName('time_to', dayjs, deserializeTime)
  to!: Dayjs

  static fromServer(data: object): TimeslotModel {
    return deserialize(data, TimeslotModel)
  }

  get timeFrom(): Dayjs {
    const date = this.date.format(STRAPI_DATE_FORMAT)
    const time = this.from.format(MEETING_TIME_TEMPLATE)
    return dayjs(`${date} ${time}`)
  }

  get timeTo(): Dayjs {
    const date = this.date.format(STRAPI_DATE_FORMAT)
    const time = this.to.format(MEETING_TIME_TEMPLATE)
    return dayjs(`${date} ${time}`)
  }
}
