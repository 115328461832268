import { css } from '@emotion/css'
import { flexCenterContent } from 'styles/common.style.ts'
import { buttonReset } from 'styles/reset.style.ts'

export const placeDetailsPage = css`
  padding: 0.5rem 2rem 6rem;
`

export const placeDetailsCard = css`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 14px 20px;
  border-radius: 16px;
  border: 4px solid #f2f2f2;
  background: #f6f6f6;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.25);
`

export const priceSegment = css`
  position: absolute;
  top: 4px;
  left: 20px;
`

export const hrImg = css`
  display: block;
  width: 100%;
  margin: 5px 0;
`

export const copyButtonWrapper = css`
  width: 28px;
  height: 28px;
  position: relative;
`
export const copyButton = css`
  ${buttonReset};
  width: 28px;
  height: 28px;
  background-color: transparent;
  position: absolute;
`

export const geolocationButton = css`
  ${buttonReset};
  ${flexCenterContent};
  width: 45%;
  height: 49px;
  border-radius: 24px;
  border: 2px solid #f2f2f2;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
  background-color: #ffde00;
  padding: 11px 15px;
`

export const geolocationImage = css`
  width: 16px;
  height: 26px;
  margin-right: 11px;
`

export function getMetroLetterStyle(color: string): string {
  return css`
    margin-right: 5px;
    color: ${color};
  `
}

export const detailsDivider = css`
  display: block;
  width: 100%;
  height: 5px;
`

export const websiteButton = css`
  ${buttonReset};
  width: 100%;
  height: 31px;
  border-radius: 16px;
  border: 2px solid #f2f2f2;
  background: #9fcbdc;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
  color: #384043;
  font-family: 'Chalkboard SE', serif;
  font-size: 14px;
  font-weight: 400;
`

export const imagesContainer = css`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export function getImageTileStyle(size: number): string {
  return css`
    width: ${size}px;
    height: ${size}px;
    border-radius: 16px;
    object-fit: cover;
    margin: 10px 0;
  `
}

export const placeCategoryLabel = css`
  max-width: 50%;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  text-align: center;
`

export const buttonSaveContainer = css`
  z-index: 10;
  position: fixed;
  bottom: 40px;
  left: 30px;
  right: 30px;
`

export const placeFavouriteButton = css`
  ${buttonReset};
  position: absolute;
  right: 12px;
  top: 12px;
  width: 29px;
  height: 24px;
  margin-left: 10px;
  background-color: transparent;
`
