import { CreateMessageRequest } from 'api/meeting-chat/models/create-message.request.ts'
import { GetChatMessagesResponse } from 'api/meeting-chat/models/get-chat-messages.response.ts'
import { AxiosResponse } from 'axios'
import { transport } from '../transport.ts'

class MeetingChatTransport {
  getMeetingChatMessages(meetingId: number): Promise<GetChatMessagesResponse | null> {
    return transport
      .post(`${import.meta.env.VITE_STRAPI_API}/api/get-chat`, {
        data: {
          id: meetingId,
        }
      })
      .then(({ data }: AxiosResponse<{ chat: object | null }>) => {
        if (!data.chat) {
          return null
        }
        return GetChatMessagesResponse.fromServer(data.chat)
      })
  }

  createChatMessage(request: CreateMessageRequest): Promise<void> {
    return transport
      .post(`${import.meta.env.VITE_STRAPI_API}/api/meeting-chats`, {
        data: request.toServer()
      })
  }

  editChatMessage(chatId: number, request: CreateMessageRequest): Promise<void> {
    return transport
      .put(`${import.meta.env.VITE_STRAPI_API}/api/meeting-chats/${chatId}`, {
        data: request.toServer()
      })
  }
}

export const meetingChatTransport = new MeetingChatTransport()
