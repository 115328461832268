import { JSX } from 'react'
import * as styles from './open-map-button.styles'
import { navToMapPage } from 'utils/navigation.ts'
import mapIcon from 'assets/map-icon.svg'

export function OpenMapButton(): JSX.Element {
  const handleChoosePlace = (): void => {
    navToMapPage()
  }

  return (
    <div className={styles.container} onClick={handleChoosePlace}>
      <img className={styles.icon} src={mapIcon} alt="category-image" />
      <div className={styles.content}>Посмотреть на карте</div>
    </div>
  )
}
