import { PriceSegment } from 'enums/places.enums.ts';
import { TRadioOption } from 'ui/radio';

export const PRICE_SEGMENT_LABEL_MAP: Record<PriceSegment, string> = {
  [PriceSegment.BUDGET]: '₽',
  [PriceSegment.MEDIUM]: '₽₽',
  [PriceSegment.EXPENSIVE]: '₽₽₽'
}

export const PRICE_SEGMENT_OPTIONS: Array<TRadioOption<PriceSegment>> = Object.values(PriceSegment)
  .map((priceSegment: PriceSegment) => ({
    value: priceSegment,
    label: PRICE_SEGMENT_LABEL_MAP[priceSegment]
  }))
