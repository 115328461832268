import { deserialize, JsonName, JsonNameReadonly, JsonStruct } from 'tserialize'

import { UserGender } from 'enums/user.enum.ts'
import { ImageModel } from 'api/models/image.model.ts'
import { TAvatarModel } from 'api/models/avatar.model.ts'

export class AvatarModel {
  @JsonNameReadonly()
  id!: number

  @JsonNameReadonly()
  gender!: UserGender

  @JsonName()
  name: string = ''

  @JsonStruct(ImageModel)
  image!: ImageModel

  static fromServer(data: TAvatarModel): AvatarModel {
    const dataToDeserialize = {
      id: data.id,
      name: data.attributes.name,
      gender: data.attributes.gender,
      image: data.attributes.image.data.attributes,
    }
    return deserialize(dataToDeserialize, AvatarModel)
  }
}
