import { JsonNameReadonly } from 'tserialize'

import emptyThumbnailURL from 'assets/sanek-empty.png'

export class ImageModel {
  @JsonNameReadonly()
  alternativeText: string | null = null

  @JsonNameReadonly()
  caption: string | null = null

  @JsonNameReadonly()
  ext: string = ''

  @JsonNameReadonly()
  formats: { thumbnail: { url: string } } = {
    thumbnail: { url: emptyThumbnailURL },
  }

  @JsonNameReadonly()
  hash: string = ''

  @JsonNameReadonly()
  width: number = 0

  @JsonNameReadonly()
  height: number = 0

  @JsonNameReadonly()
  size: number = 0

  @JsonNameReadonly()
  name: string = ''

  @JsonNameReadonly()
  mime: string = ''

  @JsonNameReadonly()
  url: string = ''
}
