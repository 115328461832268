import { JSX } from 'react'
import * as styles from './mood-card.style.ts'
import { observer } from 'mobx-react-lite'
import { cx } from '@emotion/css'
import { MeetingMoodModel } from 'api/meeting/models/meeting-mood.model.ts'
import { meetingCreationStore } from 'store/meeting-creation.store.ts'

type TMoodCardProps = {
  mood: MeetingMoodModel
}
export const MoodCard = observer(({ mood }: TMoodCardProps): JSX.Element => {
  const { selectedMood } = meetingCreationStore

  const isSelected = selectedMood?.code === mood.code
  const handleSelectMood = (): void => {
    meetingCreationStore.setSelectedMood(mood)
  }
  return (
    <div
      className={cx(styles.moodCard, { [styles.selected]: isSelected })}
      onClick={handleSelectMood}
    >
      <img src={mood.image.url} alt={mood.text} />
      <p>{mood.text}</p>
    </div>
  )
})
