import { css } from '@emotion/css'
import { flexCenterContent } from 'styles/common.style.ts'
import { buttonReset } from 'styles/reset.style.ts'

export const createMessagesPage = css`
  padding: 0 1.5rem 2rem;
`

export const createMessagesFooter = css`
  padding: 2rem 1.5rem;
`

export const formControl = css`
  border: 2px solid #f2f2f2;
  border-radius: 11px;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.25);
  background: #f6f6f6;
  padding: 11px;

  input,
  textarea {
    font-family: Copperplate, serif;
    font-size: 12px;
    font-weight: 300;
    color: black;
    ::placeholder {
      color: black;
    }
  }
`

export const addPhotoButton = css`
  ${buttonReset};
  ${flexCenterContent};
  width: 209px;
  height: 38px;
  border: 2px solid #f2f2f2;
  border-radius: 19px;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.25);
  background: #9fcbdc;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
`

export const photoPreviewContainer = css`
  position: relative;
  width: 60%;
`

export const deleteImageButton = css`
  ${buttonReset};
  width: 24px;
  height: 24px;
  position: absolute;
  top: -12px;
  right: -12px;
  background-color: transparent;
  border-radius: 50%;
`

export const required = css`
  &:before {
    white-space: nowrap;
    content: '* ';
    color: red;
  }
`
