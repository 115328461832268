import { css } from '@emotion/css'

export const cameraWrapper = css`
  position: relative;
`

export const mask = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`
export const captureBtn = css`
  position: absolute;
  bottom: 50px;
  left: 0;
`

export const errorWrapper = css`
  padding: 0 1.5rem;
  text-align: center;

  p {
    color: #000;
    font-family: 'Chalkboard SE', serif;
    font-size: 16px;
    line-height: normal;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.408px;
  }
`
