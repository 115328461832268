import { CreatePlaceRequest } from 'api/places/models/create-place.request.ts'
import { PlaceModel } from 'api/places/models/place.model.ts'
import { TPlacesFilters } from 'api/places/models/places.filters.ts';
import { transport } from 'api/transport.ts';
import { AxiosResponse } from 'axios'
import { PlaceSorting } from 'enums/places.enums.ts'
import { makeQueryString } from 'utils/query.ts';

export class PlacesTransport {
  getPlaces(filters: TPlacesFilters, sorting: PlaceSorting): Promise<Array<PlaceModel>> {
    const query = makeQueryString({
      ...filters,
      sorting
    });

    return transport
      .get(`${import.meta.env.VITE_STRAPI_API}/api/places${query}`)
      .then(({ data }: AxiosResponse<Array<object>>) => {
        return data.map((item: object) => PlaceModel.fromServer(item))
      })
  }

  getPlace(placeId: string | number): Promise<PlaceModel> {
    return transport
      .get(`${import.meta.env.VITE_STRAPI_API}/api/places/${placeId}`)
      .then(({ data }: AxiosResponse<object>) => {
        return PlaceModel.fromServer(data)
      })
  }

  createPlace(request: CreatePlaceRequest): Promise<PlaceModel> {
    return transport
      .post(`${import.meta.env.VITE_STRAPI_API}/api/places`, {
        data: request.toServer()
      })
      .then(({ data }: AxiosResponse<object>) => {
        return PlaceModel.fromServer(data)
      })
  }
}

export const placesTransport = new PlacesTransport()
