import Man from 'assets/man.png'
import { MessageAvatar } from 'components/message-avatar'
import { JSX } from 'react'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { FlexContainer } from 'ui/flex-container'
import { Typography } from 'ui/typography'
import * as styles from './not-found-content.style.ts'

const STEPS = [
  'Попробуй добавить больше вариантов времени для встречи',
  'Поищи уютное место из каталога для дружеской атмосферы',
  'Обязательно пробуй еще, друзья Санька хотят с тобой встретиться!',
]

export function NotFoundContent(): JSX.Element {
  return (
    <>
      <Typography family="Chalkboard" size="24" align="center">
        Санёк не&nbsp;смог найти друга
      </Typography>
      <CellEmpty height="25" />

      <FlexContainer align="center" wrap="nowrap" gap="15px">
        <MessageAvatar avatarImg={Man} isOwnMessage={false} />
        <div style={{ flex: 1 }}>
          <Typography family="Chalkboard" size="14">
            Чтобы мне было проще найти тебе друга, попробуй изменить условия
            встречи
          </Typography>
        </div>
      </FlexContainer>
      <CellEmpty height="25" />

      <div className={styles.stepsContainer}>
        {STEPS.map((step: string) => (
          <p className={styles.step}>{step}</p>
        ))}
      </div>
    </>
  )
}
