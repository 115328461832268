import {
  TSurveyQuestionAnswer,
  TSurveyQuestionModel,
} from 'api/models/survey-question.model.ts'
import {
  getSurveyQuestions,
  saveSurveyQuestions,
} from 'api/survey-questions.transport.ts'
import { USER_NOT_FOUND_ERROR } from 'const/common.const.ts'
import { SurveyTip } from 'pages/survey/survey-questions/survey-tip.tsx'
import { Fragment, JSX, useEffect, useState } from 'react'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums.ts'
import { userStore } from 'store/user.store.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { ProgressBar } from 'ui/progress-bar'
import { RadioGroup } from 'ui/radio'
import { SanekButton } from 'ui/sanek-button'
import stylesCommon from '../survey.module.css'
import {
  QUESTIONS_OPTIONS,
  SURVEY_TIPS,
  TSurveyTipData,
} from './survey-questions.const.ts'
import styles from './survey-questions.module.css'
import { makeAnswersEmptyMap } from './survey-questions.utils.ts'
import { CenteredLoader } from 'components/common/centered-loader'
import { useRollbar } from '@rollbar/react'

// TODO перевезти на стор или что-то такое
export function SurveyQuestions(): JSX.Element {
  const [questions, setQuestions] = useState<Array<TSurveyQuestionModel>>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const [answers, setAnswers] = useState<Record<string, number | null>>({})
  const hasEmptyAnswer = Object.values(answers).some(
    (answer: number | null) => answer === null
  )

  const { user } = userStore

  const rollbar = useRollbar()

  useEffect(() => {
    setIsLoading(true)
    void getSurveyQuestions()
      .then((questions: Array<TSurveyQuestionModel>) => {
        setQuestions(questions)
        setAnswers(makeAnswersEmptyMap(questions))
      })
      .finally(() => setIsLoading(false))
  }, [])

  const handleQuestionChange = (questionCode: string, answer: number): void => {
    const updatedAnswers = { ...answers }
    updatedAnswers[questionCode] = answer

    setAnswers(updatedAnswers)
  }

  const handleSaveQuestions = (): void => {
    if (!user) {
      rollbar.error(USER_NOT_FOUND_ERROR)
      return
    }

    setIsProcessing(true)

    const result: Array<TSurveyQuestionAnswer> = questions.map(
      (question: TSurveyQuestionModel) => ({
        survey_question: question.id,
        value: answers[question.code] || 0,
      })
    )

    saveSurveyQuestions(user, result)
      .then(() => {
        void router.navigate(SanekRoute.SURVEY_ROLES, {
          replace: true,
        })
      })
      .catch((error) => {
        rollbar.error('Ошибка при создании Question Survey:', error)
      })
      .finally(() => setIsProcessing(false))
  }

  if (isLoading) {
    return <CenteredLoader />
  }

  return (
    <div>
      <div className={stylesCommon.surveyHeader}>
        <h3 className={stylesCommon.surveyHeaderTitle}>
          Дружба с&nbsp;Саньком
        </h3>
        <CellEmpty height="7" />

        <ProgressBar percentage={10} />
        <CellEmpty height="20" />

        <h3 className={stylesCommon.surveyHeaderQuestion}>
          Что больше про тебя?
        </h3>
        <CellEmpty height="9" />

        <div className={styles.surveyTips}>
          {SURVEY_TIPS.map((tip: TSurveyTipData, index: number) => (
            <SurveyTip key={index} {...tip} />
          ))}
        </div>
        <CellEmpty height="13" />
      </div>

      <div className={styles.surveyQuestionsPage}>
        {questions.map((question: TSurveyQuestionModel) => (
          <Fragment key={question.id}>
            <p className={styles.questionLabel}>{question.text}</p>
            <CellEmpty height="15" />

            <RadioGroup
              options={QUESTIONS_OPTIONS}
              onChange={handleQuestionChange.bind(null, question.code)}
            />
            <CellEmpty height="30" />
          </Fragment>
        ))}
        <CellEmpty height="30" />

        {hasEmptyAnswer && (
          <p className={styles.formAlert}>Ты пропустил вопрос!</p>
        )}
        <SanekButton
          disabled={hasEmptyAnswer}
          onClick={handleSaveQuestions}
          isLoading={isProcessing}
        >
          Далее
        </SanekButton>
      </div>
    </div>
  )
}
