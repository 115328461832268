import { JSX } from 'react'
import style from './sanek-tip.module.css'
import Man from 'assets/man.png'

type TSanekTipProps = {
  text: string
}
export const SanekTip = ({ text }: TSanekTipProps): JSX.Element => {
  return (
    <div className={style.sanekTipWrapper}>
      <div className={style.sanekTipAva}>
        <img src={Man} />
      </div>
      <p className={style.sanekTipText}>-&nbsp;{text}</p>
    </div>
  )
}
