import { css } from '@emotion/css'

export const playButton = css`
  width: 42px;
  height: 42px;
  position: absolute;
  bottom: 12px;
  left: calc(50% - 21px);
  
  img {
    width: 20px;
  }
`
