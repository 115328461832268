import { JSX, useEffect, useState } from 'react'
import { paymentTransport } from 'api/payment/payment.transport.ts'
import { CenteredLoader } from 'components/common/centered-loader'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums.ts'
import { GetPaymentResponse } from 'api/payment/responses/get-payment.response.ts'
import { TPaymentStatus } from 'enums/payment.enums.ts'
import { useSearchParams } from 'react-router-dom'
import { PaymentModel } from 'api/payment/model/payment.model.ts'
import { Toast } from 'antd-mobile'

export const SubscriptionProceed = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasError, setHasError] = useState<boolean>(false)
  const [payment, setPayment] = useState<PaymentModel | null>(null)

  const [searchParams] = useSearchParams()

  const paymentId = searchParams.get('paymentId')

  useEffect(() => {
    const id = Number(paymentId)

    if (!paymentId || isNaN(id)) {
      void router.navigate(SanekRoute.MAIN)
      return
    }

    setIsLoading(true)

    void paymentTransport
      .getPayment(id)
      .then((data: GetPaymentResponse) => {
        setPayment(data.payment)
      })
      .catch(() => setHasError(true))
      .finally(() => setIsLoading(false))
  }, [paymentId])

  useEffect(() => {
    if (isLoading || !paymentId || !payment) {
      return
    }

    if (hasError) {
      void router.navigate(SanekRoute.SUBSCRIPTION_BUY_ERROR)
      return
    }

    if (payment.alreadyHadSubscription) {
      void router.navigate(SanekRoute.MAIN)
      Toast.show({ content: <p>Подписка уже куплена</p> })
      return
    }

    if (payment.data.status === TPaymentStatus.CANCELED) {
      void router.navigate(SanekRoute.SUBSCRIPTION_BUY_ERROR)
      return
    }

    if (payment.data.status === TPaymentStatus.SUCCEEDED) {
      void router.navigate(SanekRoute.SUBSCRIPTION_BUY_SUCCESS)
      return
    }

    if (
      payment.data.status === TPaymentStatus.PENDING &&
      payment.data.confirmation.confirmationUrl
    ) {
      payment.alreadySeen
        ? void router.navigate(SanekRoute.SUBSCRIPTIONS)
        : window.location.replace(payment.data.confirmation.confirmationUrl)
    }
  }, [hasError, payment, isLoading, paymentId])

  if (isLoading) {
    return <CenteredLoader />
  }

  return <></>
}
