import { css, keyframes } from '@emotion/css';

const fadein = keyframes`
  from { bottom: 0; opacity: 0; }
  to { bottom: 50%; opacity: 1; }
`
const fadeout = keyframes`
  from { bottom: 50%; opacity: 1; }
  to { bottom: 0; opacity: 0; }
`
export const copyAlert = css`
  visibility: hidden;
  background-color: #ffde00;
  box-sizing: border-box;
  min-width: 150px;
  margin-left: -75px;
  border: 5px solid #e6e6e6;
  border-radius: 20px;
  padding: 16px;
  text-align: center;
  left: 50%;
  bottom: 50%;
  z-index: 10;
  position: fixed;
  font-size: 18px;
  font-weight: 400;
  
  &.show {
    visibility: visible;
    animation: ${fadein} 0.5s, ${fadeout} 0.5s 2.5s;
  }
`
