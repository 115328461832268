import { PlaceModel } from 'api/places/models/place.model.ts'
import HrYellow from 'assets/hr-yellow.svg'
import { PRICE_SEGMENT_LABEL_MAP } from 'const/places.const.ts'
import { JSX } from 'react'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { FlexContainer } from 'ui/flex-container'
import { PlaceItemImage } from '../places/place-item-image.tsx'
import * as styles from './place-map-item-card.styles.ts'
import { SanekButton } from 'ui/sanek-button/sanek-button.tsx'
import { navToPlaceDetails } from 'utils/navigation.ts'

type TPlaceMapItemCardProps = {
  place: PlaceModel
}

export function PlaceMapItemCard({
  place,
}: TPlaceMapItemCardProps): JSX.Element {
  const handleChoosePlace = (): void => {
    navToPlaceDetails(place.id)
  }

  return (
    <div className={styles.placeItem}>
      <FlexContainer justify="space-between" align="center" wrap="nowrap">
        <h2 className={styles.placeName}>{place.name}</h2>
      </FlexContainer>
      <CellEmpty height="5" />
      <img src={HrYellow} className={styles.placeHr} />
      <CellEmpty height="5" />

      <FlexContainer justify="space-between" wrap="nowrap">
        <p className={styles.commonText}>{place.address}</p>

        <p className={styles.placePriceSegment}>
          {PRICE_SEGMENT_LABEL_MAP[place.priceSegment]}
        </p>
      </FlexContainer>
      <CellEmpty height="10" />

      <FlexContainer justify="end" wrap="nowrap">
        <p
          className={styles.commonText}
          style={{ marginRight: '5px', color: place.metroStation.color }}
        >
          М
        </p>
        <p className={styles.commonText}>{place.metroStation.name}</p>
      </FlexContainer>

      <PlaceItemImage place={place} />

      <p className={styles.placeDescription}>{place.description}</p>
      <CellEmpty height="10" />

      <FlexContainer justify="end" wrap="nowrap">
        <SanekButton size="s" onClick={handleChoosePlace}>
          ВЫБРАТЬ
        </SanekButton>
      </FlexContainer>
    </div>
  )
}
