import { JSX, useState, useId, useMemo } from 'react'
import { TPickerTimeslot } from 'types/time-picker.types.ts'
import {
  getPickerValue,
  getTimeLabel,
  getTimePickerFromColumns,
  getTimePickerToColumns,
  getTimeValue,
} from 'utils/time-picker.utils.ts'
import * as styles from './meeting-time-picker.style.ts'
import { DatePicker, Picker } from 'antd-mobile'
import { observer } from 'mobx-react-lite'
import * as commonStyles from 'styles/common.style.ts'
import { getFormattedDateLabel, renderPickerLabel } from 'utils/date.tsx'
import dayjs, { Dayjs } from 'dayjs'
import { FlexContainer } from 'ui/flex-container'
import { PickerValue } from 'antd-mobile/es/components/picker-view'
import * as antdStyles from 'styles/antd-mobile.style.ts'
import { PickerDate } from 'antd-mobile/es/components/date-picker/util'
import { cx } from '@emotion/css'

type TMeetingTimePickerProps = {
  selectedTimeslots: Array<TPickerTimeslot>
  onAddTimeslot: (timeslot: TPickerTimeslot) => void
}

const DATE_NOW = new Date()
const MAX_PICKER_VALUE = new Date(new Date().setMonth(DATE_NOW.getMonth() + 1))

export const MeetingTimePicker = observer(
  ({
    selectedTimeslots,
    onAddTimeslot,
  }: TMeetingTimePickerProps): JSX.Element => {
    const [date, setDate] = useState<Dayjs | null>(null)
    const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false)

    const [timeFrom, setTimeFrom] = useState<Dayjs | null>(null)
    const [selectedTimeFrom, setSelectedTimeFrom] = useState<[number, number]>([
      0, 0,
    ])
    const [selectedTimeTo, setSelectedTimeTo] = useState<[number, number]>([
      0, 0,
    ])
    const [isTimeFromPickerOpen, setIsTimeFromPickerOpen] =
      useState<boolean>(false)

    const [timeTo, setTimeTo] = useState<Dayjs | null>(null)
    const [isTimeToPickerOpen, setIsTimeToPickerOpen] = useState<boolean>(false)

    const isDateTimeFilled = date && date.isValid() && timeFrom && timeTo

    const canAdd = useMemo((): boolean => {
      if (!isDateTimeFilled) {
        return false
      }

      const hasEqualDate = Boolean(
        selectedTimeslots.some((timeslot: TPickerTimeslot) =>
          timeslot.date.isSame(date)
        )
      )

      if (!hasEqualDate) {
        return true
      }

      const hasEqualTimeRange = Boolean(
        selectedTimeslots.some(
          (timeslot: TPickerTimeslot) =>
            timeslot.timeFrom.hour() === timeFrom?.hour() &&
            timeslot.timeFrom.minute() === timeFrom.minute() &&
            timeslot.timeTo.hour() === timeTo?.hour() &&
            timeslot.timeTo.minute() === timeTo.minute()
        )
      )

      return !hasEqualTimeRange
    }, [selectedTimeslots, date, timeFrom, timeTo, isDateTimeFilled])

    const componentId = useId()

    const id = useMemo(() => {
      return componentId + '-' + selectedTimeslots.length
    }, [selectedTimeslots, componentId])

    const handleAdd = (): void => {
      if (!isDateTimeFilled || !canAdd) {
        return
      }

      onAddTimeslot({
        id,
        date,
        timeFrom,
        timeTo,
      })

      setTimeFrom(null)
      setTimeTo(null)
    }

    if (selectedTimeslots.length > 15) {
      return (
        <p className={commonStyles.textCommon}>Максимальное число слотов</p>
      )
    }

    const handleOpenTimeFromPicker = (): void => {
      setIsTimeFromPickerOpen(true)
    }

    const handleCloseTimeFromPicker = (): void => {
      setIsTimeFromPickerOpen(false)
    }

    const handleSelectTimeFrom = (value: Array<PickerValue>): void => {
      setSelectedTimeFrom(value as [number, number])
    }

    const handleSelectTimeTo = (value: Array<PickerValue>): void => {
      setSelectedTimeTo(value as [number, number])
    }

    const handleTimeFromConfirm = (value: Array<PickerValue>): void => {
      const timeFrom = getTimeValue(value)
      setTimeFrom(timeFrom)
      handleCloseTimeFromPicker()
    }

    const handleOpenTimeToPicker = (): void => {
      setIsTimeToPickerOpen(true)
    }

    const handleCloseTimeToPicker = (): void => {
      setIsTimeToPickerOpen(false)
    }

    const handleTimeToConfirm = (value: Array<PickerValue>): void => {
      setTimeTo(getTimeValue(value))
      handleCloseTimeToPicker()
    }

    const handleDateChange = (date: PickerDate) => {
      setDate(dayjs(date))
      setTimeFrom(null)
      setTimeTo(null)
      setIsDatePickerOpen(false)
    }

    const minDateTime = useMemo((): Dayjs => {
      const currentDateTime = dayjs()
      return !date || date.isSame(currentDateTime, 'd')
        ? currentDateTime.add(2, 'h')
        : date
    }, [date])

    const maxDateTime = useMemo((): Dayjs => {
      const currentDateTime = dayjs()
      return !date || date.isSame(currentDateTime, 'd')
        ? currentDateTime.endOf('d')
        : date.endOf('d')
    }, [date])

    const timePickerFromColumns = useMemo(() => {
      return getTimePickerFromColumns(
        minDateTime,
        maxDateTime,
        selectedTimeFrom[0],
        timeTo
      )
    }, [selectedTimeFrom, minDateTime, maxDateTime, timeTo])

    const timePickerToColumns = useMemo(() => {
      return getTimePickerToColumns(
        minDateTime,
        maxDateTime,
        selectedTimeTo[0],
        timeFrom || minDateTime
      )
    }, [selectedTimeTo, minDateTime, maxDateTime, timeFrom])

    return (
      <div>
        <div className={styles.timeSelectWrapper}>
          <div className={styles.pickerCol}>
            <p className={styles.label}>День</p>
            <div
              className={styles.pickerValue}
              style={{ width: 160 }}
              onClick={setIsDatePickerOpen.bind(null, true)}
            >
              {date?.isValid() ? getFormattedDateLabel(date) : 'Выберите день'}
            </div>
            <DatePicker
              className={antdStyles.datePicker}
              visible={isDatePickerOpen}
              onClose={setIsDatePickerOpen.bind(null, false)}
              min={DATE_NOW}
              max={MAX_PICKER_VALUE}
              onConfirm={handleDateChange}
              value={date?.toDate()}
              cancelText="Отмена"
              renderLabel={renderPickerLabel}
              confirmText="Подтвердить"
            />
          </div>
          <div className={styles.pickerCol}>
            <p className={styles.label}>Время</p>
            <div className={styles.timePickerWrapper}>
              <FlexContainer
                flex={1}
                direction="row"
                justify="space-between"
                align="center"
                wrap="nowrap"
              >
                <div
                  onClick={handleOpenTimeFromPicker}
                  className={cx(styles.pickerValue, {
                    [styles.pickerDisabled]: !date,
                  })}
                >
                  {getTimeLabel(timeFrom, 'От')}
                </div>
                <Picker
                  visible={isTimeFromPickerOpen}
                  columns={timePickerFromColumns}
                  value={getPickerValue(timeFrom)}
                  onSelect={handleSelectTimeFrom}
                  onConfirm={handleTimeFromConfirm}
                  onCancel={handleCloseTimeFromPicker}
                  onClose={handleCloseTimeFromPicker}
                  popupClassName={styles.timePicker}
                />
              </FlexContainer>
              <div className={styles.line}>—</div>
              <FlexContainer
                flex={1}
                direction="row"
                justify="space-between"
                align="center"
                wrap="nowrap"
              >
                <div
                  onClick={handleOpenTimeToPicker}
                  className={cx(styles.pickerValue, {
                    [styles.pickerDisabled]: !date || !timeFrom,
                  })}
                >
                  {getTimeLabel(timeTo, 'До')}
                </div>
                <Picker
                  visible={isTimeToPickerOpen}
                  columns={timePickerToColumns}
                  value={getPickerValue(timeTo)}
                  onSelect={handleSelectTimeTo}
                  onConfirm={handleTimeToConfirm}
                  onCancel={handleCloseTimeToPicker}
                  onClose={handleCloseTimeToPicker}
                  popupClassName={styles.timePicker}
                />
              </FlexContainer>
            </div>
          </div>
        </div>
        <div
          className={styles.addButton}
          style={{
            opacity: canAdd ? 1 : 0.5,
          }}
          onClick={handleAdd}
        >
          Добавить время
        </div>
      </div>
    )
  }
)
