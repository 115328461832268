import { PlaceSorting } from 'enums/places.enums.ts'
import { observer } from 'mobx-react-lite'
import { ChangeEvent, JSX } from 'react';
import { placesStore } from 'store/places.store.ts'
import styles from './places-tabs.module.css';
import { PlacesTab } from './places-tab.tsx';

type TPlacesTabsProps = {
  onFiltersClick: VoidFunction;
};

export const PlacesTabs = observer(({ onFiltersClick }: TPlacesTabsProps): JSX.Element => {
  const { sorting } = placesStore

  const handleTabChange = (event: ChangeEvent<HTMLInputElement>): void => {
    placesStore.setSorting(event.target.value as PlaceSorting);
    placesStore.getPlaces()
  }

  return (
    <div className={styles.container}>
      <div className={styles.tabsContainer}>
        <PlacesTab
          value={PlaceSorting.POPULAR}
          isSelected={sorting === PlaceSorting.POPULAR}
          onChange={handleTabChange}
        >
          Популярное
        </PlacesTab>
        <PlacesTab
          value={PlaceSorting.NEW}
          isSelected={sorting === PlaceSorting.NEW}
          onChange={handleTabChange}
        >
          Новое
        </PlacesTab>
        <PlacesTab
          value={PlaceSorting.SANEK_CHOICE}
          isSelected={sorting === PlaceSorting.SANEK_CHOICE}
          onChange={handleTabChange}
        >
          Выбор Санька
        </PlacesTab>
      </div>

      <button
        className={styles.filterButton}
        onClick={onFiltersClick}
      />
    </div>
  )
})
