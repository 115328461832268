import { AxiosPromise, AxiosResponse } from 'axios'
import { transport } from './transport.ts'
import { SendSMSResponse, VerifyCodeResponse } from './models/sms.model.ts'

export class SmsTransport {
  sendSMSCode(phone: string): AxiosPromise<SendSMSResponse> {
    return transport.post(
      `${import.meta.env.VITE_STRAPI_API}/api/login/login`,
      {
        phone_number: phone,
      }
    )
  }

  verifyCode({
    code,
    uuid,
  }: {
    code: string
    uuid: string
  }): Promise<void | VerifyCodeResponse> {
    return transport
      .post(`${import.meta.env.VITE_STRAPI_API}/api/login/verify`, {
        code,
        uuid,
      })
      .then(({ data }: AxiosResponse<VerifyCodeResponse>) => {
        if (data.jwt) {
          localStorage.setItem('jwt', data.jwt)
        }
      })
      .catch()
  }
}

export const smsTransport = new SmsTransport()
