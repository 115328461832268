import { css } from '@emotion/css'

const baseText = css`
  font-family: 'Copperplate', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 214%;
  letter-spacing: -0.03em;
  color: #000;
`

export const mainContainer = css`
  align-items: flex-start;
  background-color: white;
  padding: 26px;
  flex-direction: column;
  gap: 10px;
`

export const checkbox = css`
  appearance: none;
  border-radius: 3px;
  width: 26px;
  height: 26px;
  background: #e3e3e3;
  cursor: pointer;
  &:checked {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="black" d="M9 19.414l-7-7L4.414 11 9 15.586 20.586 4 22 5.414l-13 13z"/></svg>');
    background-color: #ffde00;
    background-repeat: no-repeat;
    background-position: center;
  }
`

export const label = css`
  margin-left: 13px;
`

export const stationContainer = css`
  display: flex;
  align-items: center;

  font-family: 'Copperplate', sans-serif;
  font-weight: 300;
  font-size: 24px;
  color: #000;
  margin-bottom: 5px;
`

export const metroIcon = css`
  margin-right: 10px;
`

export const headerBlock = css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  position: sticky;
  z-index: 100;
`

export const title = css`
  ${baseText};
  font-family: 'Chalkboard SE', serif;
  font-size: 22px;
  margin-bottom: 5px;
`

export const inputContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 23px;
  width: 100%;
  height: 46px;
  background: #f6f6f6;
  position: sticky;
  top: 48px;
  z-index: 100;
  margin-bottom: 15px;
`

export const stationsListContainer = css`
  max-height: 85vh;
  width: 100%;
  overflow-y: auto;
`

export const eraserBlock = css`
  h3 {
    ${baseText}
  }

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 3px;
  position: absolute;
  right: 20px;
  padding: 5px;
`

export const input = css`
  width: 80%;
  height: 43px;
  border: none;
  background-color: transparent;
  margin-left: 10px;
  ${baseText}

  :focus {
    outline: none !important;
    border: none;
    box-shadow: none;
  }
`

export const placeFiltersFooter = css`
  padding: 0 2rem 2rem;
`

export const searchMetroPage = css`
  height: 75vh;
  overflow: hidden;
`
