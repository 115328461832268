export function showCopyAlert() {
  const copyAlertElem = document.getElementById('sanek-global-copy-alert');
  if (!copyAlertElem) {
    return;
  }
  copyAlertElem.classList.add('show');

  setTimeout(()=>{
    copyAlertElem.classList.remove('show');
  }, 3000);
}
