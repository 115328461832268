import React from 'react'
import { LayoutPage } from 'components/layout-page'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { navigateBack } from 'utils/navigation'
import { observer } from 'mobx-react-lite'
import { UserLevelsSlider } from 'components/user-levels-slider'

export const UserLevelSPage: React.FC = observer(() => {
  useScrollToTop()

  return (
    <LayoutPage headerText="Уровень" backAction={navigateBack}>
      <UserLevelsSlider />
    </LayoutPage>
  )
})
