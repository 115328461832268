import { MeetingCounterpart } from 'api/user-meetings/models/meeting-counterpart.ts'
import AvatarStroke from 'assets/decorations/meeting-details/avatar-stroke.png'
import Star from 'assets/star.png'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import { JSX } from 'react'
import { FlexContainer } from 'ui/flex-container'
import { Typography } from 'ui/typography'
import * as styles from './rate-counterpart.style.ts'

const BUTTONS = [
  {
    text: 'Отличный друг',
    value: 3,
  },
  {
    text: 'Пока рано говорить',
    value: 2,
  },
  {
    text: 'Не мой человек',
    value: 1,
  },
]

type TRateCounterpartProps = {
  counterpart: MeetingCounterpart
  value: number | null
  onChange: (value: number) => void
}

export const RateCounterpart = observer(
  ({ counterpart, value, onChange }: TRateCounterpartProps): JSX.Element => {
    return (
      <FlexContainer
        justify="space-between"
        align="end"
        wrap="nowrap"
        style={{ marginBottom: '35px' }}
      >
        <div className={styles.avatarContainer}>
          <img src={AvatarStroke} alt="" className={styles.avatarStroke} />
          <img
            src={counterpart.imageUrl}
            alt="Аватар"
            className={styles.avatarImage}
          />
          <div className={styles.counterpartNameContainer}>
            <Typography size="20" weight="700" align="center">
              {counterpart.name}
            </Typography>
          </div>
        </div>

        <div className={styles.buttonsContainer}>
          {BUTTONS.map((data) => (
            <button
              className={cx(styles.rateButton, {
                [styles.activeButton]: data.value === value,
              })}
              onClick={onChange.bind(null, data.value)}
            >
              <FlexContainer align="center" wrap="nowrap">
                <div className={styles.starsContainer}>
                  {[...Array(data.value)].map(() => (
                    <img src={Star} alt="" />
                  ))}
                </div>
                <Typography weight="700">{data.text}</Typography>
              </FlexContainer>
            </button>
          ))}
        </div>
      </FlexContainer>
    )
  }
)
