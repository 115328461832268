import { css } from '@emotion/css'
export const inputContainer = css`
  border-radius: 23px;
  width: 100%;
  height: 46px;
  padding: 10px 20px;
  margin-bottom: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`

export const input = css`
  width: 80%;
  height: 43px;
  border: none;
  background-color: transparent;
  margin-left: 10px;
  font-family: 'Copperplate', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #595959;

  :focus {
    outline: none !important;
    border: none;
    box-shadow: none;
  }
`

export const resultsContainer = css`
  background-color: #fff;
  width: 100%;
  min-height: 50vh;
  height: auto;
  border-radius: 23px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`

export const emptyBlock = css`
  display: flex;
  padding: 80px 20px 50px;
  min-height: 50vh;
  height: auto;
`

export const placeCard = css`
  border-radius: 23px;
  width: 100%;
  min-height: 60px;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
  background: #fff7c4;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 25px;

  h2 {
    font-family: 'Chalkboard SE', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: #000;
  }

  p {
    margin-top: auto;
    align-self: flex-end;
    font-family: 'Copperplate', sans-serif;
    font-weight: 300;
    font-size: 12px;
    color: #000;

    span {
      margin-right: 5px;
    }
  }
`
