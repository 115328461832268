import dayjs, { Dayjs } from 'dayjs'

export function deserializeDate(dateRaw: string): Dayjs {
  return dayjs(dateRaw)
}

export function deserializeTime(timeRaw: string | null): Dayjs | null {
  return timeRaw ? dayjs(timeRaw, 'HH:mm:ss.SSS') : null
}

export function deserializeNullToDefault(
  defaultValue: unknown
): (_: unknown) => unknown {
  return function (rawValue: unknown): unknown {
    return rawValue === null ? defaultValue : rawValue
  }
}
