import { deserialize, JsonName } from 'tserialize'

export class PaymentConfirmationModel {
  @JsonName()
  type: 'redirect' = 'redirect' as const

  @JsonName('confirmation_url')
  confirmationUrl!: string

  static fromServer(data: object): PaymentConfirmationModel {
    return deserialize(data, PaymentConfirmationModel)
  }
}
