import { Footer } from 'components/layout-page'
import { MeetingStatus } from 'const/statuses.ts'
import { observer } from 'mobx-react-lite'
import { JSX } from 'react'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums.ts'
import { meetingDetailsStore } from 'store/meeting-details.store.ts'
import { SanekButton } from 'ui/sanek-button'

export const MeetingDetailsFooter = observer((): JSX.Element | null => {
  const { meeting } = meetingDetailsStore

  if (!meeting) {
    return null
  }

  const handleCreateMeeting = (): void => {
    void router.navigate(SanekRoute.CREATE_MEETING, {
      replace: true
    })
  }

  switch (meeting.status) {
    case MeetingStatus.NOT_FOUND:
      return (
        <Footer>
          <SanekButton onClick={handleCreateMeeting}>
            СОЗДАТЬ ВСТРЕЧУ
          </SanekButton>
        </Footer>
      )
    case MeetingStatus.CANCELLED:
      return (
        <Footer>
          <SanekButton onClick={handleCreateMeeting}>
            ПЕРЕСОЗДАТЬ
          </SanekButton>
        </Footer>
      )
  default:
      return null
  }
})
