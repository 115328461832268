import { css } from '@emotion/css'

const textCommon = css`
  color: #000;
  text-align: start;
  font-style: normal;
  font-family: 'Copperplate', serif;
`

const flexContainer = css`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;
  flex-direction: column;
`

export const mainContainer = css`
  ${flexContainer};
  padding: 0 35px;
  overflow-y: auto;
`

export const buttonBack = css`
  position: absolute;
  top: 28px;
  left: 24px;
`

export const play = css`
  position: absolute;
  top: 33px;
  left: 32px;
`

export const share = css`
  width: 26px;
  height: 24px;
  position: absolute;
  bottom: 0;
  right: 15px;
`

export const header = css`
  ${textCommon};
  width: 210px;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
`

export const image = css`
  margin-bottom: 20px;
`

export const info = css`
  ${textCommon};
  width: 306px;
  font-size: 20px;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 20px;
  position: relative;
`

export const buttonBlock = css`
  ${flexContainer};
  z-index: 2;
  width: 100%;
  max-width: 400px;
  margin-bottom: 40px;
`
