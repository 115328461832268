import ClosedLevel from 'assets/closed-level.png'
import * as styles from './slide.style'
import { UserLevelModel } from 'api/models/user-level.model'

type UserLevelSlideProps = {
  level: UserLevelModel
  isMale?: boolean
  isCurrent: boolean
  isDone: boolean
  isClosed: boolean
}

export const Slide = ({
  level,
  isMale,
  isCurrent,
  isDone,
  isClosed,
}: UserLevelSlideProps): JSX.Element => {
  const {
    maleImage,
    femaleImage,
    maleTitle,
    femaleTitle,
    description,
    gradeText,
  } = level
  const { url: maleImageUrl } = maleImage
  const { url: femaleImageUrl } = femaleImage

  return (
    <div className={styles.slideContainer}>
      {isCurrent && <div className={styles.current}>Текущий</div>}
      {isDone && <div className={styles.done}>Получено</div>}
      {isClosed && <div className={styles.closed}>Закрыт</div>}

      <div className={styles.card}>
        {isDone || isCurrent ? (
          <img
            src={isMale ? maleImageUrl : femaleImageUrl}
            alt="лого"
            className={styles.image}
          />
        ) : (
          <img src={ClosedLevel} alt="лого" className={styles.image} />
        )}
        <h4 className={styles.title}>{isMale ? maleTitle : femaleTitle}</h4>
        {isDone || isCurrent ? (
          <div className={styles.description}>{description}</div>
        ) : (
          <div className={styles.descriptionClosed}>?</div>
        )}
        {isClosed && <div className={styles.grade}>{gradeText}</div>}
      </div>
    </div>
  )
}
