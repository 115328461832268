import * as styles from './meetings-history.style'
import { UserMeetingModel } from 'api/user-meetings/models/user-meeting.model.ts'
import { CenteredLoader } from 'components/common/centered-loader'
import { MeetingCard } from 'components/meeting-card'
import { LayoutPage } from 'components/layout-page'
import { observer } from 'mobx-react-lite'
import { Fragment, useEffect } from 'react'
import { archiveMeetingsStore } from 'store/archive-meetings.store.ts'
import { navigateBack } from 'utils/navigation'
import { SanekEmptyView } from 'components/sanek-empty-view'
import { SeparatorBlue } from 'components/common/separator'

export const MeetingsHistoryPage = observer(() => {
  const { isLoading, meetings } = archiveMeetingsStore

  useEffect(() => {
    archiveMeetingsStore.getArchiveMeetings()
  }, [])

  if (isLoading) {
    return <CenteredLoader />
  }

  return (
    <LayoutPage headerText="История встреч" backAction={navigateBack}>
      <div className={styles.meetingsContainer}>
        {meetings.length === 0 ? (
          <div className={styles.emptyBlock}>
            <SanekEmptyView
              title="Упс, Санёк ничего не нашел"
              description="Попробуй с кем-нибудь встретиться, тогда Санёк точно что-то увидит!"
            />
          </div>
        ) : (
          <>
            {meetings.map((meeting: UserMeetingModel) => (
              <Fragment key={meeting.id}>
                <MeetingCard meeting={meeting} />
                <SeparatorBlue />
              </Fragment>
            ))}
          </>
        )}
      </div>
    </LayoutPage>
  )
})
