import { css } from '@emotion/css'

const textCommon = css`
  color: #000;
  text-align: center;
  line-height: 55px;
  letter-spacing: -0.3px;
  font-style: normal;
`

const flexContainer = css`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

export const mainContainer = css`
  ${flexContainer};
  flex-direction: column;
  overflow: hidden;

  img {
    z-index: 0;
  }

  p {
    width: 936px;
    ${textCommon};
    font-family: 'Copperplate', serif;
    font-size: 48px;
    font-weight: 400;
  }

  h3 {
    ${textCommon};
    width: 1234px;
    font-family: 'Chalkboard SE', serif;
    font-size: 45px;
    font-weight: 700;
    letter-spacing: 2.7px;
  }
`

export const infoBlock = css`
  ${flexContainer};

  h2 {
    ${textCommon};
    font-family: 'Chalkboard SE', serif;
    font-size: 60px;
    font-weight: 400;
    line-height: 81px;
  }
`
export const closeContainer = css`
  ${flexContainer};
  gap: 58px;
`

export const guideContainer = css`
  ${flexContainer};
  flex-direction: column;
  margin: 0 auto 100px;
  position: relative;
`

export const footer = css`
  ${flexContainer};
  flex-direction: column;
  margin: 0 auto 100px;
  gap: 80px;
  position: relative;

  a {
    color: #000;
    ${textCommon};
    font-size: 50px;
    text-decoration-line: underline;
  }
`

export const footerStroke = css`
  ${flexContainer};
  margin: 0 auto;
  gap: 44px;

  p {
    ${textCommon};
    font-weight: 300;
    text-align: start !important;
    font-size: 40px;
  }
`

export const circle = css`
  ${textCommon};
  padding-top: 8px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #ffde00;
  font-size: 100px;
  font-weight: 400;
  line-height: normal;
`

export const stroke = css`
  position: absolute;
  top: 70px;
  left: -50px;
`

export const leftBottomVortex = css`
  position: absolute;
  z-index: 10;
  bottom: -80px;
  left: -120px;
`

export const checkMark = css`
  position: absolute;
  z-index: 10;
  bottom: 30px;
  right: -30px;
`

export const vortexRight = css`
  position: absolute;
  z-index: 10;
  top: -30px;
  right: -80px;
`

export const rotatedVector = css`
  transform: scaleX(-1);
`
