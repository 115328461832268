import { css } from '@emotion/css'

export const pageContainer = css`
  padding: 0 24px;
`

export const titleNew = css`
  padding-left: 12px;
  margin-top: 12px;
  color: #68b984;
  font-size: 14px;
`

export const titleOld = css`
  padding-left: 12px;
  margin-top: 12px;
  color: #b0b0b0;
  font-size: 14px;
`
