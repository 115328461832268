import { deserializeDate } from 'api/deserializers/common.deserializers.ts'
import dayjs, { Dayjs } from 'dayjs'
import { SubscriptionState } from 'enums/subscription.enums.ts'
import { JsonNameReadonly } from 'tserialize'

const EXPIRE_SOON_DAYS = 5

export class SubscriptionModel {
  @JsonNameReadonly()
  id!: number

  @JsonNameReadonly('valid_to', deserializeDate)
  validTo!: Dayjs

  @JsonNameReadonly('is_trial')
  isTrial: boolean = false

  get remainingDays(): number {
    const days = Math.ceil(this.validTo.diff(dayjs(), 'day', true))
    return days >= 0 ? days : 0
  }

  get state(): SubscriptionState {
    if (this.remainingDays > EXPIRE_SOON_DAYS) {
      return SubscriptionState.ACTIVE
    } else if (this.remainingDays > 0) {
      return SubscriptionState.EXPIRE_SOON
    } else {
      return SubscriptionState.EXPIRED
    }
  }
}
