import { css } from '@emotion/css';
import { buttonReset } from 'styles/reset.style.ts';

export const favouritesButton = css`
  ${buttonReset};
  width: 72px;
  height: 18px;
  border: 2px solid #f2f2f2;
  border-radius: 4px;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
  background: #000;

  font-family: Chalkboard SE, serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #fff;
`;
