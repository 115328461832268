import { SubscriptionModel } from 'api/subscription/models/subscription.model.ts'
import Crown from 'assets/decorations/subscriptions/crown.svg'
import Fire from 'assets/decorations/subscriptions/fire.svg'
import Star from 'assets/decorations/subscriptions/star.svg'
import { SubscriptionState } from 'enums/subscription.enums.ts'
import { JSX } from 'react'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { FlexContainer } from 'ui/flex-container'
import { SVGImg } from 'ui/svg-img'
import { Typography } from 'ui/typography'
import { pluralize, TPluralizeConfig } from 'utils/pluralize.ts'
import * as styles from './subscription-status.style.ts'

type TSubscriptionStatusProps = {
  subscription: SubscriptionModel
}

const PLURALIZE_DAYS_CONFIG: TPluralizeConfig = ['день', 'дня', 'дней']

export function SubscriptionStatus({ subscription }: TSubscriptionStatusProps): JSX.Element {
  const pluralizedDays = pluralize(PLURALIZE_DAYS_CONFIG, subscription.remainingDays)

  const renderStateContent = (): JSX.Element => {
    if (subscription.state === SubscriptionState.EXPIRED) {
      const text = subscription.isTrial
        ? 'Пробный период закончился'
        : 'Подписка закончилась'

      return (
        <>
          <SVGImg src={Fire} width="24" height="24" />
          <CellEmpty height="5" />
          <Typography size="12" weight="300" align="center">
            {text}
          </Typography>
        </>
      )
    }


    if (subscription.isTrial) {
      return (
        <>
          <SVGImg src={Star} width="24" height="24" />
          <CellEmpty height="5" />
          <Typography size="12" weight="300" align="center">
            Действует<br/>пробный период
          </Typography>
        </>
      )
    }

    return (
      <>
        <SVGImg src={Crown} width="27" height="24" />
        <CellEmpty height="8" />
        <Typography size="12" weight="300" align="center">
          Есть подписка
        </Typography>
      </>
    )
  }

  return (
    <FlexContainer justify="space-between" wrap="nowrap" className={styles.statusContainer}>
      <FlexContainer direction="column" align="center" wrap="nowrap" className={styles.statusInfoItem}>
        <Typography family="Chalkboard" weight="700">
          Статус подписки
        </Typography>
        <CellEmpty height="10" />
        {renderStateContent()}
      </FlexContainer>

      <FlexContainer direction="column" align="center" wrap="nowrap" className={styles.statusInfoItem}>
        <Typography family="Chalkboard" weight="700">
          До окончания
        </Typography>
        <CellEmpty height="10" />
        <Typography size="55" lineHeight="0.7" weight="700" color={styles.getDaysColor(subscription)}>
          {subscription.remainingDays}
        </Typography>
        <CellEmpty height="2" />
        <Typography size="12" weight="300">
          {pluralizedDays}
        </Typography>
      </FlexContainer>
    </FlexContainer>
  )
}
