import { AxiosResponse } from 'axios'
import { MeetingMoodModel } from './models/meeting-mood.model.ts'
import { MeetingCategoryModel } from './models/meeting-category.model.ts'
import { transport } from '../transport.ts'

class MeetingTransport {
  getMeetingMoods(): Promise<Array<MeetingMoodModel>> {
    return transport
      .get(`${import.meta.env.VITE_STRAPI_API}/api/meeting-moods`)
      .then(({ data }: AxiosResponse<Array<object>>) => {
        return data.map((item: object) => MeetingMoodModel.fromServer(item))
      })
  }

  getMeetingCategories(): Promise<Array<MeetingCategoryModel>> {
    return transport
      .get(`${import.meta.env.VITE_STRAPI_API}/api/meeting-categories`)
      .then(({ data }: AxiosResponse<Array<object>>) => {
        return data.map((item: object) => MeetingCategoryModel.fromServer(item))
      })
  }
}

export const meetingTransport = new MeetingTransport()
