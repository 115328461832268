import { TSurveyQuestionModel } from 'api/models/survey-question.model.ts'

export function makeAnswersEmptyMap(
  questions: Array<TSurveyQuestionModel>
): Record<string, null> {
  return questions.reduce(
    (acc: Record<string, null>, current: TSurveyQuestionModel) => {
      acc[current.code] = null
      return acc
    },
    {}
  )
}
