import ManTallAccepted from 'assets/man_tall_accepted.png'
import ManTallDeclined from 'assets/man_tall_declined.png'
import Monolog from 'assets/monolog.svg'
import { SpeechBubble } from 'components/speech-bubble'
import { Fragment, JSX, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { router } from 'router'
import { SanekButton } from 'ui/sanek-button'
import { getSurveyRouteByUser } from 'utils/navigation.ts'
import styles from './intro-sanek-greeting.module.css'
import { sanekUsersTransport } from 'api/sanek-users.transport.ts'
import { userStore } from 'store/user.store.ts'
import { USER_NOT_FOUND_ERROR } from 'const/common.const.ts'
import { observer } from 'mobx-react-lite'
import { useRollbar } from '@rollbar/react'

export const IntroSanekGreetingPage = observer((): JSX.Element => {
  const { state } = useLocation()
  const { user } = userStore

  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const rollbar = useRollbar()

  const bubbleTextGreeting = useMemo(() => {
    if (state?.isAcceptedCall) {
      return <Fragment>Алло, это Санёк!</Fragment>
    }

    return (
      <Fragment>Я&nbsp;тоже не&nbsp;люблю отвечать на&nbsp;звонки!</Fragment>
    )
  }, [state])

  const handleContinue = (): void => {
    if (!user) {
      rollbar.error(USER_NOT_FOUND_ERROR)
      return
    }

    setIsProcessing(true)
    void sanekUsersTransport
      .setUserPassedOnboarding(user.id)
      .then(() => {
        void router.navigate(getSurveyRouteByUser(user))
      })
      .finally(() => setIsProcessing(false))
  }

  return (
    <div className={styles.sanekGreetingPage}>
      <div className={styles.speechBubbleContainer}>
        <SpeechBubble backgroundImage={Monolog} topOffsetText={10}>
          <p className={styles.speechBubbleContent}>
            {bubbleTextGreeting}
            <br />
            <br />
            Давай пройдем психологический тест, чтобы я&nbsp;подобрал тебе
            подходящих друзей
          </p>
        </SpeechBubble>
      </div>

      <div className={styles.image}>
        <img
          src={state.isAcceptedCall ? ManTallAccepted : ManTallDeclined}
          alt="Санёк"
          width="360px"
          height="360px"
        />
      </div>

      <SanekButton onClick={handleContinue} isLoading={isProcessing}>
        ПРОЙТИ ТЕСТ
      </SanekButton>
    </div>
  )
})
