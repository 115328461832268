import { css } from '@emotion/css'

export const meetingsContainer = css`
  display: flex;
  align-items: center;
  margin: 40px auto 20px;
  flex-direction: column;
  gap: 20px;
`

export const emptyBlock = css`
  display: flex;
  padding: 80px 20px 50px;
  min-height: 50vh;
  height: auto;
`
