import { makeAutoObservable } from 'mobx'
import { MetroStationModel } from 'api/models/metro-station.model.ts'
import { metroStationsTransport } from 'api/metro-stations.transport.ts'

class MetroStationsStore {
  metroStations: Array<MetroStationModel> = []
  isLoading: boolean = false
  hasError: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setMetroStations = (metroStations: Array<MetroStationModel>): void => {
    this.metroStations = metroStations
  }

  setIsLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading
  }

  setHasError = (hasError: boolean): void => {
    this.hasError = hasError
  }

  getMetroStations = (cityId?: number): void => {
    this.setIsLoading(true)
    this.setHasError(false)

    metroStationsTransport
      .getMetroStations(cityId)
      .then(this.setMetroStations)
      .catch(() => {
        this.setHasError(true)
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }
}

export const metroStationsStore = new MetroStationsStore()
