import { css } from '@emotion/css'

export const notificationButton = css`
  position: relative;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  border: 5px solid #f2f2f2;
  background: #fcdb00;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.25);
`

export const count = css`
  position: absolute;
  font-family: 'Chalkboard SE', serif;
  width: 22px;
  height: 17px;
  z-index: 20;
  margin-top: 3px;
  margin-right: 1px;
  color: #e41c2a;
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
`
