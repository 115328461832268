import { JSX } from 'react'
import * as styles from './stub-legal.style.ts'
import { CellEmpty } from '../../../ui/cell-empty.tsx'

export const StubLegal = (): JSX.Element => {
  return (
    <div className={styles.contentWrapper}>
      <div className={styles.linksWrapper}>
        <a href={import.meta.env.VITE_POLICY_URL} target="_blank">
          Политика конфенденциальности
        </a>

        <a href={import.meta.env.VITE_TERMS_URL} target="_blank">
          Пользовательское соглашение
        </a>
      </div>
      <CellEmpty height="100" />

      <div className={styles.ownerInfo}>
        <p>Владелец сайта</p>
        <CellEmpty height="40" />

        <p>
          ИП Нестеров Иван Александрович (ИНН 771817136188, ОГРНИП
          321774600045852)
        </p>
        <CellEmpty height="40" />

        <p>
          Чтобы связаться, пишите на почту{' '}
          <a href="mailto:vanesterovolympiad@yandex.ru">
            vanesterovolympiad@yandex.ru
          </a>
        </p>
      </div>
    </div>
  )
}
