import { SanekUser } from 'api/models/sanek-user.model.ts'
import { AxiosResponse } from 'axios'
import { ResultType } from './models/role.model'
import { transport } from './transport.ts'

export class AllRoles {
  getRoles() {
    return transport
      .get(`${import.meta.env.VITE_STRAPI_API}/api/role-questions?populate=*`)
      .then(({ data }: AxiosResponse) => data.data)
  }

  createRoleSurvey(user: SanekUser, result: ResultType) {
    const roleSurveyData = {
      data: {
        result: result,
        user: {
          connect: [user.id],
        },
      },
    }

    return transport
      .post(
        `${import.meta.env.VITE_STRAPI_API}/api/role-surveys`,
        roleSurveyData
      )
      .then((response) => response.data)
  }
}

export const rolesTransport = new AllRoles()
