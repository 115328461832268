import { css } from '@emotion/css'

export const centeredInput = css`
  width: 100%;
  height: 40px;

  box-sizing: border-box;
  border: 2px solid #f2f2f2;
  padding: 10px;
  outline: none;

  border-radius: 8px;
  background: #f6f6f6;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);

  text-align: center;
  font-family: Copperplate, serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.408px;

  &::placeholder {
    font-size: 16px;
    font-weight: 300;
    color: #595959;

    &:focus {
      color: #a9a9a9;
    }
  }
`

export const centeredInputBig = css`
  height: 64px;
  border: 0;
  border-radius: 16px;
  background: #f9f9f9;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.1);

  font-size: 31px;
  font-weight: 700;

  &::placeholder {
    font-size: 20px;
  }
`

export const centeredInputWrapper = css`
  position: relative;
  width: 100%;
`

export const dropdownIcon = css`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`
