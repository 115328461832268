import * as styles from './meetings-counter.style'
import { pluralize } from 'utils/pluralize.ts'

type TMeetingsCounterProps = {
  count: number
}

export const MeetingsCounter = ({
  count,
}: TMeetingsCounterProps): JSX.Element => {
  const text = pluralize(['Встреча', 'Встречи', 'Встреч'], count)

  return (
    <div className={styles.container}>
      <div className={styles.counter}>{count}</div>
      <span className={styles.text}>{text}</span>
    </div>
  )
}
