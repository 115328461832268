import { JSX, PropsWithChildren } from 'react'
import styles from './speech-bubble.module.css'

type TSpeechBubbleProps = PropsWithChildren<{
  backgroundImage: string;
  topOffsetText?: number;
  leftOffsetText?: number;
}>;

export function SpeechBubble({
  children,
  backgroundImage,
  topOffsetText = 0,
  leftOffsetText = 0
}: TSpeechBubbleProps): JSX.Element {
  return (
    <div className={styles.speechBubble}>
      <img src={backgroundImage} alt="monolog SVG" />
      <div className={styles.childrenContainer} style={{ top: topOffsetText, left: leftOffsetText }}>
        {children}
      </div>
    </div>
  )
}
