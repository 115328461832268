import * as styles from './change-avatar-button.style.ts'

import { JSX, FC } from 'react'
import { router } from 'router'
import { observer } from 'mobx-react-lite'

import { SanekRoute } from 'router/router.enums.ts'

import { ProgressiveImage } from 'components/progressive-image/progressive-image.tsx'
import { SanekUser } from 'api/models/sanek-user.model.ts'

type Props = {
  user: SanekUser
}
export const ChangeAvatarButton: FC<Props> = observer(
  ({ user }): JSX.Element => {
    return (
      <div className={styles.changeAvatarWrapper}>
        <div className={styles.changeAvatarImageWrapper}>
          <ProgressiveImage
            image={user.avatar.image}
            alt={user.avatar.name || 'Аватар'}
            height="160px"
            width="160px"
          />
        </div>
        <button onClick={handleOpenAvatars}>Сменить аватар</button>
      </div>
    )
  }
)

const handleOpenAvatars = (): void => {
  void router.navigate(SanekRoute.SURVEY_AVATARS)
}
