import { css } from '@emotion/css'

export const meetingsContainer = css`
  display: flex;
  align-items: center;
  margin: 0 auto 20px;
  flex-direction: column;
  gap: 20px;
`

export const header = css`
  display: flex;
  align-items: center;
  padding: 10px 20px 20px;
  width: 100%;
`

export const sortingBlock = css`
  display: flex;
  justify-content: center;
  width: 50%;
`

export const settingsBlock = css`
  display: flex;
  justify-content: flex-end;
  width: 50%;
`

export const button = css`
  background-color: white;
  font-family: 'Copperplate', sans-serif;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #000;
  padding: 8px 5px;
  flex: 1;
  margin: 0 10px;
  border-radius: 0px;
  border: none;

  &:focus,
  &:active {
    outline: none;
  }
`

export const active = css`
  border-bottom: 2px solid #9fcbdc !important;
`

export const settingsSvg = css`
  position: relative;
`

export const filtersCount = css`
  position: absolute;
  background-color: red;
  width: 16px;
  height: 16px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
`
