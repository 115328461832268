import dayjs from 'dayjs'
import { deserialize, JsonName } from 'tserialize'

export class NotificationModel {
  @JsonName('id')
  id!: number

  @JsonName('title')
  title: string = ''

  @JsonName('body')
  body: string = ''

  @JsonName('createdAt')
  createdAt: string = dayjs().toISOString()

  @JsonName('updatedAt')
  updatedAt: string = dayjs().toISOString()

  @JsonName('seen')
  seen: boolean = false

  @JsonName('entity')
  entity: 'meeting' | 'subscription' | 'message' | 'invitation' = 'message'

  @JsonName('entityId')
  entityId: number = -1

  static fromServer(data: object): NotificationModel {
    return deserialize(data, NotificationModel)
  }
}
