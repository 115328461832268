import * as styles from './metro-station-dialog.style.ts'
import { MetroStationModel } from 'api/models/metro-station.model'
import { LayoutPage } from 'components/layout-page'
import { MetroStationMultiSelect } from './metro-station-multi-select.tsx'
import { useState } from 'react'
import { SanekButton } from 'ui/sanek-button'

type MetroStationsSearchProps = {
  cityId: number | undefined
  onSelect: (metroStation: MetroStationModel[]) => void
}

export const MetroStationMultiSelectDialog: React.FC<
  MetroStationsSearchProps
> = ({ cityId, onSelect }) => {
  const [selectedStations, setSelectedStations] = useState<MetroStationModel[]>(
    []
  )

  const handleSave = () => {
    onSelect(selectedStations)
  }

  const handleMetroStationsChange = (stations: MetroStationModel[]) =>
    setSelectedStations(stations)

  return (
    <LayoutPage
      headerText="Выбор метро"
      footerNode={
        <div className={styles.placeFiltersFooter}>
          <SanekButton type="secondary" onClick={handleSave}>
            Сохранить
          </SanekButton>
        </div>
      }
      backAction={handleSave}
    >
      <div className={styles.searchMetroPage}>
        <MetroStationMultiSelect
          cityId={cityId}
          value={selectedStations}
          onChange={handleMetroStationsChange}
        />
      </div>
    </LayoutPage>
  )
}
