import SanekEmpty from 'assets/man_smaller_thumbs.png'
import { JSX } from 'react'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { FlexContainer } from 'ui/flex-container'
import * as styles from './sanek-empty-view.style.ts'
import { SeparatorGrayThin } from 'components/common/separator/separator-gray-thin.tsx'

type TSanekEmptyViewProps = {
  title: string
  description: string
}

export function SanekEmptyView({
  title,
  description,
}: TSanekEmptyViewProps): JSX.Element {
  return (
    <div>
      <p className={styles.emptyViewTitle}>{title}</p>

      <FlexContainer direction="column" align="center">
        <img
          src={SanekEmpty}
          alt="Ничего не найдено"
          className={styles.emptyViewImage}
        />
        <SeparatorGrayThin className={styles.emptyViewSeparator} />
      </FlexContainer>
      <CellEmpty height="5" />

      <p className={styles.emptyViewDescription}>{description}</p>
    </div>
  )
}
