import { makeAutoObservable } from 'mobx'

class AudioStore {
  permission: boolean = false
  recordedAudio: HTMLAudioElement | null = null
  time: number = 0
  stream: MediaStream | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setPermission = (value: boolean): void => {
    this.permission = value
  }

  setRecordedAudio = (audio: HTMLAudioElement | null): void => {
    this.recordedAudio = audio
  }

  setTime = (time: number): void => {
    this.time = time
  }

  setStream = (stream: MediaStream | null): void => {
    this.stream = stream
  }

  reset = (): void => {
    const tracks = this.stream?.getAudioTracks() || []

    tracks.forEach((track: MediaStreamTrack) => track.stop())

    this.permission = false
    this.recordedAudio = null
    this.time = 0
    this.stream = null
  }
}

export const audioStore = new AudioStore()
