import { PlacesSubcategoryItem } from 'components/places-subcategories/places-subcategory-item.tsx';
import { JSX } from 'react';
import * as styles from './places-subcategories.style.ts';

type TPlacesSubcategoriesOption<T> = {
  value: T;
  label: string;
}

type TPlacesSubcategoriesProps<T> = {
  options: Array<TPlacesSubcategoriesOption<T>>;
  selected?: T;
  onChange: (value: T) => void;
};

export function PlacesSubcategories<T extends number | string>({
  options,
  selected,
  onChange
}: TPlacesSubcategoriesProps<T>): JSX.Element {
  return (
    <div className={styles.container}>
      {options.map((filterOption: TPlacesSubcategoriesOption<T>, index: number) => (
        <PlacesSubcategoryItem
          key={index}
          value={filterOption.value}
          checked={filterOption.value === selected}
          onChange={onChange}
        >
          {filterOption.label}
        </PlacesSubcategoryItem>
      ))}
    </div>
  );
}
