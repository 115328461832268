import React from 'react'
import * as styles from './confirm-meeting-refusal-modal.style'

import { CellEmpty } from 'ui/cell-empty.tsx'
import { LayoutPage } from 'components/layout-page'

import ManWithCat from 'assets/man_with_cat.png'
import Vortex from 'assets/vortex-confirm-refuse.svg'
import { SeparatorGray } from 'components/common/separator'

type TConfirmMeetingRefusalModalProps = {
  onClose: VoidFunction
  onRefuse: VoidFunction
  onAccept: VoidFunction
}

export const ConfirmMeetingRefusalModal: React.FC<
  TConfirmMeetingRefusalModalProps
> = ({ onClose, onRefuse, onAccept }) => {
  return (
    <LayoutPage headerText="Не придешь?" backAction={onClose}>
      <div className={styles.mainContainer}>
        <img src={ManWithCat} alt="Санёк" width="340" height="340" />

        <div className={styles.title}>Ты точно не придешь на встречу?</div>

        <div className={styles.buttonContainer}>
          <button className={styles.acceptBtn} onClick={onAccept}>
            Всё-таки пойду
          </button>
          <SeparatorGray />
          <button className={styles.refuseBtn} onClick={onRefuse}>
            Точно не пойду
          </button>
        </div>
        <CellEmpty height="40" />
        <img className={styles.vortex} src={Vortex} alt="Вихрь" />
      </div>
    </LayoutPage>
  )
}
