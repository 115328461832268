import { ImageModel } from 'api/models/image.model.ts'
import { deserialize, JsonNameReadonly, JsonStruct } from 'tserialize'

export class MeetingMoodModel {
  @JsonNameReadonly()
  id!: number

  @JsonNameReadonly()
  text: string = ''

  @JsonNameReadonly()
  code: string = ''

  @JsonStruct(ImageModel)
  image!: ImageModel

  static fromServer(data: object): MeetingMoodModel {
    return deserialize(data, MeetingMoodModel)
  }
}
