import { css } from '@emotion/css'

export function getAvatarStyle(isOwnMessage: boolean): string {
  return css`
    width: 57px;
    height: 57px;
    border-radius: 100%;
    background-color: ${isOwnMessage ? '#ffde00' : '#9fcbdc'};
    border: 1px solid #e2e2e2;
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
    overflow: hidden;
  `
}

export const avatarImage = css`
  width: 100%;
`
