import { deserialize, JsonName } from 'tserialize'

export class PaginationModel {
  @JsonName()
  page!: number

  @JsonName()
  pageSize!: number

  @JsonName()
  pageCount!: number

  @JsonName()
  total!: number

  static fromServer(data: object): PaginationModel {
    return deserialize(data, PaginationModel)
  }
}
