import { userMeetingsTransport } from 'api/user-meetings/user-meetings.transport.ts'
import { MeetingTimePicker } from 'components/meeting-time-picker'
import { SelectedTimeslots } from 'components/selected-timeslots'
import { observer } from 'mobx-react-lite'
import { JSX, useState } from 'react'
import { meetingDetailsStore } from 'store/meeting-details.store.ts'
import { TPickerTimeslot } from 'types/time-picker.types.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { SanekButton } from 'ui/sanek-button'
import * as styles from './owner-search-content.style.ts'

export const MeetingTimeslots = observer((): JSX.Element => {
  const { meeting, selectedTimeslots } = meetingDetailsStore
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const handleEditTimeslots = (): void => {
    setIsEditMode(true)
  }

  const handleAddTimeslot = (timeslot: TPickerTimeslot): void => {
    meetingDetailsStore.setSelectedTimeslots([...selectedTimeslots, timeslot])
  }

  const handleRemoveTimeslot = (timeslot: TPickerTimeslot): void => {
    const filteredTimeslots = selectedTimeslots.filter((item: TPickerTimeslot) => item.id !== timeslot.id)
    meetingDetailsStore.setSelectedTimeslots(filteredTimeslots)
  }

  const handleSaveTimeslots = (): void => {
    if (!meeting) {
      return
    }

    setIsLoading(true)
    userMeetingsTransport.updateMeetingTimeslots(meeting.id, selectedTimeslots)
      .then(() => setIsEditMode(false))
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      {isEditMode && (
        <>
          <p className={styles.editTimeTitle}>Редактировать время</p>
          <CellEmpty height="10" />

          <MeetingTimePicker
            selectedTimeslots={selectedTimeslots}
            onAddTimeslot={handleAddTimeslot}
          />
          <CellEmpty height="14" />

          <p className={styles.editTimeDescription}>
            Выбери временные интервалы, когда у&nbsp;тебя есть свободное время
          </p>
          <CellEmpty height="26" />
        </>
      )}

      <SelectedTimeslots
        timeslots={selectedTimeslots}
        onEditClick={isEditMode ? void 0 : handleEditTimeslots}
        onRemoveItem={isEditMode ? handleRemoveTimeslot : void 0}
      />

      {isEditMode && (
        <>
          <CellEmpty height="23" />
          <SanekButton
            isLoading={isLoading}
            disabled={!selectedTimeslots.length}
            onClick={handleSaveTimeslots}
          >
            Сохранить
          </SanekButton>
        </>
      )}
    </>
  )
})
