import { deserialize, JsonStruct } from 'tserialize'
import { PaymentModel } from '../model/payment.model.ts'

export class GetPaymentResponse {
  @JsonStruct(PaymentModel)
  payment: PaymentModel | null = null

  static fromServer(data: object): GetPaymentResponse {
    return deserialize(data, GetPaymentResponse)
  }
}
