import { JSX } from 'react'
import styles from './survey-questions.module.css'

type TSurveyTipProps = {
  /** Номер шага */
  step: string
  /** Заголовок */
  title: string
  /** Описание */
  subtitle: string
}

export function SurveyTip({
  step,
  title,
  subtitle,
}: TSurveyTipProps): JSX.Element {
  return (
    <div className={styles.surveyTip}>
      <p className={styles.surveyTipStep}>{step}</p>
      <p className={styles.surveyTipTitle}>{title}</p>
      <p className={styles.surveyTipSubtitle}>{subtitle}</p>
    </div>
  )
}
