import { TPickerTimeslot } from 'types/time-picker.types.ts'
import { getFormattedStrapiDate, getFormattedStrapiTime } from 'utils/date.tsx'
import { transport } from '../transport.ts'

export class UserInvitationsTransport {
  setIsInvitationAccepted = (
    id: number,
    isAccepted: boolean,
    timeslot?: TPickerTimeslot | null
  ): Promise<void> => {
    return transport.post(
      `${import.meta.env.VITE_STRAPI_API}/api/meeting-accept`,
      {
        data: {
          id,
          is_accepted: isAccepted,
          timeslot: timeslot
            ? {
                date: getFormattedStrapiDate(timeslot.date),
                time_from: getFormattedStrapiTime(timeslot.timeFrom),
                time_to: getFormattedStrapiTime(timeslot.timeTo),
              }
            : null,
        },
      }
    )
  }
}

export const userInvitationsTransport = new UserInvitationsTransport()
