import { transport } from 'api/transport.ts'
import { AxiosResponse } from 'axios'
import { GetPaymentResponse } from './responses/get-payment.response.ts'

class PaymentTransport {
  getPayment(id: number): Promise<GetPaymentResponse> {
    return transport
      .post(`${import.meta.env.VITE_STRAPI_API}/api/get-payment`, {
        data: { payment_id: id },
      })
      .then(({ data }: AxiosResponse<GetPaymentResponse>) =>
        GetPaymentResponse.fromServer(data)
      )
  }
}

export const paymentTransport = new PaymentTransport()
