import { NOTIFICATION_STATUS_LABEL_MAP } from 'const/notification.const.ts'
import { NotificationStatus } from 'enums/notification.enums.ts'
import { JSX } from 'react'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { Typography } from 'ui/typography'

type TCounterpartPresenceStatusProps = {
  notificationStatus: NotificationStatus | null
}

export function CounterpartPresenceStatus({ notificationStatus }: TCounterpartPresenceStatusProps): JSX.Element {
  const statusText = notificationStatus
    ? NOTIFICATION_STATUS_LABEL_MAP[notificationStatus]
    : 'Подтвердил'

  return (
    <div>
      <Typography align="center">
        {statusText}
      </Typography>
      <CellEmpty height="3" />
      <div
        style={{
          borderRadius: '30px',
          width: '115px',
          height: '6px',
          backgroundColor: '#ddd',
        }}
      />
    </div>
  )
}
