import { deserialize, JsonName } from 'tserialize'

export class PushSubscriptionModel {
  @JsonName()
  id!: number

  @JsonName()
  auth!: string

  @JsonName()
  endpoint!: string

  @JsonName()
  p256dh!: string

  static fromServer(data: object): PushSubscriptionModel {
    return data && deserialize(data, PushSubscriptionModel)
  }
}
