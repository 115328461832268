import * as styles from './places.styles.ts'

import { PlaceModel } from 'api/places/models/place.model.ts'
import { sanekUsersTransport } from 'api/sanek-users.transport.ts'
import HeartFilled from 'assets/decorations/places/heart-filled.svg'
import HeartGrey from 'assets/decorations/places/heart-grey.svg'
import Geolocation from 'assets/geolocation.svg'
import HrYellow from 'assets/hr-yellow.svg'
import { PRICE_SEGMENT_LABEL_MAP } from 'const/places.const.ts'
import { JSX, MouseEvent } from 'react'
import { userStore } from 'store/user.store.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { FlexContainer } from 'ui/flex-container'
import { navToPlaceDetails } from 'utils/navigation.ts'
import { PlaceItemImage } from './place-item-image.tsx'
import { placesStore } from 'store/places.store.ts'
import { useNavigate } from 'react-router-dom'

type Props = {
  place: PlaceModel
}

export function PlaceItem({ place }: Props): JSX.Element {
  const { user } = userStore
  const { setIsFavorite, getPlaces } = placesStore

  const navigate = useNavigate()

  const hasCoords = Number(place.longitude) && Number(place.latitude)

  const handleOpenMapClick = (event: MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation()
    navigate(
      `/places/map?latitude=${place.latitude}&longitude=${place.longitude}`
    )
  }

  const handleFavouriteClick = (event: MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation()

    if (!user) {
      return
    }

    if (!place.isFavourite) {
      setIsFavorite(place.id, true)
      sanekUsersTransport
        .addPlaceToFavourites(user.id, place.id)
        .catch(() => setIsFavorite(place.id, false))
      return
    }

    setIsFavorite(place.id, false)
    sanekUsersTransport
      .removePlaceFromFavourites(user.id, place.id)
      .catch(() => setIsFavorite(place.id, true))

    getPlaces()
  }

  const handlePlaceClick = (): void => {
    navToPlaceDetails(place.id)
  }

  return (
    <div className={styles.placeItem} onClick={handlePlaceClick}>
      <FlexContainer justify="space-between" align="center" wrap="nowrap">
        <h2 className={styles.placeName}>{place.name}</h2>
        <button
          className={styles.placeFavouriteButton}
          onClick={handleFavouriteClick}
        >
          <img src={place.isFavourite ? HeartFilled : HeartGrey} />
        </button>
      </FlexContainer>
      <img src={HrYellow} className={styles.placeHr} />
      <CellEmpty height="5" />

      <FlexContainer justify="space-between" wrap="nowrap">
        <p className={styles.commonText}>{place.address}</p>

        <p className={styles.placePriceSegment}>
          {PRICE_SEGMENT_LABEL_MAP[place.priceSegment]}
        </p>
      </FlexContainer>
      <CellEmpty height="10" />

      <FlexContainer justify="end" wrap="nowrap">
        <p
          className={styles.commonText}
          style={{ marginRight: '5px', color: place.metroStation.color }}
        >
          М
        </p>
        <p className={styles.commonText}>{place.metroStation.name}</p>
      </FlexContainer>

      <PlaceItemImage place={place} />
      <CellEmpty height="10" />

      <p className={styles.placeDescription}>{place.description}</p>
      <CellEmpty height="10" />

      <FlexContainer
        justify={hasCoords ? 'space-between' : 'end'}
        align="center"
        wrap="nowrap"
      >
        {hasCoords ? (
          <button
            className={styles.placeGeolocationButton}
            onClick={handleOpenMapClick}
          >
            <img src={Geolocation} width={11} height={18} />
            на карте
          </button>
        ) : (
          <></>
        )}
        <p className={styles.placeMoreDetails}>Подробнее</p>
      </FlexContainer>
    </div>
  )
}
