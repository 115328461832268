import { css } from '@emotion/css'

export const avatarContainer = css`
  position: relative;
  width: 47%;
`

export const avatarImage = css`
  display: block;
  width: 100%;
`

export const avatarStroke = css`
  position: absolute;
  left: -30%;
  bottom: -11px;
  width: 263px;
`

export const counterpartNameContainer = css`
  width: 100%;
  position: absolute;
  bottom: -35px;
`

export const buttonsContainer = css`
  width: 170px;
`

export const rateButton = css`
  width: 170px;
  height: 44px;
  border: 2px solid #f2f2f2;
  border-radius: 16px;
  box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.25);
  background: #f6f6f6;
  margin-bottom: 17px;
  padding: 0.3em;
`

export const activeButton = css`
  background: #ffde00 !important;
`

export const starsContainer = css`
  width: 60px;
  min-width: 60px;
  margin-right: 6px;
  
  img {
    width: 20px;
    height: 20px;
  }
`
