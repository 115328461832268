import { JSX } from 'react'
import * as styles from './reset-button.style.ts'
import Reset from 'assets/reset.svg'
import { FlexContainer } from '../flex-container'

type TResetButtonProps = {
  onClick: VoidFunction
}
export const ResetButton = ({ onClick }: TResetButtonProps): JSX.Element => {
  return (
    <FlexContainer align="center" justify="end">
      <button className={styles.resetButton} onClick={onClick}>
        <img src={Reset} />
        <p>Сбросить</p>
      </button>
    </FlexContainer>
  )
}
