import { useState } from 'react'
import * as styles from './invitations-filters.style'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import { CenteredLoader } from 'components/common/centered-loader'
import { TMeetingsFilters } from 'types/meetings-filters'
import { userInvitationsStore } from 'store/invitations.store'
import { useNavigate } from 'react-router-dom'
import { SanekRoute } from 'router/router.enums.ts'
import { Footer, LayoutPage } from 'components/layout-page'
import { navigateBack } from 'utils/navigation.ts'
import {
  FILTER_NAMES_MAP,
  FilterType,
  TIME_FILTERS,
} from 'enums/filter-names.enum'
import { SVGImg } from 'ui/svg-img'
import { SanekButton } from 'ui/sanek-button'
import Eraser from 'assets/eraser.svg'
import { SeparatorBlue } from 'components/common/separator'

export const InvitationsFiltersPage = observer(() => {
  const navigate = useNavigate()
  const { isLoading, filters } = userInvitationsStore

  const [localFilters, setLocalFilters] = useState<TMeetingsFilters>(filters)

  const handleFilterChange = (filterName: keyof TMeetingsFilters) => {
    setLocalFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: !prevFilters[filterName],
    }))
  }

  const handleResetFilters = () => {
    setLocalFilters({})
  }

  const handleGoBack = () => {
    userInvitationsStore.setFilters(localFilters)
    navigate(SanekRoute.INVITATIONS_LIST)
  }

  const renderFilterGroup = (filtersGroup: Array<FilterType>) =>
    filtersGroup.map((filterName) => (
      <button
        className={cx(styles.filter, {
          [styles.active]: localFilters[filterName],
        })}
        onClick={() => handleFilterChange(filterName)}
      >
        {FILTER_NAMES_MAP[filterName]}
      </button>
    ))

  if (isLoading) {
    return <CenteredLoader />
  }

  return (
    <LayoutPage
      headerText="Фильтр"
      footerNode={
        <Footer>
          <SanekButton type="secondary" onClick={handleGoBack}>
            Сохранить
          </SanekButton>
        </Footer>
      }
      backAction={navigateBack}
    >
      <div className={styles.filtersContainer}>
        <div className={styles.eraserBlock} onClick={handleResetFilters}>
          <SVGImg src={Eraser} />
          <h3>Сбросить</h3>
        </div>
        <h3>По времени</h3>
        {renderFilterGroup(TIME_FILTERS)}

        <SeparatorBlue className={styles.delimiter} />
      </div>
    </LayoutPage>
  )
})
