import { ChatMessageModel } from 'api/meeting-chat/models/chat-message.model.ts'
import { deserialize, JsonArray, JsonNameReadonly } from 'tserialize'

export class GetChatMessagesResponse {
  @JsonNameReadonly()
  id!: number

  @JsonArray(ChatMessageModel)
  messages: Array<ChatMessageModel> = []

  static fromServer(data: object): GetChatMessagesResponse {
    return deserialize(data, GetChatMessagesResponse)
  }
}
