import React from 'react'
import * as styles from './confirm-delete-account-modal.style'

import { LayoutPage } from 'components/layout-page'

import ManWithCat from 'assets/man_with_cat.png'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { SanekButton } from 'ui/sanek-button'
import { CellEmpty } from 'ui/cell-empty'
import { SeparatorGrayThin } from 'components/common/separator/separator-gray-thin.tsx'

type TConfirmDeleteAccountModalProps = {
  onClose: VoidFunction
  onAccept: VoidFunction
}

export const ConfirmDeleteAccountModal: React.FC<
  TConfirmDeleteAccountModalProps
> = ({ onClose, onAccept }) => {
  useScrollToTop()
  return (
    <LayoutPage headerText="Удаление аккаунта" backAction={onClose}>
      <div className={styles.mainContainer}>
        <div className={styles.title}>Ты точно хочешь удалить аккаунт?</div>

        <CellEmpty height="35" />

        <div className={styles.imagesContainer}>
          <img
            src={ManWithCat}
            alt="Санёк"
            width="300"
            height="300"
            className={styles.ManTall}
          />
          <SeparatorGrayThin className={styles.delimeter} />
        </div>

        <CellEmpty height="8" />

        <div className={styles.description}>
          Все назначенные встречи будут отменены, а информация о тебе будет
          забыта Саньком
        </div>

        <div className={styles.buttonContainer}>
          <SanekButton type="clear" onClick={onAccept}>
            Удалить
          </SanekButton>
        </div>
      </div>
    </LayoutPage>
  )
}
