import * as styles from './notifications-button.style.ts'

import { FC } from 'react'
import { observer } from 'mobx-react-lite'

import BellIcon from 'assets/bell.svg'

import { notificationsStore } from 'store/notifications.store.ts'

type Props = {
  onClick: () => void
}
export const NotificationsButton: FC<Props> = observer(({ onClick }) => {
  const { unreadNotificationsCount } = notificationsStore

  return (
    <button onClick={onClick} className={styles.notificationButton}>
      <img src={BellIcon} alt="Уведомления" width="24px" height="26px" />
      <div className={styles.count}>{unreadNotificationsCount}</div>
    </button>
  )
})
