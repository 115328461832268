import Play from 'assets/play.svg'
import { CircleButton } from 'components/common/circle-button'
import { JSX, useEffect, useMemo, useState } from 'react'
import * as styles from './audio-play-button.style.ts'

type TSoundPlayButtonProps = {
  audioSrc: string
}

export function AudioPlayButton({
  audioSrc,
}: TSoundPlayButtonProps): JSX.Element {
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [audioBuffer, setAudioBuffer] = useState<AudioBuffer | null>(null)

  const context = useMemo(() => {
    return new AudioContext()
  }, [])

  useEffect(() => {
    void fetch(audioSrc)
      .then((res) => res.arrayBuffer())
      .then((ArrayBuffer) =>
        context.decodeAudioData(ArrayBuffer).then(setAudioBuffer)
      )
  }, [audioSrc, context])

  const audioPlay = async () => {
    if (isPlaying) {
      return
    }

    setIsPlaying(true)

    const source = context.createBufferSource()

    source.buffer = audioBuffer
    source.connect(context.destination)
    source.start()
    source.onended = () => {
      setIsPlaying(false)
      source.disconnect(context.destination)
    }
  }

  return (
    <>
      <CircleButton
        icon={Play}
        isLoading={isPlaying}
        className={styles.playButton}
        onClick={audioPlay}
      />
    </>
  )
}
