import { css } from '@emotion/css'

export const mainContainer = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 25px 40px;
  width: 100%;
`

export const title = css`
  font-family: 'Chalkboard SE', sans-serif;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 0.06em;
  color: #000;
`

export const image = css`
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
`
