import { JSX, useState } from 'react'
import { Radio } from 'ui/radio/radio.tsx'
import { TRadioOption } from 'ui/radio/radio.types.ts'
import styles from './radio.module.css'

type TRadioGroupProps<T extends string | number> = {
  options: Array<TRadioOption<T>>
  onChange: (value: T) => void
}

export function RadioGroup<T extends string | number>({
  options,
  onChange,
}: TRadioGroupProps<T>): JSX.Element {
  const [selected, setSelected] = useState<T | null>(null)
  const handleChange = (value: T): void => {
    setSelected(value)
    onChange(value)
  }

  return (
    <div className={styles.radioGroup}>
      {options.map((option: TRadioOption<T>, index: number) => (
        <Radio
          key={index}
          value={option.value}
          checked={option.value === selected}
          onChange={handleChange}
        >
          {option.label || option.value}
        </Radio>
      ))}
    </div>
  )
}
