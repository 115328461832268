import { css } from '@emotion/css'

const centeredContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const mainContainer = css`
  ${centeredContainer};
  flex-direction: column;
  box-sizing: border-box;
  padding: 0px 5px 20px;
  width: 100%;
`

export const header = css`
  ${centeredContainer};
  justify-content: space-between;
  width: 100%;
  position: relative;
  min-height: 75px;
  margin-bottom: 30px;
  padding: 0px 30px;
`

export const titleHeader = css`
  font-family: 'Chalkboard SE', sans-serif;
  font-weight: 700;
  font-size: 26px;
  line-height: 85%;
  color: #000;
`

export const divider = css`
  position: absolute;
  left: -40px;
  top: 55px;
`

export const sliderContainer = css`
  ${centeredContainer};
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`
