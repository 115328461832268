import { deserialize, JsonNameReadonly } from 'tserialize'

export class MeetingSubcategoryModel {
  @JsonNameReadonly()
  id!: number

  @JsonNameReadonly()
  text: string = ''

  @JsonNameReadonly()
  code: string = ''

  static fromServer(data: object): MeetingSubcategoryModel {
    return deserialize(data, MeetingSubcategoryModel)
  }
}
