import { meetingChatTransport } from 'api/meeting-chat/meeting-chat.transport.ts'
import { GetChatMessagesResponse } from 'api/meeting-chat/models/get-chat-messages.response.ts'
import { makeAutoObservable } from 'mobx'

class MeetingChatStore {
  chat: GetChatMessagesResponse | null = null
  isLoading: boolean = false
  hasError: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setChat = (chat: GetChatMessagesResponse | null): void => {
    this.chat = chat
  }

  setIsLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading
  }

  setHasError = (hasError: boolean): void => {
    this.hasError = hasError
  }

  getChatMessages = (meetingId: number): void => {
    this.setIsLoading(true)
    this.setHasError(false)

    void meetingChatTransport
      .getMeetingChatMessages(meetingId)
      .then((chat: GetChatMessagesResponse | null) => {
        this.setChat(chat)
      })
      .catch(() => {
        this.setHasError(true)
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }
}

export const meetingChatStore = new MeetingChatStore()
