import { css, cx } from '@emotion/css';
import { createElement, JSX } from 'react'
import { maxLines as getMaxLinesStyle } from 'styles/common.style.ts'
import { getFontFamily } from './typography.styles.ts'
import { TTypographyProps } from './typography.types.ts';
import * as styles from './typography.styles.ts';

export function Typography({
  family = 'Copperplate',
  size = 16,
  lineHeight = 1,
  align = 'left',
  wordBreak = 'normal',
  whiteSpace = 'normal',
  style = 'normal',
  decoration = 'none',
  weight = 'normal',
  color = '#000',
  spacing = -0.408,
  maxLines,
  ...props
}: TTypographyProps): JSX.Element {
  const maxLinesStyle = maxLines ? getMaxLinesStyle(maxLines) : ''

  return createElement(
    props.type || 'p',
    {
      'className': cx(
        styles.base,
        css({
          label: 'text',
          fontFamily: getFontFamily(family),
          fontSize: `${size}px`,
          lineHeight,
          fontStyle: style,
          textDecoration: decoration,
          fontWeight: weight,
          display: props.display,
          color,
          textAlign: align,
          letterSpacing: `${spacing}px`,
          whiteSpace,
          wordBreak,
        }),
        maxLinesStyle,
        {
          [styles.ellipsis]: props.ellipsis,
        },
        props.className,
      ),
      'style': props.styles,
    },
    props.children
  )
}
