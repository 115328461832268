import { JSX, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { LayoutPage } from '../layout-page'
import { closeAllDialogs } from 'utils/dialog.ts'
import { SanekButton } from 'ui/sanek-button'
import Manul from 'assets/camera-manul.svg'
import Webcam from 'react-webcam'
import { userStore } from 'store/user.store.ts'
import * as styles from './camera.style.ts'
import { useWindowDimensions } from 'hooks/use-dimension.ts'
import {
  isMobile,
  isMobileSafari,
  useMobileOrientation,
} from 'react-device-detect'

const VIDEO_OUTER_PADDING = 80

type TCameraProps = {
  onAddPhoto: (file: File) => void
}

export const Camera = ({ onAddPhoto }: TCameraProps): JSX.Element => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [hasError, setHasError] = useState<boolean>(false)
  const webcamRef = useRef(null)
  const { user } = userStore
  const { isLandscape } = useMobileOrientation()

  const { width, height } = useWindowDimensions()

  useEffect(() => {
    if (isLandscape) {
      closeAllDialogs()
    }
  }, [isLandscape])

  const videoConstraints = useMemo((): object => {
    const videoHeight = height - VIDEO_OUTER_PADDING

    if (isMobile || isMobileSafari) {
      return {
        facingMode: 'user',
        height: { min: width, max: width },
        width: { min: videoHeight, max: videoHeight },
        aspectRatio: 1.5,
      }
    }

    return {
      facingMode: 'user',
      height: { min: videoHeight, max: videoHeight },
      width: { min: width, max: width },
      aspectRatio: 0.6666666667,
    }
  }, [isMobileSafari, isMobile, width, height])

  const title = useMemo((): string => {
    if (hasError) {
      return 'Ошибка камеры'
    }

    if (isInitialized) {
      return 'Сделайте фото'
    }

    return 'Открываем камеру'
  }, [isInitialized, hasError])

  const handleInitCamera = (): void => {
    // Искусственная задержка, чтобы маска и кнопка появились после появления камеры
    setTimeout(() => setIsInitialized(true), 1000)
  }

  const handleError = (): void => {
    setHasError(true)
  }

  const handleCapture = useCallback(async () => {
    // @ts-ignore
    const imageSrc = webcamRef.current.getScreenshot()

    const response = await fetch(imageSrc)
    const data = await response.blob()
    const metadata = {
      type: 'image/webp',
    }
    const file = new File([data], `${user?.name || 'selfie'}.webp`, metadata)
    onAddPhoto(file)
    closeAllDialogs()
  }, [webcamRef, onAddPhoto, user?.name])

  return (
    <LayoutPage backAction={closeAllDialogs} headerText={title}>
      {hasError ? (
        <div className={styles.errorWrapper}>
          <p>Не удалось получить доступ к камере</p>
          <p>Перезапустите приложение и повторите снова</p>
        </div>
      ) : (
        <div className={styles.cameraWrapper}>
          <Webcam
            audio={false}
            mirrored={true}
            ref={webcamRef}
            screenshotFormat="image/webp"
            onUserMedia={handleInitCamera}
            onUserMediaError={handleError}
            screenshotQuality={1}
            videoConstraints={videoConstraints}
          />
          {isInitialized && webcamRef.current && (
            <>
              <img className={styles.mask} src={Manul} alt="" />
              <SanekButton
                className={styles.captureBtn}
                onClick={handleCapture}
              >
                Снять
              </SanekButton>
            </>
          )}
        </div>
      )}
    </LayoutPage>
  )
}
