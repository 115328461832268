import { css } from '@emotion/css'
import { flexCenterContent, textCommon } from 'styles/common.style.ts'

export const pickerValue = css`
  ${flexCenterContent};
  ${textCommon};
  
  width: 114px;
  height: 47px;

  border-radius: 8px;
  background: #fff7c4;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
  border: none;
`

export const timePicker = css`
  .adm-picker-view-column-item-label,
  .adm-picker-header-button {
    color: #000;
    font-family: 'Chalkboard SE', serif;
    font-size: 20px;
    font-weight: 700;
  }
`
