import { Fragment, JSX, PropsWithChildren } from 'react'
import { CellEmpty } from '../cell-empty.tsx'
import * as styles from './form-item.style.ts'
import { SeparatorBlue } from 'components/common/separator'

type TFormItemProps = {
  hasNext?: boolean
}
export const FormItem = ({
  children,
  hasNext = true,
}: PropsWithChildren<TFormItemProps>): JSX.Element => {
  return (
    <div className={styles.formItemWrapper}>
      {children}

      {hasNext ? (
        <Fragment>
          <CellEmpty height="30" />
          <SeparatorBlue />
        </Fragment>
      ) : (
        <Fragment />
      )}
    </div>
  )
}
