import { css } from '@emotion/css'
import { SubscriptionModel } from 'api/subscription/models/subscription.model.ts'
import { SubscriptionState } from 'enums/subscription.enums.ts'

export const statusContainer = css`
  width: 100%;
  height: 120px;
  border: 5px solid #f2f2f2;
  border-radius: 26px;
  background-color: #ffde00;
  padding: 11px 14px;
`

export const statusInfoItem = css`
  width: 48%;
  height: 100%;
  border-radius: 13px;
  background-color: #fff;
  padding: 6px;
`

export function getDaysColor(subscription: SubscriptionModel): string {
  if (subscription.state === SubscriptionState.EXPIRED) {
    return '#ff6242'
  }

  return subscription.isTrial ? '#9fcbdc' : '#ffde00'
}
