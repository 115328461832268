export const ROLLBAR_CONFIG = {
  accessToken: import.meta.env.VITE_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: import.meta.env.MODE,
  enabled: ['production', 'staging', 'development'].includes(
    import.meta.env.MODE
  ),
  payload: {
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true,
      },
    },
  },
}
