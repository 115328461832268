import { GetTariffsResponse } from 'api/tariff/models/get-tariffs.response.ts'
import { TariffModel } from 'api/tariff/models/tariff.model.ts'
import { transport } from 'api/transport.ts'

export class TariffTransport {
  getTariffs(): Promise<Array<TariffModel>> {
    return transport.get(`${import.meta.env.VITE_STRAPI_API}/api/tariff/get-tariffs`)
      .then((response) => {
        return GetTariffsResponse.fromServer(response.data).tariffs
      })
  }
}

export const tariffTransport = new TariffTransport()
