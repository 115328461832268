export const STRAPI_DATE_FORMAT = 'YYYY-MM-DD'

export const TIME_TEMPLATE = 'HH:mm'

export const MEETING_TIME_TEMPLATE = 'HH:mm:ss.SSS'

export const MONTH_MAP: Record<number, string> = {
  1: 'Январь',
  2: 'Февраль',
  3: 'Март',
  4: 'Апрель',
  5: 'Май',
  6: 'Июнь',
  7: 'Июль',
  8: 'Август',
  9: 'Сентябрь',
  10: 'Октябрь',
  11: 'Ноябрь',
  12: 'Декабрь',
}
