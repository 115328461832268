import { css } from '@emotion/css'
import { TTypographyFontFamily } from './typography.types.ts'

export function getFontFamily(font: TTypographyFontFamily): string {
  switch (font) {
    case 'Chalkboard':
      return 'Chalkboard SE, sans-serif'
    case 'Copperplate':
      return 'Copperplate, serif'
    default:
      return 'inherit'
  }
}

export const base = css`
  margin: 0;
  padding: 0;
  overflow: visible;
  text-overflow: clip;
`

export const ellipsis = css`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
