import { css } from '@emotion/css'

export const container = css`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.5rem;
`
