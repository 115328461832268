import React from 'react'
import * as styles from './search-modal.styles.ts'
import { PlaceModel } from 'api/places/models/place.model.ts'
import { navToPlaceDetails } from 'utils/navigation.ts'

type TSearchItemCardProps = {
  place: PlaceModel
  onClick: VoidFunction
}

export const SearchItemCard: React.FC<TSearchItemCardProps> = ({
  place,
  onClick,
}) => {
  const handlePlaceClick = (): void => {
    onClick()
    navToPlaceDetails(place.id)
  }

  return (
    <div className={styles.placeCard} onClick={handlePlaceClick}>
      <h2>{place.name}</h2>
      <p>
        <span style={{ color: `${place.metroStation.color}` }}>M</span>
        {place.metroStation.name}
      </p>
    </div>
  )
}
