import { css } from '@emotion/css'

export const checkImage = css`
  width: 30%;
`

export const separatorImage = css`
  display: block;
  width: 90%;
  height: 8px;
  margin: 10px auto;
`
