import { PriceSegment } from 'enums/places.enums.ts'
import { JsonName, serialize } from 'tserialize'
import { serializeAddRelation } from 'utils/api.utils.ts'

export class CreatePlaceRequest {
  @JsonName()
  name: string = ''

  @JsonName()
  address: string = ''

  @JsonName('yandex_oid')
  yandexOid: string = ''

  @JsonName()
  website: string = ''

  @JsonName('price_segment')
  priceSegment: PriceSegment = PriceSegment.MEDIUM

  @JsonName('categories', serializeAddRelation)
  categoryId?: number

  @JsonName('metro_station', serializeAddRelation)
  metroStationId?: number

  @JsonName('creator_user', serializeAddRelation)
  creatorUserId?: number

  constructor(data: Omit<CreatePlaceRequest, 'toServer'>) {
    Object.assign(this, data)
  }

  toServer(): object {
    return serialize(this)
  }
}
