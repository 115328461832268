import Target from 'assets/decorations/meeting-details/target.png'
import { css } from '@emotion/css'

export const avatarContainer = css`
  position: relative;
  width: 47%;
`

export const avatarImage = css`
  display: block;
  width: 100%;
`

export const avatarStroke = css`
  position: absolute;
  left: -30%;
  bottom: -11px;
  width: 263px;
`

export const targetContainer = css`
  position: relative;
  width: 159px;
  height: 163px;
  background: url(${Target}) center/contain no-repeat;
`

export const nameContainer = css`
  width: 100%;
  position: absolute;
  top: 20px
`

const baseDivider = css`
  width: 100%;
  height: 6px;
  border-radius: 30px;
  margin: 3px 0;
`

export const nameDivider = css`
  ${baseDivider};
  width: 100%;
  background-color: #ffde00;
`

export const compatibilityContainer = css`
  width: 119px;
  position: absolute;
  bottom: 10px;
  right: 0;
`

export const compatibilityDivider = css`
  ${baseDivider};
  background-color: #9fcbdc;
`

export const roleItem = css`
  width: 30%;
  
  &::after {
    content: '';
    box-sizing: border-box;
    display: block;
    border: 2px solid #9fcbdc;
    border-radius: 23px;
    height: 5px;
    background-color: white;
  }
`
