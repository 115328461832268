import { deserialize, JsonName } from 'tserialize'

export class MetroStationModel {
  @JsonName()
  id!: number

  @JsonName()
  city: string = ''

  @JsonName()
  name: string = ''

  @JsonName()
  color: string = ''

  static fromServer(data: object): MetroStationModel {
    return deserialize(data, MetroStationModel)
  }
}
