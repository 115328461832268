import { JSX, PropsWithChildren } from 'react';
import * as styles from './favourites-button.style.ts';

type TFavouritesButtonProps = PropsWithChildren<{
  onClick: VoidFunction;
}>;

export function FavouritesButton({ children, onClick }: TFavouritesButtonProps): JSX.Element {
  return (
    <button className={styles.favouritesButton} onClick={onClick}>
      {children}
    </button>
  );
}
