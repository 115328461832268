import { JSX } from 'react'
import { FlowResultPage } from 'ui/flow-result-page'

export const SubscriptionBuyError = (): JSX.Element => {
  return (
    <FlowResultPage
      headerText="Оплата не прошла"
      text="Оплата не прошла, попробуй еще раз :)"
    />
  )
}
