import { JSX } from 'react'
import Stroke from 'assets/separator-gray.png'
import cx from 'classnames'
import * as styles from './separator.style.ts'

type TSeparatorGrayProps = {
  className?: string
}
export const SeparatorGray = ({
  className,
}: TSeparatorGrayProps): JSX.Element => {
  return <img src={Stroke} alt="" className={cx(styles.img, className)} />
}
