import * as styles from './phone-number-input.style.ts'

import { ChangeEvent, FC } from 'react'
import { useMask } from '@react-input/mask'

type Props = {
  value?: string
  onChange?: (phone: string) => void
}
export const PhoneNumberInput: FC<Props> = ({ value, onChange }) => {
  const inputRef = useMask({
    mask: '+7 (___) ___-__-__',
    replacement: { _: /\d/ },
  })

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value)
  }

  return (
    <div>
      <input
        ref={inputRef}
        type="tel"
        placeholder="+7 (xxx) xxx xx xx"
        value={value}
        onChange={handlePhoneChange}
        className={styles.phoneNumberInput}
      />
    </div>
  )
}
