import { deserializeDate } from 'api/deserializers/common.deserializers.ts'
import { VoiceModel } from 'api/models/voice.model.ts'
import dayjs, { Dayjs } from 'dayjs'
import { MatchTop } from 'enums/counterpart.enums.ts'
import { NotificationStatus } from 'enums/notification.enums.ts'
import { JsonNameReadonly, JsonStruct } from 'tserialize'

export class MeetingCounterpart {
  @JsonNameReadonly()
  id!: number

  @JsonNameReadonly('date_of_birth', deserializeDate)
  dateOfBirth: Dayjs = dayjs()

  @JsonNameReadonly('image')
  imageUrl: string = ''

  @JsonNameReadonly('match_top')
  matchTop: MatchTop | null = null

  @JsonNameReadonly('matched_roles')
  matchedRoles: Array<string> = []

  @JsonNameReadonly('notification_status')
  notificationStatus: NotificationStatus | null = null

  @JsonNameReadonly('is_notifications_available')
  isNotificationsAvailable: boolean = false

  @JsonNameReadonly()
  name: string = ''

  @JsonStruct(VoiceModel)
  voice: VoiceModel = new VoiceModel()
}
