import Bad from 'assets/meeting-rates/bad.png'
import Best from 'assets/meeting-rates/best.png'
import Good from 'assets/meeting-rates/good.png'
import Great from 'assets/meeting-rates/great.png'
import Normal from 'assets/meeting-rates/normal.png'
import { TMeetingRateStep } from 'types/meeting-rate.types.ts'

export const MEETING_RATE_DATA: Array<TMeetingRateStep> = [
  {
    value: 1,
    text: 'ПЛОХО',
    icon: Bad
  },
  {
    value: 2,
    text: 'СРЕДНЕ',
    icon: Normal
  },
  {
    value: 3,
    text: 'ХОРОШО',
    icon: Good
  },
  {
    value: 4,
    text: 'ОТЛИЧНО',
    icon: Great
  },
  {
    value: 5,
    text: 'ЛУЧШАЯ',
    icon: Best
  },
] as const
