import { JsonName } from 'tserialize'

export class TariffModel {
  @JsonName()
  id!: number

  @JsonName('price_per_month')
  pricePerMonth: number = 0

  @JsonName('total_price')
  totalPrice: number = 0

  @JsonName('given_days')
  givenDays: number = 0

  @JsonName('period_label')
  periodLabel: string = ''

  @JsonName('discount_label')
  discountLabel: string | null = null
}
