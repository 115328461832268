import { css } from '@emotion/css';

export const container = css`
    white-space: nowrap;
    overflow-x: scroll;
    padding: 0 2px 2px 0;
    
    &::-webkit-scrollbar {
        display: none;
    }
`

export const item = css`
    display: inline-block;
    min-width: 125px;
    height: 37px;
    padding: 0 10px;
    border-radius: 24px;
    border: 2px solid #f2f2f2;
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
    cursor: pointer;

    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 37px;

    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    
    &:not(:first-child) {
        margin-left: 15px;
    }
    
    & input[type='radio'] {
        display: none;
    }
`
