import { css } from '@emotion/css';

export const container = css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 253px;
    height: 37px;
    border-radius: 24px;
    background: #ffde00;
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
`

export const content = css`
    max-width: 180px;
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
`

export const icon = css`
    position: absolute;
    left: 10px;
    height: 34px;
    width: 34px;
    object-fit: contain;
`
