import { makeAutoObservable } from 'mobx'
import { userMeetingsTransport } from 'api/user-meetings/user-meetings.transport.ts'
import { GetUserMeetingsResponse } from 'api/user-meetings/responses/get-user-meetings.response.ts'
import { UserMeetingModel } from 'api/user-meetings/models/user-meeting.model'

class ArchiveMeetingsStore {
  meetings: Array<UserMeetingModel> = []
  isLoading: boolean = false
  hasError: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setIsLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading
  }

  setHasError = (hasError: boolean): void => {
    this.hasError = hasError
  }

  getArchiveMeetings = (): void => {
    this.setIsLoading(true)
    this.setHasError(false)

    userMeetingsTransport
      .getArchiveMeetings()
      .then(({ results }: GetUserMeetingsResponse) => {
        this.setMeetings(results)
      })
      .catch(() => {
        this.setHasError(true)
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  private setMeetings = (meetings: Array<UserMeetingModel>): void => {
    this.meetings = meetings
  }
}

export const archiveMeetingsStore = new ArchiveMeetingsStore()
