export enum UserGender {
  MALE = 'male',
  FEMALE = 'female',
}

export const RandomGender = 'random'

export enum SurveyStep {
  QUESTIONS = 'questions',
  ROLES = 'roles',
  COMMON = 'common',
  CUSTOMIZATION = 'customization',
}
