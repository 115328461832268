export enum MeetingStatus {
  SEARCH = 'search',
  FOUND = 'found',
  NOT_FOUND = 'not_found',
  WAIT_CONFIRMATION = 'wait_confirmation',
  MEETING_SOON = 'meeting_soon',
  MEETING_IN_PROGRESS = 'meeting_in_progress',
  RATE_MEETING = 'rate_meeting',
  ARCHIVE = 'archive',
  CANCELLED = 'cancelled',
}

export const MEETING_STATUS_DATA_MAP = {
  creator: {
    [MeetingStatus.SEARCH]: {
      color: '#68b984',
      text: 'Поиск',
    },
    [MeetingStatus.FOUND]: {
      color: '#68b984',
      text: 'Найден',
    },
    [MeetingStatus.NOT_FOUND]: {
      color: '#ca0f0f',
      text: 'Не найден',
    },
    [MeetingStatus.WAIT_CONFIRMATION]: {
      color: '#e41c2a',
      text: 'Подтверди участие',
    },
    [MeetingStatus.MEETING_SOON]: {
      color: '#68b984',
      text: 'Скоро встреча',
    },
    [MeetingStatus.MEETING_IN_PROGRESS]: {
      color: '#ffda00',
      text: 'Встреча идёт',
    },
    [MeetingStatus.RATE_MEETING]: {
      color: '#ffda00',
      text: 'Оцени встречу',
    },
    [MeetingStatus.ARCHIVE]: {
      color: '#b5bbbd',
      text: '',
    },
    [MeetingStatus.CANCELLED]: {
      color: '#f00',
      text: 'Встреча отменена',
    },
  },
  invited: {
    [MeetingStatus.SEARCH]: {
      color: '#68b984',
      text: '',
    },
    [MeetingStatus.FOUND]: {
      color: '#00cf15',
      text: 'Жди напоминания',
    },
    // де-факто у приглашенного не может быть "не найденной" встречи
    [MeetingStatus.NOT_FOUND]: {
      color: '#ca0f0f',
      text: 'Не найден',
    },
    [MeetingStatus.WAIT_CONFIRMATION]: {
      color: '#e41c2a',
      text: 'Подтверди участие',
    },
    [MeetingStatus.MEETING_SOON]: {
      color: '#68b984',
      text: 'Скоро встреча',
    },
    [MeetingStatus.MEETING_IN_PROGRESS]: {
      color: '#ffda00',
      text: 'Встреча идёт',
    },
    [MeetingStatus.RATE_MEETING]: {
      color: '#ffda00',
      text: 'Оцени встречу',
    },
    [MeetingStatus.ARCHIVE]: {
      color: '#b5bbbd',
      text: '',
    },
    [MeetingStatus.CANCELLED]: {
      color: '#f00',
      text: 'Встреча отменена',
    },
  },
}
