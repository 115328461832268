import { CitiesTransport } from 'api/cities/cities.transport.ts';
import { CityModel } from 'api/cities/models/city.model.ts';
import { makeAutoObservable } from 'mobx'

class CitiesStore {
  cities: Array<CityModel> = []
  isLoading: boolean = false
  hasError: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCities = (cities: Array<CityModel>): void => {
    this.cities = cities
  }

  setIsLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading
  }

  setHasError = (hasError: boolean): void => {
    this.hasError = hasError
  }

  getCities = (): void => {
    this.setIsLoading(true)
    this.setHasError(false)

    CitiesTransport
      .getCities()
      .then(this.setCities)
      .catch(() => {
        this.setHasError(true)
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }
}

export const citiesStore = new CitiesStore()
