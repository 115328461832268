import { deserialize, JsonName, JsonStruct } from 'tserialize'
import { PaymentDataModel } from './payment-data.model.ts'

export class PaymentModel {
  @JsonName()
  id!: number

  @JsonName('already_seen')
  alreadySeen!: boolean

  @JsonName('already_had_subscription')
  alreadyHadSubscription: boolean = false

  @JsonStruct(PaymentDataModel, 'payment_data')
  data!: PaymentDataModel

  static fromServer(data: object): PaymentModel {
    return data && deserialize(data, PaymentModel)
  }
}
