import { css } from '@emotion/css'

export const chatMessagesPage = css`
  padding: 0 1.5rem calc(4rem + 58px);
`

export const chatHeaderImage = css`
  display: block;
  width: 100%;
`

export const chatMessagesFooter = css`
  padding: 2rem 1.5rem;
`

export const chatMessagesSeparator = css`
  display: block;
  width: 100%;
  height: 26px;
  margin: 10px 0;
`
